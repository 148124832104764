import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Col, Image, Row } from 'antd';

import { ToastContext } from '../../../contexts/Toast';
import useCheckRole from '../../../hooks/useCheckRole';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { CustomErrorType } from '../../../store/apis/@types';
import { DRIVER_MANAGEMENT_PATH } from '../../../routes/constants/urls';
import GridDetailTemplate from '../GridDetailTemplate';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import InputForm from '../../molecules/InputForm';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import Input from '../../atoms/Input';
import InputAlert from '../../atoms/InputAlert';
import Alert from '../../atoms/Alert';
import {
	DriverDto,
	MakeDriverDto,
	UpdateDriverDto,
} from '../../../interface/driver';
import {
	useMakeDriversOfCompanyMutation,
	useMakeMyDriverMutation,
	useUpdateDriverOfCompanyMutation,
	useUpdateMyDriverMutation,
} from '../../../store/apis/driver';
import {
	companyRegisterNumberFormat,
	driverLicenseFormat,
	phoneFormat,
} from '../../../utils/data-format';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import { getProviderName } from '../../../utils/string-util';
import SelectionModal from '../../organisms/SelectionModal';
import { useGetCompaniesQuery } from '../../../store/apis/company';
import { companyModalColumn, customerModalColumn } from '../modalColumn';
import { useGetCustomersQuery } from '../../../store/apis/customer';
import { renderCustomerStatusCell } from '../../../pages/UserManagement/CustomerManagement/Customers/gridData';
import { CustomerDto } from '../../../interface/customer';
import { CompanyDto } from '../../../interface/company';
import { useBigFilesUploadSecureMutation } from '../../../store/apis/upload';
import Badge from '../../atoms/Badge';

interface DriverTemplateProps {
	initialValues: MakeDriverDto | UpdateDriverDto;
	title?: string;
	additionalInfo?: DriverDto;
}

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
}

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	width: 100%;
	margin: 0 auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const renderFilePreview = (url: string, altText?: string) => {
	try {
		const imageFileType = ['png', 'jpg', 'jpeg'];
		const imageMimeType = ['image/png', 'image/jpg', 'image/jpeg'];

		const checkUrl = new URL(url); // can occur TypeError
		const pathName = checkUrl.pathname;

		if (checkUrl.protocol === 'data:') {
			const split = pathName.split(';');
			const fileName = split[0];
			return imageMimeType.includes(fileName) ? (
				<Image
					src={url}
					style={{ width: '100px' }}
					alt={altText ?? '미리보기'}
				/>
			) : null;
		}

		const split = pathName.split('/');
		const fileName = split[split.length - 1];
		const fileNameSplit = fileName.split('.');
		const fileType = fileNameSplit[fileNameSplit.length - 1];

		return imageFileType.includes(fileType.toLowerCase()) ? (
			<Image src={url} style={{ width: '100px' }} alt={altText ?? '미리보기'} />
		) : (
			<a href={url} target="_blank" rel="noreferrer">
				{altText ?? '업로드된 파일'}
			</a>
		);
	} catch {
		return null;
	}
};

const DriverTemplate = ({
	initialValues,
	title = '기사 등록',
	additionalInfo,
}: DriverTemplateProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const { driverId } = useParams();

	const [isSelectCompany, setIsSelectCompany] = useState(false);
	const [isSelectCustomer, setIsSelectCustomer] = useState(false);
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState<
		CustomerDto | undefined
	>(undefined);
	const [selectedCompany, setSelectedCompany] = useState<
		CompanyDto | undefined
	>(undefined);
	const [makeDriver] = useMakeDriversOfCompanyMutation();
	const [updateDriver] = useUpdateDriverOfCompanyMutation();
	const [makeMyDriver] = useMakeMyDriverMutation();
	const [updateMyDriver] = useUpdateMyDriverMutation();

	const [freightForwarderLicensePreview, setFreightForwarderLicensePreview] =
		useState<string>('');
	const [businessLicensePreview, setBusinessLicensePreview] =
		useState<string>('');
	const [bankAccountScanPreview, setBankAccountScanPreview] =
		useState<string>('');

	const isCreateDriver = useCheckRole({
		roleCategory: '회원관리',
		roleName: '법인관리',
		roleType: 'isCreate',
	});
	const isUpdateDriver = useCheckRole({
		roleCategory: '회원관리',
		roleName: '법인관리',
		roleType: 'isUpdate',
	});
	const isSuper = useCheckRole({
		roleCategory: '회원관리',
		roleName: '법인관리',
		roleType: 'isSuper',
	});

	const [fileUploadSecure] = useBigFilesUploadSecureMutation();

	const handleValidate = (values: MakeDriverDto | UpdateDriverDto) => {
		setIsTrySubmit(true);

		const errors: Partial<Record<keyof MakeDriverDto, string>> = {};

		if ((values as MakeDriverDto).companyId === 0) {
			errors.companyId = '법인을 선택해주세요.';
		}

		if (values.driverName === '') {
			errors.driverName = '기사명을 입력해주세요.';
		}
		return errors;
	};

	const handleCreateSubmit = async (values: typeof initialValues) => {
		const { companyId, ...rest } = values as MakeDriverDto;

		const result = !isSuper
			? await makeMyDriver({
					body: rest,
			  })
			: await makeDriver({
					body: { companyId, ...rest },
			  });

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '기사 등록이 완료되었습니다.');
			navigate(DRIVER_MANAGEMENT_PATH);
		}
	};

	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const {
			freightForwarderLicense,
			businessLicense,
			bankAccountScan,
			...rest
		} = values;

		const result = !isSuper
			? await updateMyDriver({
					body: values,
					driverId: driverId as string,
			  })
			: await updateDriver({
					body: {
						...rest,
						freightForwarderLicense:
							freightForwarderLicense === initialValues.freightForwarderLicense
								? undefined
								: freightForwarderLicense,
						businessLicense:
							businessLicense === initialValues.businessLicense
								? undefined
								: businessLicense,
						bankAccountScan:
							bankAccountScan === initialValues.bankAccountScan
								? undefined
								: bankAccountScan,
					},
					driverId: driverId as string,
			  });

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '기사 등록이 완료되었습니다.');
			navigate(DRIVER_MANAGEMENT_PATH);
		}
	};

	const handleSubmit = async (values: typeof initialValues) => {
		dispatch(fullLoadingOn());

		const updatedValues = { ...values };

		if (updatedValues.driverLicenseNumber === '') {
			updatedValues.driverLicenseNumber = null;
		}

		if (!driverId) {
			await handleCreateSubmit(updatedValues);
		} else {
			await handleUpdateSubmit(updatedValues);
		}

		dispatch(fullLoadingOff());
	};

	const formik = useFormik({
		initialValues,
		onSubmit: handleSubmit,
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleCancelClick = () => {
		navigate(-1);
	};

	const handleCreateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.CREATE);
		}
	};

	const handleUpdateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.UPDATE);
		}
	};

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.CREATE:
				return (
					<Alert
						title="기사 등록"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="등록하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.CREATE}
					>
						기사를 등록하시겠습니까?
					</Alert>
				);
			case AlertState.UPDATE:
				return (
					<Alert
						title="기사 수정"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="수정하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						기사를 수정하시겠습니까?
					</Alert>
				);
			default:
				return null;
		}
	}, [alertState, formik]);

	const handleUploadFile = useCallback(
		async (acceptedFiles: File[]) => {
			if (acceptedFiles.length) {
				const res = await fileUploadSecure({
					file: acceptedFiles,
					folder: `driver/general`,
				});

				if ('error' in res) {
					const errorMessage =
						(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message;

					toast('error', errorMessage);

					return null;
				}

				return res.data;
			}

			return null;
		},
		[fileUploadSecure, toast],
	);

	const onDrop = useCallback(
		async (acceptedFiles: File[], fieldName: string) => {
			const reader = new FileReader();

			reader.onload = (e: ProgressEvent<FileReader>) => {
				const temporaryPreviewURL = e.target?.result as string;

				switch (fieldName) {
					case 'freightForwarderLicense':
						setFreightForwarderLicensePreview(`${temporaryPreviewURL}`);
						break;

					case 'businessLicense':
						setBusinessLicensePreview(`${temporaryPreviewURL}`);
						break;

					case 'bankAccountScan':
						setBankAccountScanPreview(`${temporaryPreviewURL}`);
						break;

					// Add cases for other fields if needed...

					default:
						break;
				}
			};
			reader.readAsDataURL(acceptedFiles[0]);

			const resData = await handleUploadFile(acceptedFiles);

			if (resData) {
				toast('info', '파일 업로드가 완료되었습니다.');

				switch (fieldName) {
					case 'freightForwarderLicense':
						await formik.setFieldValue(fieldName, resData.rows[0] as string);
						break;

					case 'businessLicense':
						await formik.setFieldValue(fieldName, resData.rows[0] as string);
						break;

					case 'bankAccountScan':
						await formik.setFieldValue(fieldName, resData.rows[0] as string);
						break;

					// Add cases for other fields if needed...

					default:
						break;
				}
			}
		},
		[formik, handleUploadFile, toast],
	);

	const {
		getRootProps: getFreightForwarderLicenseRootProps,
		getInputProps: getFreightForwarderLicenseInputProps,
		acceptedFiles: freightForwarderLicenseAcceptedFiles,
	} = useDropzone({
		onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'freightForwarderLicense'),
		noKeyboard: true,
		maxFiles: 1,
	});

	const {
		getRootProps: getBusinessLicenseRootProps,
		getInputProps: getBusinessLicenseInputProps,
		acceptedFiles: businessLicenseAcceptedFiles,
	} = useDropzone({
		onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'businessLicense'),
		noKeyboard: true,
		maxFiles: 1,
	});

	const {
		getRootProps: getBankAccountScanRootProps,
		getInputProps: getBankAccountScanInputProps,
		acceptedFiles: bankAccountScanAcceptedFiles,
	} = useDropzone({
		onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'bankAccountScan'),
		noKeyboard: true,
		maxFiles: 1,
	});

	return (
		<>
			<GridDetailTemplate
				detailTitle="기사 관리"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						{!driverId ? (
							<Button
								onClick={handleCreateClick}
								size="small"
								disabled={!isCreateDriver}
							>
								{isCreateDriver ? '등록하기' : '등록 권한없음'}
							</Button>
						) : (
							<Button
								onClick={handleUpdateClick}
								size="small"
								disabled={!isUpdateDriver}
							>
								{isUpdateDriver ? '수정하기' : '수정 권한없음'}
							</Button>
						)}
					</ButtonContainer>
				}
			>
				<Row gutter={[12, 12]}>
					<Col md={12}>
						<ContentBoxWithHeader
							title={title as string}
							borderRadius="10px"
							className="inner-content"
						>
							<InnerContent>
								{!driverId && isSuper && (
									<InputForm>
										<Label name="companyId">
											<span className="label">
												<Typo $typoType="label2">법인</Typo>
											</span>
										</Label>
										<div style={{ display: 'flex' }}>
											<Button
												size="small"
												onClick={() => setIsSelectCompany(true)}
											>
												법인 선택
											</Button>
											<SelectionModal
												onClose={() => setIsSelectCompany(false)}
												onChange={(e) => {
													formik.setFieldValue('companyId', e[0].id);
													setSelectedCompany(e[0]);
												}}
												column={companyModalColumn.colsList}
												useQuery={useGetCompaniesQuery}
												isVisible={isSelectCompany}
												title="법인 선택"
												defaultSelectedIdList={[
													(formik.values as MakeDriverDto).companyId,
												]}
											/>
										</div>
										{selectedCompany && (
											<GridLayout $columnCount={2}>
												<GridItem title="회사명" size={GridTitleSize.GT_LARGE}>
													{selectedCompany.name}
												</GridItem>
												<GridItem
													title="사업자번호"
													size={GridTitleSize.GT_LARGE}
												>
													{companyRegisterNumberFormat(
														selectedCompany.businessLicenseNumber ?? '',
													)}
												</GridItem>
												<GridItem
													title="담당자명"
													size={GridTitleSize.GT_LARGE}
												>
													{selectedCompany.contactName}
												</GridItem>
												<GridItem
													title="담당자 연락처"
													size={GridTitleSize.GT_LARGE}
												>
													{phoneFormat(
														selectedCompany.contactPhoneNumber ?? '',
													)}
												</GridItem>
											</GridLayout>
										)}
									</InputForm>
								)}
								<InputForm>
									<Label name="driverName" essential>
										<span className="label">
											<Typo $typoType="label2">기사명</Typo>
										</span>
									</Label>
									<Input
										name="driverName"
										placeholder="기사명을 입력해주세요"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										value={formik.values.driverName ?? ''}
										onChange={formik.handleChange}
										onReset={() => formik.setFieldValue('driverName', '')}
									/>
									{formik.errors.driverName && (
										<InputAlert $inputStatus="error">
											{formik.errors.driverName}
										</InputAlert>
									)}
								</InputForm>
								<InputForm>
									<Label name="driverPhone" essential>
										<span className="label">
											<Typo $typoType="label2">기사 휴대폰번호</Typo>
										</span>
									</Label>
									<Input
										name="driverPhone"
										placeholder="기사 휴대폰번호를 입력해주세요"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										value={phoneFormat(formik.values.driverPhone ?? '')}
										onChange={(e) => {
											formik.setFieldValue(
												'driverPhone',
												e.target.value.replaceAll('-', ''),
											);
										}}
										onReset={() => formik.setFieldValue('driverPhone', '')}
									/>
									{formik.errors.driverPhone && (
										<InputAlert $inputStatus="error">
											{formik.errors.driverPhone}
										</InputAlert>
									)}
								</InputForm>
								<InputForm>
									<Label name="driverLicenseNumber">
										<span className="label">
											<Typo $typoType="label2">운전면허증번호</Typo>
										</span>
									</Label>
									<Input
										name="driverLicenseNumber"
										placeholder="면허번호 12자리를 입력해주세요"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										value={
											formik.values.driverLicenseNumber
												? driverLicenseFormat(formik.values.driverLicenseNumber)
												: ''
										}
										onChange={formik.handleChange}
										onReset={() =>
											formik.setFieldValue('driverLicenseNumber', '')
										}
									/>
									{formik.errors.driverLicenseNumber && (
										<InputAlert $inputStatus="error">
											{formik.errors.driverLicenseNumber}
										</InputAlert>
									)}
								</InputForm>
								{isUpdateDriver && (
									<InputForm>
										<Label name="customerId">
											<span className="label">
												<Typo $typoType="label2">회원계정 연결하기</Typo>
											</span>
										</Label>
										<div style={{ display: 'flex' }}>
											<Button
												size="small"
												$buttonType={ButtonTypeType.GHOST}
												onClick={() => setIsSelectCustomer(true)}
											>
												회원 선택
											</Button>
											<SelectionModal
												onClose={() => setIsSelectCustomer(false)}
												onChange={(e) => {
													formik.setFieldValue('customerId', e[0].id);
													setSelectedCustomer(e[0]);
												}}
												column={customerModalColumn.colsList}
												useQuery={useGetCustomersQuery}
												isVisible={isSelectCustomer}
												title="계정 선택"
												defaultSelectedIdList={[formik.values.customerId]}
											/>
										</div>
										{formik.errors.customerId && (
											<InputAlert $inputStatus="error">
												{formik.errors.customerId}
											</InputAlert>
										)}
										{selectedCustomer && (
											<ContentBoxWithHeader
												title="선택된 회원 정보"
												borderRadius="10px"
											>
												{selectedCustomer ? (
													<GridLayout $columnCount={2}>
														<GridItem title="회원 아이디">
															{selectedCustomer.customerApplicationId}
														</GridItem>
														<GridItem title="회원명">
															{selectedCustomer.customerName}
														</GridItem>
														<GridItem title="회원 상태">
															{renderCustomerStatusCell({
																value: selectedCustomer.customerStatus,
															})}
														</GridItem>
														<GridItem title="가입 경로">
															{selectedCustomer.provider
																? getProviderName(selectedCustomer.provider)
																: ''}
														</GridItem>
													</GridLayout>
												) : (
													'선택된 회원계정 없음'
												)}
											</ContentBoxWithHeader>
										)}
									</InputForm>
								)}
							</InnerContent>
							{additionalInfo && (
								<>
									<ContentBoxWithHeader
										title="소속 법인 정보"
										borderRadius="10px"
										className="mt-4"
									>
										<GridLayout $columnCount={2}>
											<GridItem title="회사명">
												{additionalInfo.company?.name}
											</GridItem>
											<GridItem title="사업자번호">
												{companyRegisterNumberFormat(
													additionalInfo.company?.businessLicenseNumber ?? '',
												)}
											</GridItem>
											<GridItem title="법인등록번호">
												{additionalInfo.company?.registrationNumber ?? ''}
											</GridItem>
											<GridItem title="담당자명">
												{additionalInfo.company?.contactName}
											</GridItem>
											<GridItem title="담당자 연락처">
												{phoneFormat(
													additionalInfo.company?.contactPhoneNumber ?? '',
												)}
											</GridItem>
										</GridLayout>
									</ContentBoxWithHeader>
									<ContentBoxWithHeader
										title="배정된 차량 정보"
										borderRadius="10px"
										className="mt-4"
									>
										{additionalInfo.vehicle ? (
											<GridLayout $columnCount={2}>
												<GridItem title="차량번호">
													{additionalInfo.vehicle?.vehicleNumber}
												</GridItem>
												<GridItem title="차대번호">
													{additionalInfo.vehicle?.vin}
												</GridItem>
												<GridItem title="차량명">
													{additionalInfo.vehicle?.vehicleName}
												</GridItem>
												<GridItem title="적재함 종류">
													{additionalInfo.vehicle?.carLoadingBoxType}
												</GridItem>
												<GridItem title="적재함">
													{additionalInfo.vehicle?.loadingCapacity}
												</GridItem>
												<GridItem title="차종">
													{additionalInfo.vehicle?.vehicleCategory}
												</GridItem>
											</GridLayout>
										) : (
											'배정된 차량 정보 없음'
										)}
									</ContentBoxWithHeader>
									<ContentBoxWithHeader
										title="회원 정보"
										borderRadius="10px"
										className="mt-4"
									>
										{additionalInfo.customer ? (
											<GridLayout $columnCount={2}>
												<GridItem title="회원 아이디">
													{additionalInfo.customer?.customerApplicationId}
												</GridItem>
												<GridItem title="회원명">
													{additionalInfo.customer?.customerName}
												</GridItem>
												<GridItem title="회원 상태">
													{renderCustomerStatusCell({
														value: additionalInfo.customer?.customerStatus,
													})}
												</GridItem>
												<GridItem title="가입 경로">
													{additionalInfo.customer?.provider
														? getProviderName(additionalInfo.customer?.provider)
														: ''}
												</GridItem>
											</GridLayout>
										) : (
											'연결된 회원 정보 없음'
										)}
									</ContentBoxWithHeader>
								</>
							)}
						</ContentBoxWithHeader>
					</Col>
					<Col md={12}>
						<ContentBoxWithHeader
							title="추가정보"
							borderRadius="10px"
							className="inner-content"
						>
							<InnerContent>
								<InputForm>
									<Label name="freightForwarderLicense">
										<span className="label">
											<Typo $typoType="label2">화물운송종사자격증</Typo>
										</span>
									</Label>
									<div>
										{freightForwarderLicensePreview
											? renderFilePreview(
													freightForwarderLicensePreview,
													'화물운송종사자격증',
											  )
											: formik.values.freightForwarderLicense &&
											  renderFilePreview(
													formik.values.freightForwarderLicense,
													'화물운송종사자격증',
											  )}
									</div>
									{freightForwarderLicenseAcceptedFiles.length ? (
										freightForwarderLicenseAcceptedFiles.map((file) => (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
												key={file.name}
											>
												<input {...getFreightForwarderLicenseInputProps()} />
												<span>
													<Badge color="blue">업로드 파일</Badge> {file.name}
												</span>
												<div {...getFreightForwarderLicenseRootProps()}>
													<Button
														size="x-small"
														$buttonType={ButtonTypeType.GHOST_DANGER}
													>
														다시 올리기
													</Button>
												</div>
											</div>
										))
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<input {...getFreightForwarderLicenseInputProps()} />
											<div {...getFreightForwarderLicenseRootProps()}>
												<Button
													size="small"
													$buttonType={ButtonTypeType.GHOST_BLACK}
												>
													파일 선택
												</Button>
											</div>
										</div>
									)}
								</InputForm>
								<InputForm>
									<Label name="businessLicense">
										<span className="label">
											<Typo $typoType="label2">사업자등록증</Typo>
										</span>
									</Label>
									<div>
										{businessLicensePreview
											? renderFilePreview(
													businessLicensePreview,
													'사업자등록증',
											  )
											: formik.values.businessLicense &&
											  renderFilePreview(
													formik.values.businessLicense,
													'사업자등록증',
											  )}
									</div>
									{businessLicenseAcceptedFiles.length ? (
										businessLicenseAcceptedFiles.map((file) => (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
												key={file.name}
											>
												<input {...getBusinessLicenseInputProps()} />
												<span>
													<Badge color="blue">업로드 파일</Badge> {file.name}
												</span>
												<div {...getBusinessLicenseRootProps()}>
													<Button
														size="x-small"
														$buttonType={ButtonTypeType.GHOST_DANGER}
													>
														다시 올리기
													</Button>
												</div>
											</div>
										))
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<input {...getBusinessLicenseInputProps()} />
											<div {...getBusinessLicenseRootProps()}>
												<Button
													size="small"
													$buttonType={ButtonTypeType.GHOST_BLACK}
												>
													파일 선택
												</Button>
											</div>
										</div>
									)}
								</InputForm>
								<InputForm>
									<Label name="bankAccountScan">
										<span className="label">
											<Typo $typoType="label2">은행계좌사본</Typo>
										</span>
									</Label>
									<div>
										{bankAccountScanPreview
											? renderFilePreview(
													bankAccountScanPreview,
													'은행계좌사본',
											  )
											: formik.values.bankAccountScan &&
											  renderFilePreview(
													formik.values.bankAccountScan,
													'은행계좌사본',
											  )}
									</div>
									{bankAccountScanAcceptedFiles.length ? (
										bankAccountScanAcceptedFiles.map((file) => (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
												key={file.name}
											>
												<input {...getBankAccountScanInputProps()} />
												<span>
													<Badge color="blue">업로드 파일</Badge> {file.name}
												</span>
												<div {...getBankAccountScanRootProps()}>
													<Button
														size="x-small"
														$buttonType={ButtonTypeType.GHOST_DANGER}
													>
														다시 올리기
													</Button>
												</div>
											</div>
										))
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<input {...getBankAccountScanInputProps()} />
											<div {...getBankAccountScanRootProps()}>
												<Button
													size="small"
													$buttonType={ButtonTypeType.GHOST_BLACK}
												>
													파일 선택
												</Button>
											</div>
										</div>
									)}
								</InputForm>
							</InnerContent>
						</ContentBoxWithHeader>
					</Col>
				</Row>
			</GridDetailTemplate>
			{renderAlert()}
		</>
	);
};

export default DriverTemplate;
