import React from 'react';
import AdminCreatePage from '../pages/UserManagement/AdminManagement/AdminCreate';
import AdminDetailPage from '../pages/UserManagement/AdminManagement/AdminDetail';
import AdminPage from '../pages/UserManagement/AdminManagement/Admin';
import FaqPage from '../pages/CSManagement/FAQManagement/Faq';
import FaqCreatePage from '../pages/CSManagement/FAQManagement/FaqCreate';
import FaqDetailPage from '../pages/CSManagement/FAQManagement/FaqDetail';
import Login from '../pages/Auth/Login';
import ManagerDetailPage from '../pages/UserManagement/ManagerManagement/ManagerDetail';
import ManagersPage from '../pages/UserManagement/ManagerManagement/Managers';
import OneOnOneChatDetailPage from '../pages/CSManagement/OneOnOneChatManagement/OneOnOneChatDetail';
import OneOnOneChatPage from '../pages/CSManagement/OneOnOneChatManagement/OneOnOneChat';
import PasswordReset from '../pages/Auth/PasswordReset';
import SubscribeProductCreatePage from '../pages/OpenSpannerProduct/SubscriptionProductManagement/SubscribeProductCreate';
import UserIdSearch from '../pages/Auth/UserIdSearch';
import CustomerManagementPage from '../pages/UserManagement/CustomerManagement/Customers';
import UserPasswordSearch from '../pages/Auth/UserPasswordSearch';
import VehicleStatusManagementPage from '../pages/RealtimeVehicleManagement/VehicleStatusManagement/VehicleStatus';
import {
	ADMIN_MANAGEMENT_PATH,
	MANAGER_MANAGEMENT_PATH,
	CUSTOMER_MANAGEMENT_PATH,
	TASK_MANAGEMENT_PATH,
	REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH,
	INSPECTION_AGENCY_PATH,
	SERVICE_SCHEDULE_MANAGEMENT_PATH,
	EMERGENCY_DISPATCH_MANAGEMENT_PATH,
	SUBSCRIBE_PRODUCT_MANAGEMENT_PATH,
	PRODUCT_MANAGEMENT_PATH,
	RULES_MANAGEMENT_PATH,
	VEHICLES_STATUS_MANAGEMENT_PATH,
	PURCHASE_HISTORY_MANAGEMENT_PATH,
	TERMINATION_MANAGEMENT_PATH,
	INVENTORY_STATE_PATH,
	INVENTORY_STATE_STANDARD_MANAGEMENT_PATH,
	MONTHLY_SALES_MANAGEMENT_PATH,
	MANAGER_SETTLEMENT_MANAGEMENT_PATH,
	BANNER_MANAGEMENT_PATH,
	CS_TEL_PATH,
	CS_CHAT_PATH,
	CS_FAQ_PATH,
	LOGIN_PATH,
	PASSWORD_RESET,
	ID_SEARCH,
	PASSWORD_SEARCH,
	CUSTOMER_DETAIL_PATH,
	MANAGER_DETAIL_PATH,
	ADMIN_CREATE_PATH,
	ADMIN_DETAIL_PATH,
	SUBSCRIBE_PRODUCT_CREATE_PATH,
	CS_FAQ_CREATE_PATH,
	CS_CHAT_DETAIL_PATH,
	CS_FAQ_DETAIL_PATH,
	ADMIN_ROLE_MANAGEMENT_PATH,
	ADMIN_ROLE_CREATE_PATH,
	PRODUCT_CREATE_PATH,
	PRODUCT_DETAIL_PATH,
	ADMIN_ROLE_DETAIL_PATH,
	DEPARTMENT_MANAGEMENT_PATH,
	DEPARTMENT_DETAIL_PATH,
	SUBSCRIBE_PRODUCT_DETAIL_PATH,
	CS_TEL_DETAIL_PATH,
	CS_TEL_CREATE_PATH,
	PURCHASE_HISTORY_DETAIL_PATH,
	CS_APP_VERSION_MANAGEMENT,
	CS_APP_VERSION_DETAIL_PATH,
	CS_APP_VERSION_CREATE_PATH,
	TERMINATION_DETAIL_PATH,
	CS_NOTICE_PATH,
	CS_NOTICE_DETAIL_PATH,
	CS_NOTICE_CREATE_PATH,
	TERMS_MANAGEMENT_PATH,
	TERMS_CREATE_PATH,
	TERMS_DETAIL_PATH,
	PARTNER_INSPECTION_AGENCY_PATH,
	ERROR_PATH,
	INVENTORY_STATE_STANDARD_CREATE_PATH,
	INVENTORY_STATE_STANDARD_DETAIL_PATH,
	MONTHLY_SALES_DETAIL_PATH,
	VEHICLE_DTG_PATH,
	VEHICLE_OBD_PATH,
	CAR_SERVICE_MANAGEMENT_PATH,
	CAR_SERVICE_DETAIL_PATH,
	CAR_SERVICE_OUTSOURCING_MANAGEMENT_PATH,
	CAR_SERVICE_COMPANY_MANAGEMENT_PATH,
	CAR_SERVICE_EXCEL_DOWNLOAD_PATH,
	CAR_SERVICE_EXCEL_UPLOAD_PATH,
	CAR_SERVICE_TS_INSPECTION_RESULTS,
	CAR_SERVICE_ALL_TRANSFERS,
	CAR_SERVICE_PARTNER_MANAGEMENT_PATH,
	VEHICLES_CURRENT_LOCATIONS_PATH,
	VEHICLES_OBD_PATH,
	VEHICLE_DTG_BY_TRIP_PATH,
	CAR_SERVICE_OUTSOURCING_DETAIL_PATH,
	CAR_SERVICE_PARTNER_DETAIL_PATH,
	VEHICLE_DTG_MAP_BY_TRIP_PATH,
	CAR_SERVICE_COMPANY_CREATE_PATH,
	TIRE_REPLACEMENT_MANAGEMENT_PATH,
	VEHICLES_STATUS_CREATE_PATH,
	VEHICLES_STATUS_DETAIL_PATH,
	VEHICLES_DETAIL_PATH,
	VEHICLES_MANAGEMENT_PATH,
	VEHICLES_CREATE_PATH,
	PLATES_MANAGEMENT_PATH,
	PLATES_CREATE_PATH,
	PLATES_DETAIL_PATH,
	COMPANY_MANAGEMENT_PATH,
	COMPANY_DETAIL_PATH,
	COMPANY_CREATE_PATH,
	DRIVER_MANAGEMENT_PATH,
	DRIVER_DETAIL_PATH,
	DRIVER_CREATE_PATH,
	CAR_SERVICE_DASHBOARD_PATH,
	VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_CANCEL_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_CANCEL_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_POLICY_MANAGEMENT_PATH,
	CUSTOMER_CREATE_PATH,
	CAR_CARE_SUBSCRIPTION_MANAGEMENT_PATH,
	CAR_CARE_SUBSCRIPTION_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH,
	CAR_SERVICE_MYDASHBOARD_PATH,
	VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_CONSULTING_CREATE_PATH,
	VEHICLE_SUBSCRIPTION_CONSULTING_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_QUOTES_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_QUOTES_CREATE_PATH,
	QUOTE_PDF_PATH,
	SUBPRODUCT_MANAGEMENT_PATH,
	SUBPRODUCT_CREATE_PATH,
	SUBPRODUCT_DETAIL_PATH,
} from './constants/urls';
import AdminRolePage from '../pages/UserManagement/AdminRoleManagement/AdminRole';
import AdminRoleCreatePage from '../pages/UserManagement/AdminRoleManagement/AdminRoleCreate';
import RegularInspectionManagerAssignmentPage from '../pages/CarCareManagement/RegularInspectionManagerAssignment';
import RulesManagementPage from '../pages/OpenSpannerManagement/RulesManagement';
import SubscriptionProductPage from '../pages/OpenSpannerProduct/SubscriptionProductManagement/SubscriptionProduct';
import ProductPage from '../pages/OpenSpannerProduct/IndividualProductManagement/Product';
import ProductCreatePage from '../pages/OpenSpannerProduct/IndividualProductManagement/ProductCreate';
import ProductDetailPage from '../pages/OpenSpannerProduct/IndividualProductManagement/ProductDetail';
import AdminRoleDetailPage from '../pages/UserManagement/AdminRoleManagement/AdminRoleDetail';
import SubscribeProductDetailPage from '../pages/OpenSpannerProduct/SubscriptionProductManagement/SubscribeProductDetail';
import TelInquiryCreatePage from '../pages/CSManagement/TelInQuiryManagement/TelInquiryCreate';
import TelInquiryPage from '../pages/CSManagement/TelInQuiryManagement/TelInquiry';
import TelInquiryDetailPage from '../pages/CSManagement/TelInQuiryManagement/TelInquiryDetail';
import PurchaseHistoryPage from '../pages/OpenSpannerManagement/PurchaseHistoryManagement/PurchaseHistory';
import PurchaseDetailPage from '../pages/OpenSpannerManagement/PurchaseHistoryManagement/PurchaseHistoryDetail';
import AppVersionDetailPage from '../pages/CSManagement/AppVersionManagement/AppVersionDetail';
import AppVersionPage from '../pages/CSManagement/AppVersionManagement/AppVersion';
import TerminationPage from '../pages/OpenSpannerManagement/TerminationManagement/Termination';
import TerminationDetailPage from '../pages/OpenSpannerManagement/TerminationManagement/TerminationDetail';
import AppVersionCreatePage from '../pages/CSManagement/AppVersionManagement/AppVersionCreate';
import NoticePage from '../pages/CSManagement/NoticeManagement/Notice';
import NoticeDetailPage from '../pages/CSManagement/NoticeManagement/NoticeDetail';
import NoticeCreatePage from '../pages/CSManagement/NoticeManagement/NoticeCreate';
import TermsPage from '../pages/TermsManagement/Terms';
import TermsCreatePage from '../pages/TermsManagement/TermsCreate';
import TermsDetailPage from '../pages/TermsManagement/TermsDetail';
import InspectionAgencyManagerAssignmentPage from '../pages/CarCareManagement/InspectionAgencyManagerAssignment';
import PartnerInspectionAgencyManagerAssignmentPage from '../pages/CarCareManagement/PartnerInspectionAgencyManagerAssignment';
import ErrorPage from '../pages/Error';
import ServiceScheduleManagementPage from '../pages/CarCareManagement/ServiceScheduleManagement';
import InventoryCreatePage from '../pages/InventoryManagement/InventoryCreate';
import InventoryPage from '../pages/InventoryManagement/Inventory';
import InventoryDetailPage from '../pages/InventoryManagement/InventoryDetail';
import MonthlySalesManagement from '../pages/MonthlySalesManagement/MonthlySales';
import MonthlySalesDetailPage from '../pages/MonthlySalesManagement/MonthlySalesDetail';
import VehicleDTGInfo from '../pages/RealtimeVehicleManagement/VehicleDTG/VehicleDTGInfo';
import VehicleTrips from '../pages/RealtimeVehicleManagement/VehicleDTG/VehicleTrips';
import VehicleOBD from '../pages/RealtimeVehicleManagement/RealtimeVehicleStatusManagement/VehicleOBD';
import VehiclesConditionsOBD from '../pages/RealtimeVehicleManagement/RealtimeVehicleStatusManagement/VehiclesOBD';
import VehiclesCurrentLocations from '../pages/RealtimeVehicleManagement/VehicleDTG/VehiclesCurrentLocations';
import VehiclePathByDateTime from '../pages/RealtimeVehicleManagement/VehicleDTG/VehicleDrivingRecord';
import CarService from '../pages/NationalInspectionAgency/CarServiceManagement/CarService';
import CarServiceDetail from '../pages/NationalInspectionAgency/CarServiceManagement/CarServiceDetail';
import CarServiceOutsourcingManagement from '../pages/NationalInspectionAgency/CarServiceOutsourcingManagement';
import CarServiceOutsourcingDetail from '../pages/CarServiceOutsourcingDetail';
import CarServicePartnerManagement from '../pages/NationalInspectionAgency/CarServicePartnerManagement';
import CarServicePartnerDetail from '../pages/CarServicePartnerDetail';
import CarServiceTransfers from '../pages/NationalInspectionAgency/CarServiceTransfers';
import CarServicePublicCorp from '../pages/NationalInspectionAgency/CarServicePublicCorp';
import CarServiceCompany from '../pages/NationalInspectionAgency/CarServiceCompanyManagement/CarServiceCompany';
import CarServiceCompanyCreate from '../pages/NationalInspectionAgency/CarServiceCompanyManagement/CarServiceCompanyCreate';
import CarServiceExcelDownload from '../pages/NationalInspectionAgency/CarServiceExcel/CarServiceExcelDownload';
import CarServiceExcelUpload from '../pages/NationalInspectionAgency/CarServiceExcel/CarServiceExcelUpload';
import TireReplacementManagement from '../pages/CarCareManagement/TireReplacementManagement';
import VehicleStatusCreate from '../pages/RealtimeVehicleManagement/VehicleStatusManagement/VehicleStatusCreate';
import VehicleStatusDetailPage from '../pages/RealtimeVehicleManagement/VehicleStatusManagement/VehicleStatusDetail';
import VehiclesManagement from '../pages/AssetManagement/VehiclesManagement/Vehicles';
import VehiclesCreate from '../pages/AssetManagement/VehiclesManagement/VehiclesCreate';
import VehiclesDetail from '../pages/AssetManagement/VehiclesManagement/VehiclesDetail';
import VehiclePlatesPage from '../pages/AssetManagement/VehiclePlatesManagementPage/VehiclePlates';
import VehiclePlateCreate from '../pages/AssetManagement/VehiclePlatesManagementPage/VehiclePlateCreate';
import CompanyPage from '../pages/UserManagement/CompanyManagement/Companies';
import CompanyCreate from '../pages/UserManagement/CompanyManagement/CompanyCreate';
import CompanyDetailPage from '../pages/UserManagement/CompanyManagement/CompanyDetail';
import VehiclePlateDetail from '../pages/AssetManagement/VehiclePlatesManagementPage/VehiclePlateDetail';
import DriversPage from '../pages/UserManagement/DriverManagement/Drivers';
import DriverCreate from '../pages/UserManagement/DriverManagement/DriverCreate';
import DriverDetailPage from '../pages/UserManagement/DriverManagement/DriverDetail';
import CarServiceDashboard from '../pages/NationalInspectionAgency/CarServiceDashboard';
import VehicleSubscriptionsManagement from '../pages/OpenTruckManagement/VehicleSubscriptionsManagement';
import CustomerCreate from '../pages/UserManagement/CustomerManagement/CustomerCreate';
import VSCancellationManagement from '../pages/OpenTruckManagement/CancellationManagement';
import VSCancellationDetailPage from '../pages/VehicleSubscription/cancellationDetail';
import VehicleSubscriptionPolicies from '../pages/OpenTruckManagement/VehicleSubscriptionPolicies';
import DepartmentsManagementPage from '../pages/UserManagement/DepartmentManagement/management';
import DepartmentDetailPage from '../pages/UserManagement/DepartmentManagement/departmentDetail';
import MyCarCareSubscriptionManagement from '../pages/CarCareSubscriptionMe/management';
import MyCarCareSubscriptionDetailPage from '../pages/CarCareSubscriptionMe/myCarCareDetail';
import VehicleSubscriptionRegisterPage from '../pages/VehicleSubscription/registerContract';
import CarServiceMyDashboard from '../pages/NationalInspectionAgency/CarServiceMyDashboard';
import VehicleSubscriptionConsulting from '../pages/OpenTruckManagement/VehicleSubscriptionConsulting/Consulting';
import VehicleSubscriptionDetailPage from '../pages/OpenTruckManagement/VehicleSubscriptionsManagement/VehicleSubscriptionDetail';
import VehicleSubscriptionConsultingCreatePage from '../pages/OpenTruckManagement/VehicleSubscriptionConsulting/ConsultingCreate';
import VehicleSubscriptionConsultingDetail from '../pages/OpenTruckManagement/VehicleSubscriptionConsulting/ConsultingDetail';
import VehicleSubscriptionsQuotes from '../pages/OpenTruckManagement/VehicleSubscriptionsQuotes/VehicleQuotes';
import VehicleQuotesDetail from '../pages/OpenTruckManagement/VehicleSubscriptionsQuotes/VehicleQuotesDetail';
import VehicleQuotesCreate from '../pages/OpenTruckManagement/VehicleSubscriptionsQuotes/VehicleQuotesCreate';
import PublicPdfViwer from '../components/templates/VehicleSubscriptionQuotesTemplate/pdf/publicPdfViwer';
import CustomerDetailPage from '../pages/UserManagement/CustomerManagement/CustomerDetail/customerDetail';
import SubproductPage from '../pages/OpenSpannerProduct/SubproductManagement/Subproduct';
import SubproductCreatePage from '../pages/OpenSpannerProduct/SubproductManagement/SubproductCreate';
import SubproductDetailPage from '../pages/OpenSpannerProduct/SubproductManagement/SubproductDetail';

export const PRIVATE_ROUTES = [
	{
		path: CUSTOMER_MANAGEMENT_PATH,
		element: <CustomerManagementPage />,
	},
	{
		path: `${CUSTOMER_DETAIL_PATH}/:userId`,
		element: <CustomerDetailPage />,
	},
	{ path: CUSTOMER_CREATE_PATH, element: <CustomerCreate /> },
	{
		path: MANAGER_MANAGEMENT_PATH,
		element: <ManagersPage />,
	},
	{
		path: `${MANAGER_DETAIL_PATH}/:id`,
		element: <ManagerDetailPage />,
	},
	{
		path: ADMIN_MANAGEMENT_PATH,
		element: <AdminPage />,
	},
	{
		path: ADMIN_CREATE_PATH,
		element: <AdminCreatePage />,
	},
	{
		path: `${ADMIN_DETAIL_PATH}/:id`,
		element: <AdminDetailPage />,
	},
	{
		path: ADMIN_ROLE_CREATE_PATH,
		element: <AdminRoleCreatePage />,
	},
	{
		path: ADMIN_ROLE_MANAGEMENT_PATH,
		element: <AdminRolePage />,
	},
	{
		path: `${ADMIN_ROLE_DETAIL_PATH}/:id`,
		element: <AdminRoleDetailPage />,
	},
	{
		path: DEPARTMENT_MANAGEMENT_PATH,
		element: <DepartmentsManagementPage />,
	},
	{
		path: `${DEPARTMENT_DETAIL_PATH}/:departmentId`,
		element: <DepartmentDetailPage />,
	},
	{ path: CAR_SERVICE_DASHBOARD_PATH, element: <CarServiceDashboard /> },
	{ path: CAR_SERVICE_MYDASHBOARD_PATH, element: <CarServiceMyDashboard /> },

	{
		path: CAR_SERVICE_MANAGEMENT_PATH,
		element: <CarService />,
	},
	{
		path: `${CAR_SERVICE_DETAIL_PATH}/:inspectionId`,
		element: <CarServiceDetail />,
	},
	{
		path: CAR_SERVICE_OUTSOURCING_MANAGEMENT_PATH,
		element: <CarServiceOutsourcingManagement />,
	},
	{
		path: `${CAR_SERVICE_OUTSOURCING_DETAIL_PATH}/:inspectionId`,
		element: <CarServiceOutsourcingDetail />,
	},
	{
		path: CAR_SERVICE_PARTNER_MANAGEMENT_PATH,
		element: <CarServicePartnerManagement />,
	},
	{
		path: `${CAR_SERVICE_PARTNER_DETAIL_PATH}/:inspectionId`,
		element: <CarServicePartnerDetail />,
	},
	{
		path: CAR_SERVICE_COMPANY_MANAGEMENT_PATH,
		element: <CarServiceCompany />,
	},
	{
		path: CAR_SERVICE_COMPANY_CREATE_PATH,
		element: <CarServiceCompanyCreate />,
	},
	{
		path: CAR_SERVICE_EXCEL_DOWNLOAD_PATH,
		element: <CarServiceExcelDownload />,
	},
	{
		path: CAR_SERVICE_EXCEL_UPLOAD_PATH,
		element: <CarServiceExcelUpload />,
	},
	{
		path: CAR_SERVICE_TS_INSPECTION_RESULTS,
		element: <CarServicePublicCorp />,
	},
	{
		path: CAR_SERVICE_ALL_TRANSFERS,
		element: <CarServiceTransfers />,
	},
	{
		path: TASK_MANAGEMENT_PATH,
		element: <div>TASK_MANAGEMENT_PATH</div>,
	},
	{
		path: REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH,
		element: <RegularInspectionManagerAssignmentPage />,
	},
	{
		path: INSPECTION_AGENCY_PATH,
		element: <InspectionAgencyManagerAssignmentPage />,
	},
	{
		path: PARTNER_INSPECTION_AGENCY_PATH,
		element: <PartnerInspectionAgencyManagerAssignmentPage />,
	},
	{
		path: TIRE_REPLACEMENT_MANAGEMENT_PATH,
		element: <TireReplacementManagement />,
	},
	{
		path: SERVICE_SCHEDULE_MANAGEMENT_PATH,
		element: <ServiceScheduleManagementPage />,
	},
	{
		path: EMERGENCY_DISPATCH_MANAGEMENT_PATH,
		element: <div>EMERGENCY_DISPATCH_MANAGEMENT_PATH</div>,
	},
	{
		path: SUBSCRIBE_PRODUCT_MANAGEMENT_PATH,
		element: <SubscriptionProductPage />,
	},
	{
		path: `${SUBSCRIBE_PRODUCT_DETAIL_PATH}/:id`,
		element: <SubscribeProductDetailPage />,
	},
	{
		path: SUBSCRIBE_PRODUCT_CREATE_PATH,
		element: <SubscribeProductCreatePage />,
	},
	{
		path: PRODUCT_MANAGEMENT_PATH,
		element: <ProductPage />,
	},
	{
		path: PRODUCT_CREATE_PATH,
		element: <ProductCreatePage />,
	},
	{
		path: `${PRODUCT_DETAIL_PATH}/:id`,
		element: <ProductDetailPage />,
	},
	{
		path: `${SUBPRODUCT_MANAGEMENT_PATH}`,
		element: <SubproductPage />,
	},
	{
		path: `${SUBPRODUCT_CREATE_PATH}`,
		element: <SubproductCreatePage />,
	},
	{
		path: `${SUBPRODUCT_DETAIL_PATH}/:id`,
		element: <SubproductDetailPage />,
	},
	{
		path: RULES_MANAGEMENT_PATH,
		element: <RulesManagementPage />,
	},
	{
		path: VEHICLES_STATUS_MANAGEMENT_PATH,
		element: <VehicleStatusManagementPage />,
	},
	{
		path: `${VEHICLES_STATUS_DETAIL_PATH}/:id`,
		element: <VehicleStatusDetailPage />,
	},
	{
		path: VEHICLES_STATUS_CREATE_PATH,
		element: <VehicleStatusCreate />,
	},
	{
		path: VEHICLES_CURRENT_LOCATIONS_PATH,
		element: <VehiclesCurrentLocations />,
	},
	{
		path: VEHICLE_DTG_PATH,
		element: <VehicleDTGInfo />,
	},
	{ path: VEHICLE_DTG_BY_TRIP_PATH, element: <VehicleTrips /> },
	{
		path: `${VEHICLE_DTG_MAP_BY_TRIP_PATH}/:vehicleNumber`,
		element: <VehiclePathByDateTime />,
	},
	{
		path: VEHICLES_OBD_PATH,
		element: <VehiclesConditionsOBD />,
	},
	{
		path: `${VEHICLE_OBD_PATH}/:vehicleNumber`,
		element: <VehicleOBD />,
	},
	{
		path: VEHICLES_MANAGEMENT_PATH,
		element: <VehiclesManagement />,
	},
	{
		path: `${VEHICLES_DETAIL_PATH}/:id`,
		element: <VehiclesDetail />,
	},
	{
		path: VEHICLES_CREATE_PATH,
		element: <VehiclesCreate />,
	},
	{
		path: PLATES_MANAGEMENT_PATH,
		element: <VehiclePlatesPage />,
	},
	{
		path: `${PLATES_DETAIL_PATH}/:plateNumber`,
		element: <VehiclePlateDetail />,
	},
	{
		path: PLATES_CREATE_PATH,
		element: <VehiclePlateCreate />,
	},
	{
		path: COMPANY_MANAGEMENT_PATH,
		element: <CompanyPage />,
	},
	{
		path: `${COMPANY_DETAIL_PATH}/:companyId`,
		element: <CompanyDetailPage />,
	},
	{
		path: COMPANY_CREATE_PATH,
		element: <CompanyCreate />,
	},
	{
		path: DRIVER_MANAGEMENT_PATH,
		element: <DriversPage />,
	},
	{
		path: `${DRIVER_DETAIL_PATH}/:driverId`,
		element: <DriverDetailPage />,
	},
	{
		path: DRIVER_CREATE_PATH,
		element: <DriverCreate />,
	},
	{
		path: PURCHASE_HISTORY_MANAGEMENT_PATH,
		element: <PurchaseHistoryPage />,
	},
	{
		path: `${PURCHASE_HISTORY_DETAIL_PATH}/:subscriptionNo`,
		element: <PurchaseDetailPage />,
	},
	{
		path: TERMINATION_MANAGEMENT_PATH,
		element: <TerminationPage />,
	},
	{
		path: `${TERMINATION_DETAIL_PATH}/:subscriptionNo`,
		element: <TerminationDetailPage />,
	},
	{
		path: INVENTORY_STATE_PATH,
		element: <div>INVENTORY_STATE_PATH</div>,
	},
	{
		path: INVENTORY_STATE_STANDARD_MANAGEMENT_PATH,
		element: <InventoryPage />,
	},
	{
		path: INVENTORY_STATE_STANDARD_CREATE_PATH,
		element: <InventoryCreatePage />,
	},
	{
		path: `${INVENTORY_STATE_STANDARD_DETAIL_PATH}/:id`,
		element: <InventoryDetailPage />,
	},
	{
		path: MONTHLY_SALES_MANAGEMENT_PATH,
		element: <MonthlySalesManagement />,
	},
	{
		path: `${MONTHLY_SALES_DETAIL_PATH}/:id`,
		element: <MonthlySalesDetailPage />,
	},
	{
		path: MANAGER_SETTLEMENT_MANAGEMENT_PATH,
		element: <div>MANAGER_SETTLEMENT_MANAGEMENT_PATH</div>,
	},
	{
		path: BANNER_MANAGEMENT_PATH,
		element: <div>BANNER_MANAGERMENT_PATH</div>,
	},
	{
		path: CS_TEL_PATH,
		element: <TelInquiryPage />,
	},
	{
		path: `${CS_TEL_DETAIL_PATH}/:id`,
		element: <TelInquiryDetailPage />,
	},
	{
		path: CS_TEL_CREATE_PATH,
		element: <TelInquiryCreatePage />,
	},
	{
		path: CS_CHAT_PATH,
		element: <OneOnOneChatPage />,
	},
	{
		path: `${CS_CHAT_DETAIL_PATH}/:detailId`,
		element: <OneOnOneChatDetailPage />,
	},
	{
		path: CS_FAQ_PATH,
		element: <FaqPage />,
	},
	{
		path: `${CS_FAQ_DETAIL_PATH}/:userId`,
		element: <FaqDetailPage />,
	},
	{
		path: CS_FAQ_CREATE_PATH,
		element: <FaqCreatePage />,
	},
	{
		path: CS_APP_VERSION_MANAGEMENT,
		element: <AppVersionPage />,
	},
	{
		path: `${CS_APP_VERSION_DETAIL_PATH}/:id`,
		element: <AppVersionDetailPage />,
	},
	{
		path: CS_APP_VERSION_CREATE_PATH,
		element: <AppVersionCreatePage />,
	},
	{
		path: CS_NOTICE_PATH,
		element: <NoticePage />,
	},
	{
		path: `${CS_NOTICE_DETAIL_PATH}/:id`,
		element: <NoticeDetailPage />,
	},
	{
		path: CS_NOTICE_CREATE_PATH,
		element: <NoticeCreatePage />,
	},
	{
		path: TERMS_MANAGEMENT_PATH,
		element: <TermsPage />,
	},
	{
		path: TERMS_CREATE_PATH,
		element: <TermsCreatePage />,
	},
	{
		path: `${TERMS_DETAIL_PATH}/:id`,
		element: <TermsDetailPage />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH,
		element: <VehicleSubscriptionsManagement />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH,
		element: <VehicleSubscriptionConsulting />,
	},
	{
		path: `${VEHICLE_SUBSCRIPTION_CONSULTING_DETAIL_PATH}/:consultationId`,
		element: <VehicleSubscriptionConsultingDetail />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_CONSULTING_CREATE_PATH,
		element: <VehicleSubscriptionConsultingCreatePage />,
	},
	{
		path: `${VEHICLE_SUBSCRIPTION_DETAIL_PATH}/:subscriptionNo`,
		element: <VehicleSubscriptionDetailPage />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH,
		element: <VehicleSubscriptionRegisterPage />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_CANCEL_MANAGEMENT_PATH,
		element: <VSCancellationManagement />,
	},
	{
		path: `${VEHICLE_SUBSCRIPTION_CANCEL_DETAIL_PATH}/:subscriptionNo`,
		element: <VSCancellationDetailPage />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_POLICY_MANAGEMENT_PATH,
		element: <VehicleSubscriptionPolicies />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH,
		element: <VehicleSubscriptionsQuotes />,
	},
	{
		path: `${VEHICLE_SUBSCRIPTION_QUOTES_DETAIL_PATH}/:quoteNo`,
		element: <VehicleQuotesDetail />,
	},
	{
		path: VEHICLE_SUBSCRIPTION_QUOTES_CREATE_PATH,
		element: <VehicleQuotesCreate />,
	},
	{
		path: CAR_CARE_SUBSCRIPTION_MANAGEMENT_PATH,
		element: <MyCarCareSubscriptionManagement />,
	},
	{
		path: `${CAR_CARE_SUBSCRIPTION_DETAIL_PATH}/:subscriptionNo`,
		element: <MyCarCareSubscriptionDetailPage />,
	},
];

export const PUBLIC_ROUTES = [
	{
		path: LOGIN_PATH,
		element: <Login />,
	},
	{
		path: PASSWORD_RESET,
		element: <PasswordReset />,
	},
	{
		path: ID_SEARCH,
		element: <UserIdSearch />,
	},
	{
		path: PASSWORD_SEARCH,
		element: <UserPasswordSearch />,
	},
	{
		path: `${ERROR_PATH}/:status`,
		element: <ErrorPage />,
	},
	{
		path: QUOTE_PDF_PATH,
		element: <PublicPdfViwer />,
	},
];
