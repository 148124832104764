import React, { ReactNode, useState } from 'react';
import { Image } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../components/molecules/GridLayout';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { CustomerDto } from '../../../../interface/customer';
import { useGetCustomerDetailQuery } from '../../../../store/apis/customer';
import theme, { ButtonTypeType } from '../../../../styles/theme';
import Button from '../../../../components/atoms/Button';
import { NewTableValueType } from '../../../../utils/table-util';

import { phoneFormat } from '../../../../utils/data-format';
import { useGetSubscriptionDetailByAccountIdQuery } from '../../../../store/apis/subscription';
import {
	CUSTOMER_STATE_DEREGISTERED_VALUE,
	CUSTOMER_STATE_NORMAL_VALUE,
	CUSTOMER_STATE_SUSPENSION_VALUE,
} from '../../../../utils/const';
import { RootState } from '../../../../store';
import { getProviderName } from '../../../../utils/string-util';
import Typo from '../../../../components/atoms/Typo';
import LockModal from './LockModal';
import EditModal from './EditModal';
import { userInfoTableKey, vehicleInfoTableKey } from './detailGridData';

const StatusButton = styled(Button)`
	margin-left: auto;
`;

const GridLayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

interface AccountIdProps {
	accountId: number | undefined;
}

const SubscriptionInfo = ({ accountId }: AccountIdProps) => {
	const { data } = useGetSubscriptionDetailByAccountIdQuery(
		{ accountId },
		{ skip: !accountId },
	);
	const subscribeProductData = data && data.row;

	return (
		<ContentBoxWithHeader
			title="구독 상품 정보"
			borderRadius="10px"
			className="inner-content"
		>
			{subscribeProductData ? (
				<GridLayout $columnCount={2}>
					<GridItem title="구독 상품명" size={GridTitleSize.GT_MEDIUM}>
						{subscribeProductData.subscriptionProductName &&
							subscribeProductData.subscriptionProductName}
					</GridItem>
					<GridItem title="결제 기준일" size={GridTitleSize.GT_MEDIUM}>
						{subscribeProductData.subscriptionStartedAt
							? dayjs(
									subscribeProductData.subscriptionStartedAt as string,
							  ).format('YYYY-MM-DD')
							: '-'}
					</GridItem>
				</GridLayout>
			) : (
				<Typo>구독 상품이 없습니다.</Typo>
			)}
		</ContentBoxWithHeader>
	);
};

const VehicleStatus = ({ accountId }: AccountIdProps) => {
	const { data } = useGetSubscriptionDetailByAccountIdQuery(
		{ accountId },
		{ skip: !accountId },
	);
	const subscribeProductData = data && data.row;
	return (
		<ContentBoxWithHeader
			title="차량 상태"
			borderRadius="10px"
			className="inner-content"
		>
			<GridLayoutWrapper>
				<GridLayout title="차량점검 주기" $columnCount={1}>
					<GridItem
						title="마지막 점검부터 경과일"
						size={GridTitleSize.GT_LARGE}
					>
						{subscribeProductData?.elapsedDateFromLastSubscriptionItemDate
							? `${subscribeProductData.elapsedDateFromLastSubscriptionItemDate}일 경과`
							: '점검 전'}
					</GridItem>
				</GridLayout>
				{subscribeProductData &&
					subscribeProductData.inspectionStatus.map((item, idx) => {
						return (
							<GridLayout
								key={idx.toString()}
								title={item.productName}
								$columnCount={1}
							>
								<GridItem
									title="마지막 점검부터 경과일"
									size={GridTitleSize.GT_LARGE}
								>
									{item?.afterDays || item?.afterDays === 0
										? `${item.afterDays}일 경과`
										: '점검 전'}
								</GridItem>
							</GridLayout>
						);
					})}
			</GridLayoutWrapper>
		</ContentBoxWithHeader>
	);
};

function CustomerDetailPage() {
	const { userId } = useParams();
	const navigate = useNavigate();

	const { user } = useSelector((state: RootState) => state.auth);

	const isUpdateRole =
		user &&
		user.role.roleItems
			.filter((item) => item.isUpdate)
			.find((item) => {
				return item.roleTemplate.roleName.replaceAll(' ', '') === '개인고객';
			});

	const { data, refetch } = useGetCustomerDetailQuery({ userId });
	const customerData = data && data.row;

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isLockModalOpen, setIsLockModalOpen] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(true);
	};
	const handleLockModalOpen = () => {
		setIsLockModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};
	const handleLockModalClose = () => {
		setIsLockModalOpen(false);
	};

	const goBack = () => {
		navigate(-1);
	};

	const renderTable = <T extends Record<keyof T, ReactNode | any>>({
		tableKeys,
		data: tableData,
	}: {
		tableKeys: Record<string, NewTableValueType>;
		data: T;
	}) => {
		const tableKeyList = Object.entries(tableKeys);

		return tableKeyList.map(([key, value], idx) => {
			const tableContent = tableData[key as keyof T];

			const { label, span, bulletOptions } = value;
			switch (key) {
				case 'paymentAt':
				case 'subscriptionStartedAt':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent
								? dayjs(tableContent as string).format('YYYY-MM-DD')
								: '-'}
						</GridItem>
					);
				case 'createdAt':
				case 'provider':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{getProviderName(tableContent as string)}
						</GridItem>
					);
				case 'department':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent ? tableContent.departmentName : '-'}
						</GridItem>
					);

				case 'emailReceivable': {
					const color = (tableContent as boolean) ? 'gray_11' : 'danger_5_main';

					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							<span style={{ color: theme.common.colors[color] }}>
								&#8226; {tableContent ? '동의' : '미동의'}
							</span>
						</GridItem>
					);
				}

				case 'pushReceivable':
				case 'messageReceivable':
				case 'subscribeStatus': {
					const color =
						value.bulletOptions?.find((item) => item.key === tableContent)
							?.color ?? 'black';

					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							<span style={{ color: theme.common.colors[color] }}>
								&#8226;{' '}
								{bulletOptions?.find((item) => item.key === tableContent)
									?.value ?? '-'}
							</span>
						</GridItem>
					);
				}
				case 'customerStatus': {
					let color: keyof typeof theme.common.colors = 'gray_11';
					let viewValue = '-';

					switch (tableContent) {
						case CUSTOMER_STATE_NORMAL_VALUE:
							color = 'gray_11';
							viewValue = '활동중';
							break;
						case CUSTOMER_STATE_SUSPENSION_VALUE:
							color = 'gray_6';
							viewValue = '제한됨';
							break;
						case CUSTOMER_STATE_DEREGISTERED_VALUE:
							color = 'danger_5_main';
							viewValue = '탈퇴';
							break;
						default:
							break;
					}
					return (
						<GridItem
							key={idx.toString()}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							<span style={{ color: theme.common.colors[color] }}>
								&#8226; {viewValue}
							</span>
							{customerData?.customerStatus !==
								CUSTOMER_STATE_DEREGISTERED_VALUE &&
								isUpdateRole && (
									<StatusButton
										type="submit"
										size="small"
										$buttonType={ButtonTypeType.GHOST}
										onClick={handleModalOpen}
									>
										{tableKeys[key].buttonOptions?.label || '상태관리'}
									</StatusButton>
								)}
						</GridItem>
					);
				}
				case 'isLock': {
					const color = !tableContent ? 'gray_11' : 'danger_5_main';
					const viewValue = !tableContent ? '정상' : '잠겨있음';

					return (
						<GridItem
							key={idx.toString()}
							title={value.label}
							size={GridTitleSize.GT_MEDIUM}
							span={value.span}
						>
							<span style={{ color: theme.common.colors[color] }}>
								&#8226; {viewValue}
							</span>
							{customerData?.customerStatus !==
								CUSTOMER_STATE_DEREGISTERED_VALUE &&
								isUpdateRole && (
									<StatusButton
										type="submit"
										size="small"
										disabled={!customerData?.isLock}
										$buttonType={ButtonTypeType.GHOST}
										onClick={handleLockModalOpen}
									>
										{tableKeys[key].buttonOptions?.label || '잠금해제'}
									</StatusButton>
								)}
						</GridItem>
					);
				}
				case 'customerPhone':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							{phoneFormat(tableContent as string)}
						</GridItem>
					);
				case 'vehicleRegistration':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={3}
						>
							<Image width={32} height={32} src={tableContent as string} />
						</GridItem>
					);
				case 'vin':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							{tableContent}
						</GridItem>
					);
				case 'loadingCapacity':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent ? `${tableContent}톤` : '-'}
						</GridItem>
					);
				default:
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
							span={span}
						>
							{tableContent || '-'}
						</GridItem>
					);
			}
		});
	};

	return (
		<GridDetailTemplate detailTitle="회원 상세" onBack={goBack}>
			<ContentBoxWithHeader
				title="회원정보"
				borderRadius="10px"
				className="inner-content"
			>
				<GridLayout>
					{customerData &&
						renderTable({
							tableKeys: userInfoTableKey,
							data: customerData as Omit<CustomerDto, 'vehicle'>,
						})}
				</GridLayout>
			</ContentBoxWithHeader>

			<SubscriptionInfo accountId={customerData?.accountId} />

			<ContentBoxWithHeader
				title="차량정보"
				borderRadius="10px"
				className="inner-content"
			>
				<GridLayout>
					{customerData &&
						customerData.vehicle &&
						renderTable({
							tableKeys: vehicleInfoTableKey,
							data: customerData.vehicle,
						})}
				</GridLayout>
			</ContentBoxWithHeader>

			<VehicleStatus accountId={customerData?.accountId} />

			{customerData && (
				<EditModal
					isVisible={isModalOpen}
					onClose={handleModalClose}
					onRefetch={refetch}
					defaultValue={{
						status: customerData?.customerStatus,
						statusChangedReason: customerData?.statusChangedReason,
					}}
					userId={userId || ''}
				/>
			)}
			{customerData && (
				<LockModal
					isVisible={isLockModalOpen}
					onClose={handleLockModalClose}
					onRefetch={refetch}
					defaultValue={{
						isLock: customerData.isLock,
					}}
					accountId={customerData.accountId}
				/>
			)}
		</GridDetailTemplate>
	);
}

export default CustomerDetailPage;
