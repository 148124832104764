import { useState } from 'react';
import { CallBackProps, STATUS, Step } from 'react-joyride';
import markTutorialCompleted from '../../utils/tutorial-utils';

const useTutorialGuide = (
	userId: number,
	tutorialId: string,
	steps: Step[],
) => {
	const [state, setState] = useState({
		run: true,
		steps,
	});

	const tutorialguideKey = `tutorialguide_${userId}`;
	const tutorialguide = JSON.parse(
		localStorage.getItem(tutorialguideKey) || '{}',
	);
	const localStorageDetail = tutorialguide[tutorialId];

	const handleJoyrideCallback = (data: CallBackProps) => {
		const { status } = data;
		const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

		if (finishedStatuses.includes(status)) {
			setState((currentState) => ({
				...currentState,
				run: false,
			}));
		}

		if (status === STATUS.FINISHED) {
			markTutorialCompleted(userId, tutorialId);
		}

		if (status === STATUS.SKIPPED) {
			markTutorialCompleted(userId, tutorialId);
		}
	};

	return {
		state,
		handleJoyrideCallback,
		localStorageDetail,
	};
};

export default useTutorialGuide;
