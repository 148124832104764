import React from 'react';
import { useNavigate } from 'react-router-dom';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import {
	CUSTOMER_CREATE_PATH,
	CUSTOMER_DETAIL_PATH,
} from '../../../../routes/constants/urls';
import userData from './gridData';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import { useGetCustomersQuery } from '../../../../store/apis/customer';
import useCheckRole from '../../../../hooks/useCheckRole';
import Button from '../../../../components/atoms/Button';

function CustomerManagementPage() {
	const navigate = useNavigate();

	const isCreateCustomer = useCheckRole({
		roleCategory: '회원관리',
		roleName: '개인고객',
		roleType: 'isCreate',
		includeSuper: true,
	});

	const { formInitialValues, colsList, forms } = userData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetCustomersQuery,
		detailPath: CUSTOMER_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="회원 관리"
			gridTitle="회원 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			rightAccessory={
				isCreateCustomer && (
					<Button size="small" onClick={() => navigate(CUSTOMER_CREATE_PATH)}>
						회원계정 생성하기
					</Button>
				)
			}
		/>
	);
}

export default CustomerManagementPage;
