import React, { useEffect, useState } from 'react';
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import {
	useGetCarServiceDashboardByStackDateQuery,
	useGetCarServiceDashBoardGroupsQuery,
	useGetCarServiceDashboardStackDateListQuery,
} from '../../../store/apis/carService';
import useCheckRole from '../../../hooks/useCheckRole';
import ChartGroup from './ChartGroup';
import ContentBox from '../../../components/atoms/ContentBox';
import Button from '../../../components/atoms/Button';
import SelectionModal from '../../../components/organisms/SelectionModal';
import Select from '../../../components/atoms/Select';
import { CarServiceDashboardStackDate } from '../../../interface/carService';
import Typo from '../../../components/atoms/Typo';
import carServiceDashboardGroupColumn from './modalColumn';

const CarServiceDashboard = () => {
	const [selectGroupId, setSelectGroupId] = useState(false);
	const [groupId, setGroupId] = useState('');
	const [groupName, setGroupName] = useState('');
	const [dashboardSelectedStackDate, setDashboardSelectedStackDate] =
		useState('');

	const isDashboardAdmin = useCheckRole({
		roleCategory: '검사대행',
		roleName: '대시보드관리',
		roleType: 'isRead',
	});

	const { data: stackDateDataByGroupId } =
		useGetCarServiceDashboardStackDateListQuery(
			{ groupId },
			{ skip: !groupId },
		);

	const latestDashboardDate =
		stackDateDataByGroupId?.rows[0]?.dashboardStackDate ?? '';
	const latestDashboardTitle =
		stackDateDataByGroupId?.rows[0]?.dashboardStackTitle;

	// 날짜 선택시 뿌려주는 데이터
	const dashboardDateGroup = stackDateDataByGroupId?.rows.map(
		(row: Partial<CarServiceDashboardStackDate>) => ({
			dashboardStackDate: row.dashboardStackDate,
			dashboardStackTitle: row.dashboardStackTitle,
		}),
	);

	const { data: adminDashboardResult } =
		useGetCarServiceDashboardByStackDateQuery(
			{
				groupId,
				dashboardStackDate: dashboardSelectedStackDate,
			},
			{ skip: !isDashboardAdmin || !groupId || !latestDashboardDate },
		);

	useEffect(() => {
		setDashboardSelectedStackDate(latestDashboardDate);
	}, [latestDashboardDate, groupId, isDashboardAdmin]);

	return (
		<GridDetailTemplate detailTitle="대시보드">
			{isDashboardAdmin && (
				<ContentBox borderRadius="10px">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: 12,
							alignItems: 'center',
						}}
					>
						<Button
							onClick={() => {
								setSelectGroupId(true);
							}}
						>
							대상 그룹 선택하기
						</Button>
						{groupId ? (
							<Typo $typoType="h6">{groupName}</Typo>
						) : (
							'데이터를 조회할 대상그룹을 선택해주세요.'
						)}
						{groupName && (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Select
									placeholder="날짜 선택"
									defaultValue={latestDashboardTitle}
									value={
										dashboardSelectedStackDate ||
										adminDashboardResult?.row?.dashboardStackTitle
									}
									style={{ width: '200px' }}
									onChange={(value) => {
										setDashboardSelectedStackDate(value);
									}}
								>
									{dashboardDateGroup?.map((item, idx) => (
										<Select.Option key={idx} value={item.dashboardStackDate}>
											{item.dashboardStackTitle}
										</Select.Option>
									))}
								</Select>
								<Typo $typoType="b5" style={{ marginLeft: 12 }}>
									기준일자: {dashboardSelectedStackDate}
								</Typo>
							</div>
						)}
					</div>

					{selectGroupId && (
						<SelectionModal
							isVisible={selectGroupId}
							onChange={(e) => {
								setGroupId(e[0].dashboardGroupId);
								setGroupName(e[0].dashboardGroupName);
							}}
							title="대상 그룹 선택하기"
							onClose={() => setSelectGroupId(false)}
							column={carServiceDashboardGroupColumn.colsList}
							useQuery={useGetCarServiceDashBoardGroupsQuery}
							defaultSelectedIdList={[groupId]}
							width={600}
						/>
					)}
				</ContentBox>
			)}

			{!isDashboardAdmin ? (
				<ContentBox borderRadius="10px">권한이 없습니다.</ContentBox>
			) : (
				<ChartGroup
					groupId={groupId}
					selectedDate={dashboardSelectedStackDate}
					dashboardData={adminDashboardResult?.row}
				/>
			)}
		</GridDetailTemplate>
	);
};

export default CarServiceDashboard;
