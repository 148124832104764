import React, { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input as AntInput, Select, Radio } from 'antd';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import diff from 'microdiff';
import _ from 'lodash';
import GridDetailTemplate from '../GridDetailTemplate';
import { VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH } from '../../../routes/constants/urls';
import { ButtonContainer } from '../../../pages/CSManagement/AppVersionManagement/AppVersionCreate';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, { GridItem } from '../../molecules/GridLayout';
import { numberWithCommas, phoneFormat } from '../../../utils/data-format';
import DateTimePicker from '../../atoms/DateTimePicker';
import TextArea from '../../atoms/TextArea';
import { SubscriptionProductDto } from '../../../interface/subscriptionProducts';
import useCheckRole from '../../../hooks/useCheckRole';
import {
	useDeleteVehicleSubscriptionQuoteMutation,
	useMakeSubscriptionVehicleQuotesMutation,
	useMakeVehicleSubscriptionQuoteSendMutation,
	useUpdateVehicleSubscriptionQuoteMutation,
} from '../../../store/apis/vehicleSubscriptionQuotes';
import Alert from '../../atoms/Alert';
import Input from '../../atoms/Input';
import { vehicleSubscriptionEndActionType } from '../../../interface/vehicleSubscription';
import Typo from '../../atoms/Typo';
import SelectionModal from '../../organisms/SelectionModal';
import { VehicleDetailDto } from '../../../interface/vehicle';

import {
	subscriptionProductModalColumn,
	vehicleModalColumn,
} from '../modalColumn';
import { useGetVehiclesQuery } from '../../../store/apis/vehicle';
import { useGetSubscriptionProductsQuery } from '../../../store/apis/subscribeProduct';
import {
	MakeVehicleSubscriptionQuoteDto,
	VehicleSubscriptionQuoteDto,
} from '../../../interface/vehicleSubscriptionsQuotes';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import { RootState } from '../../../store';
import TutorialSteps from './tutorialSteps';

interface VehicleSubscriptionQuotesTemplateProps {
	initialValues: {
		rows: (MakeVehicleSubscriptionQuoteDto &
			Partial<VehicleSubscriptionQuoteDto>)[];
	};
}

interface RowErrors {
	customerName?: string;
	customerEmail?: string;
	customerPhone?: string;
	agCommissionPct?: string;
	subscriptionStartedAt?: string;
	subscriptionEndedAt?: string;
	subscriptionPeriod?: string;
	subscriptionPrice?: string;
	vehicleId?: string;
	subscriptionProductId?: string;
}

type QuoteErrors = {
	rows: RowErrors[];
};

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
	DELETE,
	SUBMIT,
}

const customerTypeSelection = ['개인', '법인', '개인사업자'];

const CustomerFormikInput = ({
	value,
	onChange,
	placeholder,
	type = 'text',
	required = false,
	style,
}: {
	value: string | undefined;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	type?: string;
	required?: boolean;
	style?: React.CSSProperties;
}) => (
	<AntInput
		required={required}
		placeholder={placeholder}
		type={type}
		value={value}
		onChange={onChange}
		style={style}
	/>
);

const VehicleGridItems = ({
	vehicle,
}: {
	vehicle: VehicleDetailDto | undefined;
}) => (
	<>
		<GridItem title="차량번호">{vehicle?.vehicleNumber}</GridItem>
		<GridItem title="차대번호">{vehicle?.vin}</GridItem>
		<GridItem title="차종">{vehicle?.vehicleCategory}</GridItem>
		<GridItem title="적재함종류">{vehicle?.carLoadingBoxType}</GridItem>
		<GridItem title="차명">{vehicle?.vehicleName}</GridItem>
		<GridItem title="적재 용량">{vehicle?.loadingCapacity}</GridItem>
	</>
);

const SubscriptionProductGridItems = ({
	product,
}: {
	product: SubscriptionProductDto | undefined;
}) => (
	<>
		<GridItem title="상품명">{product?.subscriptionProductName}</GridItem>
		<GridItem title="설명">{product?.subscriptionProductIntroduction}</GridItem>
		<GridItem title="가격">
			{numberWithCommas(
				(product?.monthlyPrice ?? 0) + (product?.monthlyTax ?? 0),
			)}{' '}
			원
		</GridItem>
		<GridItem title="상세설명" span={3}>
			{product?.subscriptionProductDescription}
		</GridItem>
	</>
);

const VehicleSubscriptionQuotesTemplate = ({
	initialValues,
}: VehicleSubscriptionQuotesTemplateProps) => {
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const { user } = useSelector((state: RootState) => state.auth);
	const userId = user?.id;
	const tutorialId = 'vehicleSubscriptionQuotes';
	const { quoteNo } = useParams();
	const dispatch = useDispatch();
	const toast = useContext(ToastContext);
	const navigate = useNavigate();

	// 구독 차량
	const [subscriptionVehicleIndex, setSubscriptionVehicleIndex] = useState<
		number | null
	>(null);

	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);
	const [isVehicleModal, setIsVehicleModal] = useState(false);
	const [isOpenSpannerModal, setIsOpenSpannerModal] = useState(false);

	// 차량 모달 관련
	const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(0);
	const [selectedVehicles, setSelectedVehicles] = useState<VehicleDetailDto[]>(
		[],
	);

	// 오픈스패너 모달 관련
	const [selectedOpenSpannerIndex, setSelectedOpenSpannerIndex] = useState(0);
	const [selectedOpenSpanners, setSelectedOpenSpanners] = useState<
		SubscriptionProductDto[]
	>([]);

	const [makeVehicleQuote] = useMakeSubscriptionVehicleQuotesMutation();
	const [updateVehicleQuote] = useUpdateVehicleSubscriptionQuoteMutation();
	const [deleteVehicleQuote] = useDeleteVehicleSubscriptionQuoteMutation();
	const [sendVehicleQuote] = useMakeVehicleSubscriptionQuoteSendMutation();

	const isCreateVehicleSubscriptionQuote = useCheckRole({
		roleCategory: '차량구독관리',
		roleName: '신청관리',
		roleType: 'isCreate',
		includeSuper: true,
	});

	const isUpdateVehicleSubscriptionQuote = useCheckRole({
		roleCategory: '차량구독관리',
		roleName: '신청관리',
		roleType: 'isUpdate',
		includeSuper: true,
	});

	const handleCancelClick = () => {
		navigate(VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH);
	};

	const handleCreateSubmit = async (values: typeof initialValues) => {
		const result = await makeVehicleQuote(values);

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '차량 구독 견적서가 등록되었습니다.');
			navigate(VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH);
		}
	};

	// 업데이트 함수
	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const diffValues = diff(initialValues, values);
		const updateValues = { rows: [] };

		diffValues.forEach((item) => {
			if (item.type === 'CREATE' || item.type === 'CHANGE') {
				_.set(updateValues, item.path.join('.'), item.value);
			}
		});

		// 고객 정보 변경
		if (diffValues.length && quoteNo) {
			// promise.all 써서 updatedValues.length만큼 updateVehicleQuote 호출
			const result = await Promise.all(
				updateValues.rows.map((row, index) => {
					if (row) {
						return updateVehicleQuote({
							quoteNo,
							vehicleIndex: Number(initialValues.rows[index].vehicleIndex),
							body: row,
						});
					}
					return Promise.resolve({});
				}),
			);

			if ('error' in result) {
				toast(
					'error',
					(result.error as CustomErrorType).data.translate ||
						(result.error as CustomErrorType).data.message,
				);
			} else {
				toast('info', '차량 구독 견적서가 수정되었습니다.');
				setAlertState(AlertState.NONE);
			}
		} else {
			toast('info', '변경된 내용이 없습니다.');
			setAlertState(AlertState.NONE);
		}
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());
			if (!quoteNo) {
				await handleCreateSubmit(values);
			} else {
				await handleUpdateSubmit(values);
			}
			dispatch(fullLoadingOff());
		},

		validationSchema: Yup.object().shape({
			rows: Yup.array().of(
				Yup.object().shape({
					customerName: Yup.string().required('고객명을 입력해주세요.'),
					customerEmail: Yup.string()
						.required('이메일을 입력해주세요.')
						.email('올바른 이메일을 입력해주세요.'),
					customerPhone: Yup.string()
						.required('연락처를 입력해주세요.')
						.test(
							'phone-validation',
							'올바른 연락처를 입력해주세요.',
							(value) => {
								if (!value) {
									return false;
								}
								const formattedPhone = phoneFormat(value);
								return /^010-\d{3,4}-\d{4}$/.test(formattedPhone);
							},
						),
					agCommissionPct: Yup.number()
						.max(100, 'AG수수료는 100%를 넘을 수 없습니다.')
						.required('AG수수료를 입력해주세요.'),
					subscriptionPrice: Yup.number()
						.required('월 가격을 입력해주세요.')
						.test(
							'is-positive',
							'월 가격은 0보다 커야 합니다.',
							(value) => value > 0,
						),

					subscriptionStartedAt: Yup.date()
						.required('구독 시작일을 입력해주세요.')
						.max(
							Yup.ref('subscriptionEndedAt'),
							'구독 시작일은 구독 종료일 이전이어야 합니다.',
						),

					subscriptionEndedAt: Yup.date()
						.required('구독 종료일을 입력해주세요.')
						.min(
							Yup.ref('subscriptionStartedAt'),
							'구독 종료일은 구독 시작일 이후여야 합니다.',
						),

					subscriptionPeriod: Yup.number()
						.required('구독 기간을 입력해주세요.')
						.test(
							'is-valid-duration',
							'구독 기간은 0 이상이어야 합니다.',
							(value) => value !== undefined && value >= 0,
						),

					vehicleId: Yup.number()
						.required('차량을 선택해주세요.')
						.test('is-positive', '차량을 선택해주세요.', (value) => value > 0),
					subscriptionProductId: Yup.number()
						.required('오픈스패너를 선택해주세요.')
						.test(
							'is-positive',
							'오픈스패너를 선택해주세요.',
							(value) => value > 0,
						),
				}),
			),
		}),
		validateOnChange: isTrySubmit,
	});

	// 고객 정보 onChange 핸들러
	const handleFieldChange = (field: string, value: string) => {
		const updatedRows = formik.values.rows.map(
			(row: MakeVehicleSubscriptionQuoteDto) => ({
				...row,
				[field]: value,
			}),
		);
		formik.setFieldValue('rows', updatedRows);
	};

	const inputStyle = (hasError: string) => ({
		backgroundColor: hasError ? 'pink' : '',
		border: hasError ? '1px solid red' : '',
	});

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleOpenVehicleModal = (index: number) => {
		setSelectedVehicleIndex(index);
		setIsVehicleModal(true);
	};

	const handleCloseVehicleModal = () => {
		setSelectedVehicleIndex(0);
		setIsVehicleModal(false);
	};

	const handleVehicleChange = (vehicles: VehicleDetailDto[]) => {
		if (selectedVehicleIndex != null && vehicles.length > 0) {
			const [selectedVehicle] = vehicles;

			formik.setFieldValue(
				`rows[${selectedVehicleIndex}].vehicleId`,
				selectedVehicle.id,
			);

			setSelectedVehicles((prev) => {
				const newSelections = [...prev];
				newSelections[selectedVehicleIndex] = selectedVehicle;
				return newSelections;
			});

			handleCloseVehicleModal();
		}
	};

	const handleOpenSpannerModal = (index: number) => {
		setSelectedOpenSpannerIndex(index);
		setIsOpenSpannerModal(true);
	};

	const handleCloseSpannerModal = () => {
		setSelectedOpenSpannerIndex(0);
		setIsOpenSpannerModal(false);
	};

	const handleOpenSpannerChange = (spanners: SubscriptionProductDto[]) => {
		if (selectedOpenSpannerIndex !== null && spanners.length > 0) {
			const [selectedSpanner] = spanners;

			// Update Formik field value
			formik.setFieldValue(
				`rows[${selectedOpenSpannerIndex}].subscriptionProductId`,
				selectedSpanner.id,
			);

			// Update local state

			setSelectedOpenSpanners((prev) => {
				const updatedSpanners = [...prev];
				if (selectedOpenSpannerIndex < updatedSpanners.length) {
					updatedSpanners[selectedOpenSpannerIndex] = selectedSpanner;
				} else {
					updatedSpanners.push(selectedSpanner);
				}
				return updatedSpanners;
			});

			handleCloseSpannerModal();
		}
	};

	// 해당 차량의 에러 메시지만 띄우기 (MAX. 구독 차량 갯수)
	const handleAction = useCallback(
		async (type: number) => {
			setIsTrySubmit(true);

			const errorObject = (await formik.validateForm(
				formik.values,
			)) as QuoteErrors;
			const rowsErrors = errorObject.rows || [];
			const isValid = rowsErrors.every(
				(rowError) => rowError && Object.keys(rowError).length === 0,
			);

			if (isValid) {
				setAlertState(type);
			} else {
				const subscriptionError: Record<number, string[]> = {};

				rowsErrors.forEach((rowError, index) => {
					if (rowError) {
						subscriptionError[index] = Object.values(rowError).filter(Boolean);
					}
				});

				const sortedIndices = Object.keys(subscriptionError)
					.map(Number)
					.sort((a, b) => b - a);

				// errors를 모아서 토스트로 띄워주기
				sortedIndices.forEach((index) => {
					const errorMessages = subscriptionError[index];
					if (errorMessages.length > 0) {
						toast('error', `구독 ${index + 1}: ${errorMessages.join(', ')}`);
					}
				});
			}
		},
		[formik, toast],
	);

	const handleUpdateClick = async (index: number) => {
		setSelectedVehicleIndex(index);

		await handleAction(AlertState.UPDATE);
	};

	const handleDeleteSubscriptionVehicle = (index: number) => {
		setSubscriptionVehicleIndex(index);
		setAlertState(AlertState.DELETE);
	};

	// 견적서 발송
	const handleQuoteSend = useCallback(async () => {
		if (quoteNo) {
			const pdfUrl = `${
				process.env.REACT_APP_ENV === 'production'
					? 'https://members.openmile.kr'
					: 'https://dev-members.openmile.kr'
			}/quote/pdf`;
			const message = `${pdfUrl}?quoteNo=${quoteNo}`;
			const result = await sendVehicleQuote({
				quoteNo,
				body: {
					customerPhone: formik.values.rows[0].customerPhone,
					link: message, // link는 고객에게 보낼 견적 링크
				},
			});

			if ('error' in result) {
				toast(
					'error',
					(result.error as CustomErrorType).data.translate ||
						(result.error as CustomErrorType).data.message,
				);
			} else {
				toast('info', '견적서가 발송되었습니다.');
				navigate(VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH);
			}
		}
	}, [formik.values.rows, navigate, quoteNo, sendVehicleQuote, toast]);

	const isQuoteSent = formik.values.rows[0]?.quoteSentAt !== null;

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.CREATE:
				return (
					<Alert
						title="견적서 등록"
						onConfirmButtonText="등록하기"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.CREATE}
					>
						견적서를 등록하시겠습니까?
					</Alert>
				);

			case AlertState.UPDATE:
				return (
					<Alert
						title="견적서 수정"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="수정하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						견적서를 수정하시겠습니까?
					</Alert>
				);

			case AlertState.SUBMIT:
				return (
					<Alert
						title="견적서 발송"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="발송하기"
						onConfirmButtonClick={handleQuoteSend}
						isVisible={alertState === AlertState.SUBMIT}
					>
						견적을 발송하시겠습니까? 고객정보에 입력한 연락처로 문자 메시지가
						발송됩니다.
					</Alert>
				);

			default:
				return null;
		}
	}, [alertState, formik, handleQuoteSend]);

	return (
		<>
			<TutorialSteps userId={userId} tutorialId={tutorialId} />

			<FormikProvider value={formik}>
				<GridDetailTemplate
					detailTitle="차량구독 견적서"
					onBack={handleCancelClick}
					rightAccessory={
						<ButtonContainer>
							<Button
								$buttonType={ButtonTypeType.OPAQUE}
								onClick={handleCancelClick}
								size="small"
							>
								취소
							</Button>
							{quoteNo ? (
								<div id="step-3">
									<Button
										onClick={() => handleAction(AlertState.SUBMIT)}
										size="small"
										disabled={!isUpdateVehicleSubscriptionQuote}
									>
										{isUpdateVehicleSubscriptionQuote
											? '견적 발송'
											: '발송 권한없음'}

										{isQuoteSent && '(발송 완료)'}
									</Button>
								</div>
							) : (
								<Button
									onClick={() => handleAction(AlertState.CREATE)}
									size="small"
									disabled={!isCreateVehicleSubscriptionQuote}
								>
									{isCreateVehicleSubscriptionQuote
										? '등록하기'
										: '등록 권한없음'}
								</Button>
							)}
						</ButtonContainer>
					}
				>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
						<ContentBoxWithHeader
							title="고객 정보"
							borderRadius="10px"
							$isCompact
							id="step-1"
							rightAccessory={
								quoteNo && (
									<Button
										onClick={() => handleUpdateClick(selectedVehicleIndex)}
										size="small"
										disabled={!isUpdateVehicleSubscriptionQuote}
									>
										{isUpdateVehicleSubscriptionQuote
											? '수정'
											: '수정 권한없음'}
									</Button>
								)
							}
						>
							<GridLayout $columnCount={2}>
								<GridItem title="고객 유형" $isCompact>
									<Select
										value={formik.values.rows[0]?.customerType}
										style={{ width: '100%' }}
										onChange={(e) => {
											const newCustomerType = e;

											const updatedRows = formik.values.rows.map(
												(row: MakeVehicleSubscriptionQuoteDto) => ({
													...row,
													customerType: newCustomerType,
												}),
											);

											formik.setFieldValue('rows', updatedRows);
										}}
									>
										{customerTypeSelection.map((customer) => (
											<Select.Option key={customer} value={customer}>
												{customer}
											</Select.Option>
										))}
									</Select>
								</GridItem>
								<GridItem title="고객명" $isCompact essential>
									<CustomerFormikInput
										required
										placeholder="고객명을 입력해 주세요"
										value={formik.values.rows[0]?.customerName ?? ''}
										onChange={(e) =>
											handleFieldChange('customerName', e.target.value)
										}
										style={inputStyle(
											(formik.errors.rows as RowErrors[])?.[0]?.customerName ||
												'',
										)}
									/>
								</GridItem>
								<GridItem title="연락처" $isCompact essential>
									<CustomerFormikInput
										required
										placeholder="연락처를 입력해 주세요"
										value={
											phoneFormat(formik.values.rows[0]?.customerPhone) ?? ''
										}
										onChange={(e) =>
											handleFieldChange(
												'customerPhone',
												e.target.value.replaceAll('-', ''),
											)
										}
										style={inputStyle(
											(formik.errors.rows as RowErrors[])?.[0]?.customerPhone ||
												'',
										)}
									/>
								</GridItem>
								<GridItem title="이메일" $isCompact essential>
									<CustomerFormikInput
										placeholder="이메일을 입력해 주세요"
										type="email"
										value={formik.values.rows[0]?.customerEmail ?? ''}
										onChange={(e) =>
											handleFieldChange('customerEmail', e.target.value)
										}
										style={inputStyle(
											(formik.errors.rows as RowErrors[])?.[0]?.customerEmail ||
												'',
										)}
									/>
								</GridItem>
								{formik.values.rows[0]?.customerType !== '개인' && (
									<GridItem title="담당자 이름" $isCompact>
										<CustomerFormikInput
											placeholder="담당자를 입력해 주세요"
											value={formik.values.rows[0]?.contactName ?? ''}
											onChange={(e) =>
												handleFieldChange('contactName', e.target.value)
											}
										/>
									</GridItem>
								)}
							</GridLayout>
						</ContentBoxWithHeader>
					</div>

					<div style={{ marginTop: '1rem' }}>
						<FieldArray name="rows">
							{({ push, remove }) => (
								<div>
									{formik.values.rows.map(
										(row: MakeVehicleSubscriptionQuoteDto, index: number) => (
											<div key={index} style={{ marginBottom: '1rem' }}>
												<ContentBoxWithHeader
													title={`구독 차량 ${index + 1}`}
													borderRadius="10px"
													className="inner-content"
													id="step-2"
													rightAccessory={
														<div style={{ display: 'flex' }}>
															{quoteNo && (
																<Button
																	type="button"
																	size="small"
																	onClick={() => handleUpdateClick(index)}
																>
																	수정
																</Button>
															)}
															{formik.values.rows.length > 1 && (
																<Button
																	type="button"
																	size="small"
																	$buttonType={ButtonTypeType.GHOST_DANGER}
																	style={{ marginLeft: '0.5rem' }}
																	onClick={() => {
																		handleDeleteSubscriptionVehicle(index);
																	}}
																>
																	삭제
																</Button>
															)}
															{subscriptionVehicleIndex === index && (
																<Alert
																	title="구독차량 삭제"
																	closeButtonClick={handleAlertCloseClick}
																	onConfirmButtonText="삭제하기"
																	onConfirmButtonClick={() => {
																		if (subscriptionVehicleIndex !== null) {
																			remove(subscriptionVehicleIndex);
																			selectedVehicles.splice(index, 1);
																			selectedOpenSpanners.splice(index, 1);
																			if (quoteNo) {
																				deleteVehicleQuote({
																					quoteNo,
																					vehicleIndex:
																						subscriptionVehicleIndex,
																				});
																			}
																		}
																		setAlertState(AlertState.NONE);
																	}}
																	isVisible={alertState === AlertState.DELETE}
																>
																	구독차량을 삭제하시겠습니까?
																</Alert>
															)}
														</div>
													}
												>
													<ContentBoxWithHeader
														title="구독 정보"
														borderRadius="10px"
														$isCompact
													>
														<GridLayout $columnCount={2}>
															<GridItem title="구독시작일" $isCompact>
																<DateTimePicker
																	name={`[${index}].subscriptionStartedAt`}
																	value={dayjs(
																		row.subscriptionStartedAt ??
																			new Date().toISOString(),
																	)}
																	onChange={(value: dayjs.Dayjs | null) => {
																		const startDate =
																			value?.toISOString() || '';
																		formik.setFieldValue(
																			`rows[${index}].subscriptionStartedAt`,
																			startDate,
																		);

																		const endDate = dayjs(
																			formik.values.rows[index]
																				.subscriptionEndedAt ||
																				new Date().toISOString(),
																		);
																		if (endDate.isValid() && value) {
																			const newPeriod = endDate.diff(
																				dayjs(startDate),
																				'day',
																			);
																			formik.setFieldValue(
																				`rows[${index}].subscriptionPeriod`,
																				newPeriod,
																			);
																		}
																	}}
																	picker="date"
																/>
															</GridItem>
															<GridItem title="구독종료일" $isCompact>
																<DateTimePicker
																	name={`[${index}].subscriptionEndedAt`}
																	value={dayjs(
																		row.subscriptionEndedAt ??
																			new Date().toISOString(),
																	)}
																	onChange={(value: dayjs.Dayjs | null) => {
																		const endDate = value?.toISOString() || '';
																		formik.setFieldValue(
																			`rows[${index}].subscriptionEndedAt`,
																			endDate,
																		);

																		const startDate = dayjs(
																			formik.values.rows[index]
																				.subscriptionStartedAt ||
																				new Date().toISOString(),
																		);

																		if (startDate.isValid() && value) {
																			const newPeriod = dayjs(endDate).diff(
																				startDate,
																				'day',
																			);
																			formik.setFieldValue(
																				`rows[${index}].subscriptionPeriod`,
																				newPeriod,
																			);
																		}
																	}}
																	picker="date"
																/>
															</GridItem>
															<GridItem title="구독기간" $isCompact>
																<Input
																	name="subscriptionPeriod"
																	placeholder="구독기간"
																	type="number"
																	$inputSize="small"
																	$inputRadius="small"
																	$hasError={
																		!!(formik.errors.rows as RowErrors[])?.[
																			index
																		]?.subscriptionStartedAt ||
																		!!(formik.errors.rows as RowErrors[])?.[
																			index
																		]?.subscriptionPeriod
																	}
																	value={dayjs(
																		formik.values.rows[index]
																			.subscriptionEndedAt || undefined,
																	).diff(
																		dayjs(
																			formik.values.rows[index]
																				.subscriptionStartedAt || undefined,
																		),
																		'day',
																	)}
																	onChange={formik.handleChange}
																	$isDisabled
																	unitText="일"
																	$isCompact
																/>
															</GridItem>
															<GridItem title="만기시" $isCompact>
																<Select
																	style={{ minWidth: '120px' }}
																	value={
																		formik.values.rows[index].endActionType
																	}
																	onChange={(e) =>
																		formik.setFieldValue(
																			`rows[${index}].endActionType`,
																			e,
																		)
																	}
																>
																	{vehicleSubscriptionEndActionType.map(
																		(item) => (
																			<Select.Option key={item} value={item}>
																				{item}
																			</Select.Option>
																		),
																	)}
																</Select>
															</GridItem>
															<GridItem title="월 구독료" $isCompact span={2}>
																<Input
																	name={`[${index}].subscriptionPrice`}
																	placeholder="월 구독료"
																	type="text"
																	$inputSize="small"
																	$inputRadius="small"
																	$hasError={
																		!!(formik.errors.rows as RowErrors[])?.[
																			index
																		]?.subscriptionPrice
																	}
																	value={numberWithCommas(
																		row.subscriptionPrice,
																	)}
																	onChange={(e) =>
																		formik.setFieldValue(
																			`rows[${index}].subscriptionPrice`,
																			Number(
																				String(e.target.value).replace(
																					/[^0-9]/g,
																					'',
																				),
																			),
																		)
																	}
																	onReset={() =>
																		formik.setFieldValue('subscriptionPrice', 0)
																	}
																	unitText="원"
																	$isCompact
																/>
															</GridItem>
															<GridItem title="차량가" $isCompact>
																<Input
																	name={`[${index}].vehiclePrice`}
																	placeholder="차량가"
																	type="text"
																	$inputSize="small"
																	$inputRadius="small"
																	value={numberWithCommas(
																		formik.values.rows[index].vehiclePrice,
																	)}
																	onChange={(e) =>
																		formik.setFieldValue(
																			`rows[${index}].vehiclePrice`,
																			Number(
																				String(e.target.value).replace(
																					/[^0-9]/g,
																					'',
																				),
																			),
																		)
																	}
																	onReset={() =>
																		formik.setFieldValue('vehiclePrice', 0)
																	}
																	unitText="원"
																	$isCompact
																/>
															</GridItem>
															<GridItem title="선납금" $isCompact>
																<Input
																	name={`[${index}].prePaymentPrice`}
																	placeholder="선납금"
																	type="text"
																	$inputSize="small"
																	$inputRadius="small"
																	value={numberWithCommas(
																		formik.values.rows[index].prePaymentPrice,
																	)}
																	onChange={(e) =>
																		formik.setFieldValue(
																			`rows[${index}].prePaymentPrice`,
																			Number(
																				String(e.target.value).replace(
																					/[^0-9]/g,
																					'',
																				),
																			),
																		)
																	}
																	onReset={() =>
																		formik.setFieldValue('prePaymentPrice', 0)
																	}
																	unitText="원"
																	$isCompact
																/>
															</GridItem>
															<GridItem title="인수잔가" $isCompact>
																<Input
																	name={`[${index}].acquisitionPrice`}
																	placeholder="인수잔가"
																	type="text"
																	$inputSize="small"
																	$inputRadius="small"
																	value={numberWithCommas(
																		formik.values.rows[index].acquisitionPrice,
																	)}
																	onChange={(e) =>
																		formik.setFieldValue(
																			`rows[${index}].acquisitionPrice`,
																			Number(
																				String(e.target.value).replace(
																					/[^0-9]/g,
																					'',
																				),
																			),
																		)
																	}
																	onReset={() =>
																		formik.setFieldValue('acquisitionPrice', 0)
																	}
																	unitText="원"
																	$isCompact
																/>
															</GridItem>
															<GridItem title="AG수수료" $isCompact>
																<Input
																	name={`[${index}].agCommissionPct`}
																	placeholder="AG수수료"
																	type="number"
																	$inputSize="small"
																	$inputRadius="small"
																	$hasError={
																		!!(formik.errors.rows as RowErrors[])?.[
																			index
																		]?.agCommissionPct
																	}
																	value={
																		formik.values.rows[index].agCommissionPct
																	}
																	onChange={(e) =>
																		formik.setFieldValue(
																			`rows[${index}].agCommissionPct`,
																			e.target.value
																				? Number(e.target.value)
																				: '',
																		)
																	}
																	onReset={() =>
																		formik.setFieldValue('agCommissionPct', 0)
																	}
																	unitText="%"
																	$isCompact
																/>
															</GridItem>
															<GridItem title="번호판신청여부" $isCompact>
																<Radio.Group
																	onChange={(e) => {
																		formik.setFieldValue(
																			`rows[${index}].requestVehiclePlate`,
																			e.target.value,
																		);
																	}}
																	value={
																		formik.values.rows[index]
																			.requestVehiclePlate
																	}
																>
																	<Radio value>
																		<Typo $typoType="label1">신청함</Typo>
																	</Radio>
																	<Radio value={false}>
																		<Typo $typoType="label1">신청안함</Typo>
																	</Radio>
																</Radio.Group>
															</GridItem>
															<GridItem title="결제방법" $isCompact>
																<Select
																	style={{ minWidth: '120px' }}
																	value={
																		formik.values.rows[index].paymentMethod
																	}
																	onChange={(e) =>
																		formik.setFieldValue(
																			`rows[${index}].paymentMethod`,
																			e,
																		)
																	}
																>
																	<Select.Option value="신용카드">
																		신용카드
																	</Select.Option>
																	<Select.Option value="현금">
																		현금
																	</Select.Option>
																</Select>
															</GridItem>
														</GridLayout>
													</ContentBoxWithHeader>

													<div
														style={{
															margin: '1rem 0',
														}}
													>
														<ContentBoxWithHeader
															title="차량 정보"
															borderRadius="10px"
															$isCompact
															rightAccessory={
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	{formik.errors.rows?.[index] &&
																		!selectedVehicles[index]?.vehicleNumber && (
																			<div
																				style={{
																					color: 'red',
																					fontWeight: 'bold',
																					marginRight: '5px',
																				}}
																			>
																				차량을 선택해주세요.
																			</div>
																		)}
																	<Button
																		size="small"
																		$buttonType={ButtonTypeType.GHOST}
																		onClick={() =>
																			handleOpenVehicleModal(index)
																		}
																	>
																		차량 선택
																	</Button>
																</div>
															}
														>
															<GridLayout $columnCount={3} $isCompact>
																{isVehicleModal && (
																	<SelectionModal
																		isVisible={isVehicleModal}
																		title="계약차량 선택"
																		onClose={() => setIsVehicleModal(false)}
																		onChange={handleVehicleChange}
																		column={vehicleModalColumn.colsList}
																		useQuery={useGetVehiclesQuery}
																		searchInput={vehicleModalColumn.forms}
																	/>
																)}
																{selectedVehicles[index] ? (
																	<VehicleGridItems
																		vehicle={selectedVehicles[index]}
																	/>
																) : (
																	<VehicleGridItems
																		vehicle={formik.values.rows[index]?.vehicle}
																	/>
																)}
															</GridLayout>
														</ContentBoxWithHeader>
													</div>

													<div
														style={{
															margin: '1rem 0',
														}}
													>
														<ContentBoxWithHeader
															title="오픈스패너 정보"
															borderRadius="10px"
															$isCompact
															rightAccessory={
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	{formik.errors.rows?.[index] &&
																		!selectedOpenSpanners[index] && (
																			<div
																				style={{
																					color: 'red',
																					fontWeight: 'bold',
																					marginRight: '5px',
																				}}
																			>
																				오픈스패너를 선택해주세요.
																			</div>
																		)}
																	<Button
																		size="small"
																		$buttonType={ButtonTypeType.GHOST}
																		onClick={() =>
																			handleOpenSpannerModal(index)
																		}
																	>
																		오픈스패너 선택
																	</Button>
																</div>
															}
														>
															<GridLayout $columnCount={3} $isCompact>
																{isOpenSpannerModal && (
																	<SelectionModal
																		isVisible={isOpenSpannerModal}
																		title="계약차량 선택"
																		onClose={() => setIsOpenSpannerModal(false)}
																		onChange={handleOpenSpannerChange}
																		column={
																			subscriptionProductModalColumn.colsList
																		}
																		useQuery={useGetSubscriptionProductsQuery}
																		searchInput={
																			subscriptionProductModalColumn.forms
																		}
																		defaultSelectedIdList={[
																			formik.values.rows[index]
																				?.subscriptionProductId,
																		]}
																		width={500}
																	/>
																)}
																{selectedOpenSpanners[index] ? (
																	<SubscriptionProductGridItems
																		product={selectedOpenSpanners[index]}
																	/>
																) : (
																	<SubscriptionProductGridItems
																		product={
																			formik.values.rows[index]
																				?.subscriptionProduct
																		}
																	/>
																)}
															</GridLayout>
														</ContentBoxWithHeader>
													</div>

													<div style={{ marginTop: '1rem' }}>
														<ContentBoxWithHeader
															title="메모"
															borderRadius="10px"
															$isCompact
														>
															<TextArea
																placeholder="메모 내용을 작성해 주세요"
																name={`rows[${index}].memo`}
																value={formik.values.rows[index].memo ?? ''}
																onChange={formik.handleChange}
															/>
														</ContentBoxWithHeader>
													</div>

													{index === formik.values.rows.length - 1 &&
														!quoteNo && (
															<Button
																style={{ marginTop: '1rem' }}
																onClick={() => {
																	const newRow = {
																		acquisitionPrice: 0,
																		agCommissionPct: 0,
																		consultationId: 1,
																		customerEmail:
																			formik.values.rows[0].customerEmail || '',
																		customerName:
																			formik.values.rows[0].customerName || '',
																		customerPhone:
																			formik.values.rows[0].customerPhone || '',
																		customerType:
																			formik.values.rows[0].customerType || '',
																		endActionType: '반납',
																		memo: '',
																		paymentMethod: '현금',
																		prePaymentPrice: 0,
																		requestVehiclePlate: false,
																		subscriptionEndedAt:
																			dayjs().format('YYYY-MM-DD'),
																		subscriptionPeriod: 0,
																		subscriptionPrice: 0,
																		subscriptionProductId: 0,
																		subscriptionStartedAt:
																			dayjs().format('YYYY-MM-DD'),
																		vehicleId: 0,
																		vehiclePrice: 0,
																	};
																	push(newRow);
																}}
															>
																견적 추가
															</Button>
														)}
												</ContentBoxWithHeader>
											</div>
										),
									)}
								</div>
							)}
						</FieldArray>
					</div>
				</GridDetailTemplate>

				{renderAlert()}
			</FormikProvider>
		</>
	);
};

export default VehicleSubscriptionQuotesTemplate;
