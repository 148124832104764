import dayjs, { Dayjs } from 'dayjs';

export const transformTimeToHour = (time: number, multiple = 3600) => {
	const hour = Math.floor(time / multiple);
	const minute = Math.round((time / multiple - hour) * 60);
	return hour ? `${hour}시간 ${minute}분` : `${minute}분`;
};

export const formatDateString = (date: Dayjs | string, withTime = false) => {
	if (date) {
		if (!withTime) return dayjs(date).format('YYYY-MM-DD');
		return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
	}
	return '';
};

export const transportDatetime = (datetime: string) => {
	const now = dayjs();
	const timeValue = dayjs(datetime);

	const betweenMinutes = now.diff(timeValue, 'minute');
	if (betweenMinutes < 1) return '방금전';
	if (betweenMinutes < 60) {
		return `${betweenMinutes}분전`;
	}

	const betweenHours = now.diff(timeValue, 'hour');
	if (betweenHours < 24) {
		return `${betweenHours}시간전`;
	}

	const betweenDays = now.diff(timeValue, 'day');
	if (betweenDays < 365) {
		return `${betweenDays}일전`;
	}

	return `${Math.floor(betweenDays / 365)}년전`;
};

export const getTodayDateRange = () => {
	const startDate = dayjs().startOf('day');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const getWeekDateRange = () => {
	const startDate = dayjs().subtract(1, 'week');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const get1MonthDateRange = () => {
	const startDate = dayjs().subtract(1, 'M');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const get3MonthDateRange = () => {
	const startDate = dayjs().subtract(3, 'M');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const get1YearDateRange = () => {
	const startDate = dayjs().subtract(1, 'year');
	const endDate = dayjs().startOf('day');
	return [startDate, endDate];
};

export const getYesterdayDateRange = () => {
	const startDate = dayjs().subtract(1, 'day');
	const endDate = dayjs().subtract(1, 'day');
	return [startDate, endDate];
};

export const formatTimeToString = (time: string) => {
	return `${time.substring(0, 2)}시 
	${time.substring(2, 4)}분 ${time.substring(4, 6)}초`;
};
