import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../components/molecules/GridLayout';
import { useGetVehicleOBDInfoQuery } from '../../../../store/apis/vehicleConditions';
import Button from '../../../../components/atoms/Button';
import { ButtonTypeType } from '../../../../styles/theme';
import Icon from '../../../../components/atoms/Icon';
import { resetIcon } from '../../../../assets/icon';
import Select from '../../../../components/atoms/Select';
import { stringWithCommas } from '../../../../utils/data-format';
import Badge from '../../../../components/atoms/Badge';
import { VEHICLE_OBD_PATH } from '../../../../routes/constants/urls';
import { testCarList } from '../../VehicleDTG/dummy';
import { transportDatetime } from '../../../../utils/date-util';

const VehicleOBD = () => {
	const { vehicleNumber } = useParams();
	const navigate = useNavigate();

	const goTo = (route: string) => {
		navigate(route);
	};

	const [vehicleNumberList] = useState(testCarList); // 현장에서 확인한다고 더미 데이터 사용
	const { data, isLoading, isFetching, refetch } = useGetVehicleOBDInfoQuery({
		vehicleNumber: vehicleNumber || vehicleNumberList[0],
	});

	const obdInfo = data?.row;

	return (
		<GridDetailTemplate
			detailTitle="실시간 차량상태"
			onBack={() => goTo('/vehicles/obd')}
		>
			<Select
				onChange={(value) => {
					navigate(`${VEHICLE_OBD_PATH}/${value}`);
				}}
				placeholder="차량번호"
				value={vehicleNumber}
				showSearch
			>
				{vehicleNumberList.map((vehicleNum) => (
					<Select.Option key={vehicleNum} value={vehicleNum}>
						{vehicleNum}
					</Select.Option>
				))}
			</Select>
			<ContentBoxWithHeader
				title={vehicleNumber || '차량을 선택해주세요'}
				borderRadius="10px"
				className="inner-content"
				rightAccessory={
					<Button
						size="x-small"
						$buttonType={ButtonTypeType.NO_LINE}
						onClick={refetch}
					>
						<Icon
							src={resetIcon}
							className="reset-icon"
							width={24}
							height={24}
						/>
					</Button>
				}
			>
				{obdInfo && (
					<div style={{ marginBottom: '8px' }}>
						<Badge color="red">
							측정시간 :{' '}
							{transportDatetime(
								dayjs(
									`20${obdInfo.reportedAt.slice(
										0,
										6,
									)}T${obdInfo.reportedAt.slice(6)}`,
								).toISOString(),
							)}
						</Badge>
						<Badge color="blue">
							{dayjs(
								`20${obdInfo.reportedAt.slice(0, 6)}T${obdInfo.reportedAt.slice(
									6,
								)}`,
							).format('MM월 DD일(dd) A hh시 mm분 ss초')}
						</Badge>
					</div>
				)}
				<GridLayout $columnCount={4}>
					{isLoading || isFetching ? (
						<Skeleton active style={{ marginTop: '1rem' }} />
					) : (
						<>
							<GridItem key="v" title="속도" size={GridTitleSize.GT_MEDIUM}>
								{obdInfo && stringWithCommas(obdInfo.v)} km/h
							</GridItem>
							<GridItem key="r" title="RPM" size={GridTitleSize.GT_MEDIUM}>
								{obdInfo && stringWithCommas(obdInfo.r)} r/min
							</GridItem>
							<GridItem
								key="m"
								title="총 주행거리"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && stringWithCommas(obdInfo.m)} km
							</GridItem>
							<GridItem
								key="bv"
								title="배터리 전압"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.bv} V
							</GridItem>
							<GridItem
								key="ftli"
								title="연료 잔량"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.ftli} %
							</GridItem>
							<GridItem key="a" title="방위각" size={GridTitleSize.GT_MEDIUM}>
								{obdInfo && obdInfo.a} degC
							</GridItem>
							<GridItem
								key="f"
								title="총 연료 소모량"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo ? obdInfo.f : 0} L
							</GridItem>
							<GridItem
								key="map"
								title="흡기압력"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.map} kPA
							</GridItem>
							<GridItem
								key="mat"
								title="흡기온도"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.mat} degC
							</GridItem>
							<GridItem
								key="sf"
								title="단기연료 보정"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.sf} %
							</GridItem>
							<GridItem
								key="lf"
								title="장기연료 보정"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.lf} %
							</GridItem>
							<GridItem
								key="maf"
								title="공기 유량"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.maf} g/s
							</GridItem>
							<GridItem
								key="ep"
								title="엔진 부하"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.ep} %
							</GridItem>
							<GridItem
								key="eot"
								title="엔진오일 온도"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.eot} degC
							</GridItem>
							<GridItem
								key="cwt"
								title="냉각수 온도"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.cwt} degC
							</GridItem>
							<GridItem
								key="mv"
								title="모듈 전압"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.mv} V
							</GridItem>
							<GridItem
								key="abp"
								title="대기 온도"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.abp} degC
							</GridItem>
							<GridItem key="abt" title="대기압" size={GridTitleSize.GT_MEDIUM}>
								{obdInfo && obdInfo.abt} psi
							</GridItem>
							<GridItem
								key="tp"
								title="타이어 압력"
								size={GridTitleSize.GT_MEDIUM}
							>
								{obdInfo && obdInfo.tp} psi
							</GridItem>
						</>
					)}
				</GridLayout>
			</ContentBoxWithHeader>
		</GridDetailTemplate>
	);
};

export default VehicleOBD;
