import {
	menu01Icon,
	menu02Icon,
	menu03Icon,
	menu04Icon,
	menu05Icon,
	menu06Icon,
	menu07Icon,
	menu08Icon,
	menu09Icon,
	menu10Icon,
} from '../../../assets/icon';
import {
	CUSTOMER_MANAGEMENT_PATH,
	CUSTOMER_DETAIL_PATH,
	MANAGER_MANAGEMENT_PATH,
	MANAGER_DETAIL_PATH,
	ADMIN_MANAGEMENT_PATH,
	ADMIN_CREATE_PATH,
	ADMIN_DETAIL_PATH,
	ADMIN_ROLE_MANAGEMENT_PATH,
	ADMIN_ROLE_CREATE_PATH,
	ADMIN_ROLE_DETAIL_PATH,
	REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH,
	INSPECTION_AGENCY_PATH,
	SERVICE_SCHEDULE_MANAGEMENT_PATH,
	SUBSCRIBE_PRODUCT_MANAGEMENT_PATH,
	SUBSCRIBE_PRODUCT_CREATE_PATH,
	SUBSCRIBE_PRODUCT_DETAIL_PATH,
	PRODUCT_MANAGEMENT_PATH,
	PRODUCT_CREATE_PATH,
	PRODUCT_DETAIL_PATH,
	PURCHASE_HISTORY_MANAGEMENT_PATH,
	PURCHASE_HISTORY_DETAIL_PATH,
	TERMINATION_MANAGEMENT_PATH,
	TERMINATION_DETAIL_PATH,
	INVENTORY_STATE_STANDARD_MANAGEMENT_PATH,
	CS_TEL_PATH,
	CS_TEL_CREATE_PATH,
	CS_TEL_DETAIL_PATH,
	CS_CHAT_PATH,
	CS_CHAT_DETAIL_PATH,
	CS_FAQ_PATH,
	CS_FAQ_CREATE_PATH,
	CS_FAQ_DETAIL_PATH,
	CS_APP_VERSION_MANAGEMENT,
	CS_APP_VERSION_CREATE_PATH,
	CS_APP_VERSION_DETAIL_PATH,
	CS_NOTICE_PATH,
	CS_NOTICE_CREATE_PATH,
	CS_NOTICE_DETAIL_PATH,
	TERMS_MANAGEMENT_PATH,
	RULES_MANAGEMENT_PATH,
	PARTNER_INSPECTION_AGENCY_PATH,
	MONTHLY_SALES_MANAGEMENT_PATH,
	MONTHLY_SALES_DETAIL_PATH,
	TERMS_DETAIL_PATH,
	INVENTORY_STATE_STANDARD_DETAIL_PATH,
	VEHICLE_DTG_PATH,
	VEHICLE_OBD_PATH,
	CAR_SERVICE_MANAGEMENT_PATH,
	CAR_SERVICE_DETAIL_PATH,
	CAR_SERVICE_PARTNER_MANAGEMENT_PATH,
	CAR_SERVICE_OUTSOURCING_MANAGEMENT_PATH,
	CAR_SERVICE_COMPANY_MANAGEMENT_PATH,
	CAR_SERVICE_EXCEL_DOWNLOAD_PATH,
	CAR_SERVICE_EXCEL_UPLOAD_PATH,
	CAR_SERVICE_TS_INSPECTION_RESULTS,
	CAR_SERVICE_ALL_TRANSFERS,
	VEHICLES_CURRENT_LOCATIONS_PATH,
	VEHICLES_OBD_PATH,
	VEHICLE_DTG_BY_TRIP_PATH,
	CAR_SERVICE_OUTSOURCING_DETAIL_PATH,
	CAR_SERVICE_PARTNER_DETAIL_PATH,
	VEHICLE_DTG_MAP_BY_TRIP_PATH,
	CAR_SERVICE_COMPANY_CREATE_PATH,
	TIRE_REPLACEMENT_MANAGEMENT_PATH,
	VEHICLES_STATUS_MANAGEMENT_PATH,
	VEHICLES_STATUS_DETAIL_PATH,
	VEHICLES_MANAGEMENT_PATH,
	VEHICLES_DETAIL_PATH,
	PLATES_MANAGEMENT_PATH,
	PLATES_DETAIL_PATH,
	COMPANY_MANAGEMENT_PATH,
	COMPANY_DETAIL_PATH,
	DRIVER_MANAGEMENT_PATH,
	DRIVER_DETAIL_PATH,
	CAR_SERVICE_DASHBOARD_PATH,
	VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_CANCEL_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_CANCEL_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_POLICY_MANAGEMENT_PATH,
	DEPARTMENT_MANAGEMENT_PATH,
	DEPARTMENT_DETAIL_PATH,
	VEHICLES_CREATE_PATH,
	PLATES_CREATE_PATH,
	DRIVER_CREATE_PATH,
	COMPANY_CREATE_PATH,
	CUSTOMER_CREATE_PATH,
	CAR_CARE_SUBSCRIPTION_MANAGEMENT_PATH,
	CAR_CARE_SUBSCRIPTION_DETAIL_PATH,
	CAR_CARE_SUBSCRIPTION_REQUEST_PATH,
	CAR_SERVICE_MYDASHBOARD_PATH,
	VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_CONSULTING_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH,
	VEHICLE_SUBSCRIPTION_QUOTES_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH,
	SUBPRODUCT_MANAGEMENT_PATH,
	SUBPRODUCT_CREATE_PATH,
	SUBPRODUCT_DETAIL_PATH,
} from '../../../routes/constants/urls';

export interface AsideMenuItem {
	displayTitle: string;
	roleCategory: string;
	key: string;
	labelIcon: string;
	subMenu: {
		displaySubTitle: string;
		roleName: string;
		key: string;
		includePaths: string[];
	}[];
}

export const asideMenuList: AsideMenuItem[] = [
	{
		displayTitle: '자산 관리',
		roleCategory: '자산 관리',
		key: 'asset-section',
		labelIcon: menu01Icon,
		subMenu: [
			{
				displaySubTitle: '번호판 관리',
				roleName: '번호판 관리',
				key: PLATES_MANAGEMENT_PATH,
				includePaths: [PLATES_DETAIL_PATH, PLATES_CREATE_PATH],
			},
			{
				displaySubTitle: '차량 관리',
				roleName: '차량 관리',
				key: VEHICLES_MANAGEMENT_PATH,
				includePaths: [VEHICLES_DETAIL_PATH, VEHICLES_CREATE_PATH],
			},
		],
	},
	{
		displayTitle: '오픈트럭 관리',
		roleCategory: '차량구독 관리',
		key: 'open-truck-section',
		labelIcon: menu01Icon,
		subMenu: [
			{
				displaySubTitle: '계약현황 관리',
				roleName: '현황 관리',
				key: VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH,
				includePaths: [
					VEHICLE_SUBSCRIPTION_DETAIL_PATH,
					VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH,
				],
			},
			{
				displaySubTitle: '차량구독 상담',
				roleName: '신청 관리',
				key: VEHICLE_SUBSCRIPTION_CONSULTING_MANAGEMENT_PATH,
				includePaths: [VEHICLE_SUBSCRIPTION_CONSULTING_DETAIL_PATH],
			},
			{
				displaySubTitle: '차량구독 견적',
				roleName: '신청 관리',
				key: VEHICLE_SUBSCRIPTION_QUOTES_MANAGEMENT_PATH,
				includePaths: [VEHICLE_SUBSCRIPTION_QUOTES_DETAIL_PATH],
			},

			{
				displaySubTitle: '해지 관리',
				roleName: '해지 관리',
				key: VEHICLE_SUBSCRIPTION_CANCEL_MANAGEMENT_PATH,
				includePaths: [VEHICLE_SUBSCRIPTION_CANCEL_DETAIL_PATH],
			},
			{
				displaySubTitle: '정책 관리',
				roleName: '정책 관리',
				key: VEHICLE_SUBSCRIPTION_POLICY_MANAGEMENT_PATH,
				includePaths: [],
			},
		],
	},
	{
		displayTitle: '실시간 차량관리',
		roleCategory: '차량 관리',
		key: 'car-section',
		labelIcon: menu01Icon,
		subMenu: [
			{
				displaySubTitle: '실시간 차량위치',
				roleName: '실시간 차량위치',
				key: VEHICLES_CURRENT_LOCATIONS_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '실시간 차량상태',
				roleName: '실시간 차량상태',
				key: VEHICLES_OBD_PATH,
				includePaths: [VEHICLE_OBD_PATH],
			},
			{
				displaySubTitle: '차량 운행기록',
				roleName: '차량 운행기록',
				key: VEHICLE_DTG_BY_TRIP_PATH,
				includePaths: [VEHICLE_DTG_MAP_BY_TRIP_PATH],
			},
			{
				displaySubTitle: '차량 운행기록(시간별)',
				roleName: '차량 운행기록',
				key: VEHICLE_DTG_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '차량상태 관리',
				roleName: '차량상태 관리',
				key: VEHICLES_STATUS_MANAGEMENT_PATH,
				includePaths: [VEHICLES_STATUS_DETAIL_PATH],
			},
		],
	},
	{
		displayTitle: '카케어 작업 관리',
		roleCategory: '작업 관리',
		key: 'task-section',
		labelIcon: menu02Icon,
		subMenu: [
			{
				displaySubTitle: '전체 작업 현황',
				roleName: '작업 현황',
				key: SERVICE_SCHEDULE_MANAGEMENT_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '순회점검 매니저 현황',
				roleName: '순회점검 매니저 배정',
				key: REGULAR_INSPECTION_MANAGER_ASSIGNMENT_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '타이어 매니저 현황',
				roleName: '순회점검 매니저 배정',
				key: TIRE_REPLACEMENT_MANAGEMENT_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '검사대행 매니저 현황',
				roleName: '검사대행 매니저 배정',
				key: INSPECTION_AGENCY_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '검사대행 매니저 현황(제휴)',
				roleName: '검사대행 매니저 배정(제휴)',
				key: PARTNER_INSPECTION_AGENCY_PATH,
				includePaths: [],
			},
		],
	},
	{
		displayTitle: '전국 검사대행',
		roleCategory: '검사대행',
		key: 'car-service-section',
		labelIcon: menu03Icon,
		subMenu: [
			{
				displaySubTitle: '대시보드(전체 관리자)',
				roleName: '대시보드 관리', // 오픈마일 관리자
				key: CAR_SERVICE_DASHBOARD_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '대시보드',
				roleName: '대시보드',
				key: CAR_SERVICE_MYDASHBOARD_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '통화 및 이체 관리',
				roleName: '통화 및 이체 관리',
				key: CAR_SERVICE_MANAGEMENT_PATH,
				includePaths: [CAR_SERVICE_DETAIL_PATH],
			},
			{
				displaySubTitle: '지급 요청(협력사)',
				roleName: '지급요청 협력',
				key: CAR_SERVICE_PARTNER_MANAGEMENT_PATH,
				includePaths: [CAR_SERVICE_PARTNER_DETAIL_PATH],
			},
			{
				displaySubTitle: '통화 관리(외주)',
				roleName: '통화(외주) 관리',
				key: CAR_SERVICE_OUTSOURCING_MANAGEMENT_PATH,
				includePaths: [CAR_SERVICE_OUTSOURCING_DETAIL_PATH],
			},
			{
				displaySubTitle: '업체 관리',
				roleName: '업체 관리',
				key: CAR_SERVICE_COMPANY_MANAGEMENT_PATH,
				includePaths: [CAR_SERVICE_COMPANY_CREATE_PATH],
			},
			{
				displaySubTitle: '통합 다운로드',
				roleName: '통합 다운로드',
				key: CAR_SERVICE_EXCEL_DOWNLOAD_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '통합 업로드',
				roleName: '통합 업로드',
				key: CAR_SERVICE_EXCEL_UPLOAD_PATH,
				includePaths: [],
			},
			{
				displaySubTitle: '공단검사결과 조회',
				roleName: '공단검사결과 조회',
				key: CAR_SERVICE_TS_INSPECTION_RESULTS,
				includePaths: [],
			},
			{
				displaySubTitle: '계좌이체결과 조회',
				roleName: '계좌이체결과 조회',
				key: CAR_SERVICE_ALL_TRANSFERS,
				includePaths: [],
			},
		],
	},
	{
		displayTitle: '법인 카케어',
		roleCategory: '카케어구독  관리',
		key: 'open-spanner-section',
		labelIcon: menu01Icon,
		subMenu: [
			{
				displaySubTitle: '서비스 관리',
				roleName: '서비스 관리',
				key: CAR_CARE_SUBSCRIPTION_MANAGEMENT_PATH,
				includePaths: [
					CAR_CARE_SUBSCRIPTION_DETAIL_PATH,
					CAR_CARE_SUBSCRIPTION_REQUEST_PATH,
				],
			},
		],
	},
	{
		displayTitle: '오픈스패너 상품',
		roleCategory: '상품 관리',
		key: 'product-section',
		labelIcon: menu04Icon,
		subMenu: [
			{
				displaySubTitle: '구독상품 관리',
				roleName: '구독상품 관리',
				key: SUBSCRIBE_PRODUCT_MANAGEMENT_PATH,
				includePaths: [
					SUBSCRIBE_PRODUCT_CREATE_PATH,
					SUBSCRIBE_PRODUCT_DETAIL_PATH,
				],
			},
			{
				displaySubTitle: '개별상품 관리',
				roleName: '개별상품 관리',
				key: PRODUCT_MANAGEMENT_PATH,
				includePaths: [PRODUCT_CREATE_PATH, PRODUCT_DETAIL_PATH],
			},
			{
				displaySubTitle: '하위상품 관리',
				roleName: '개별상품 관리',
				key: SUBPRODUCT_MANAGEMENT_PATH,
				includePaths: [SUBPRODUCT_CREATE_PATH, SUBPRODUCT_DETAIL_PATH],
			},
		],
	},
	{
		displayTitle: '오픈스패너 관리',
		roleCategory: '주문 관리',
		key: 'order-section',
		labelIcon: menu05Icon,
		subMenu: [
			{
				displaySubTitle: '구독내역 관리',
				roleName: '구매내역 관리',
				key: PURCHASE_HISTORY_MANAGEMENT_PATH,
				includePaths: [PURCHASE_HISTORY_DETAIL_PATH],
			},
			{
				displaySubTitle: '해지 관리',
				roleName: '해지 관리',
				key: TERMINATION_MANAGEMENT_PATH,
				includePaths: [TERMINATION_DETAIL_PATH],
			},
			{
				displaySubTitle: '약정/해지 규정 관리',
				roleName: '약정/해지 규정 관리',
				key: RULES_MANAGEMENT_PATH,
				includePaths: [],
			},
		],
	},
	{
		displayTitle: '유저 관리',
		roleCategory: '회원 관리',
		key: 'user-section',
		labelIcon: menu06Icon,
		subMenu: [
			{
				displaySubTitle: '회원 관리',
				roleName: '개인고객',
				key: CUSTOMER_MANAGEMENT_PATH,
				includePaths: [CUSTOMER_DETAIL_PATH, CUSTOMER_CREATE_PATH],
			},
			{
				displaySubTitle: '법인 소속 기사 관리',
				roleName: '법인 관리',
				key: DRIVER_MANAGEMENT_PATH,
				includePaths: [DRIVER_DETAIL_PATH, DRIVER_CREATE_PATH],
			},
			{
				displaySubTitle: '관리자',
				roleName: '관리자',
				key: ADMIN_MANAGEMENT_PATH,
				includePaths: [ADMIN_CREATE_PATH, ADMIN_DETAIL_PATH],
			},
			{
				displaySubTitle: '관리자 권한 관리',
				roleName: '관리자 권한 관리',
				key: ADMIN_ROLE_MANAGEMENT_PATH,
				includePaths: [ADMIN_ROLE_CREATE_PATH, ADMIN_ROLE_DETAIL_PATH],
			},
			{
				displaySubTitle: '매니저',
				roleName: '매니저',
				key: MANAGER_MANAGEMENT_PATH,
				includePaths: [MANAGER_DETAIL_PATH],
			},

			{
				displaySubTitle: '법인 관리',
				roleName: '법인 관리',
				key: COMPANY_MANAGEMENT_PATH,
				includePaths: [COMPANY_DETAIL_PATH, COMPANY_CREATE_PATH],
			},
			{
				displaySubTitle: '소속 관리',
				roleName: '소속 관리',
				key: DEPARTMENT_MANAGEMENT_PATH,
				includePaths: [DEPARTMENT_DETAIL_PATH],
			},
		],
	},
	{
		displayTitle: '재고 관리',
		roleCategory: '재고 관리',
		key: 'inventory-section',
		labelIcon: menu07Icon,
		subMenu: [
			{
				displaySubTitle: '재고현황 (입/출고 관리)',
				roleName: '재고현황 (입/출고 관리)',
				key: INVENTORY_STATE_STANDARD_MANAGEMENT_PATH,
				includePaths: [INVENTORY_STATE_STANDARD_DETAIL_PATH],
			},
		],
	},
	{
		displayTitle: '정산 관리',
		roleCategory: '정산 관리',
		key: 'sales-section',
		labelIcon: menu08Icon,
		subMenu: [
			{
				displaySubTitle: '매출 관리',
				roleName: '매출 관리',
				key: MONTHLY_SALES_MANAGEMENT_PATH,
				includePaths: [MONTHLY_SALES_DETAIL_PATH],
			},
		],
	},
	{
		displayTitle: 'CS 관리',
		roleCategory: 'CS 관리',
		key: 'cs-section',
		labelIcon: menu09Icon,
		subMenu: [
			{
				displaySubTitle: '전화문의 현황',
				roleName: '전화문의 현황',
				key: CS_TEL_PATH,
				includePaths: [CS_TEL_CREATE_PATH, CS_TEL_DETAIL_PATH],
			},
			{
				displaySubTitle: '1:1 문의 관리',
				roleName: '1:1 문의 관리',
				key: CS_CHAT_PATH,
				includePaths: [CS_CHAT_DETAIL_PATH],
			},
			{
				displaySubTitle: 'FAQ 관리',
				roleName: 'FAQ 관리',
				key: CS_FAQ_PATH,
				includePaths: [CS_FAQ_CREATE_PATH, CS_FAQ_DETAIL_PATH],
			},
			{
				displaySubTitle: '앱 버전 관리',
				roleName: '앱 버전 관리',
				key: CS_APP_VERSION_MANAGEMENT,
				includePaths: [CS_APP_VERSION_CREATE_PATH, CS_APP_VERSION_DETAIL_PATH],
			},
			{
				displaySubTitle: '공지사항 관리 (매니저)',
				roleName: '공지사항 관리 (매니저)',
				key: CS_NOTICE_PATH,
				includePaths: [CS_NOTICE_CREATE_PATH, CS_NOTICE_DETAIL_PATH],
			},
		],
	},
	{
		displayTitle: '약관 관리',
		roleCategory: '약관 관리',
		key: 'terms-section',
		labelIcon: menu10Icon,
		subMenu: [
			{
				displaySubTitle: '약관 관리',
				roleName: '약관 관리',
				key: TERMS_MANAGEMENT_PATH,
				includePaths: [TERMS_DETAIL_PATH],
			},
		],
	},
];

export const carServiceMenuList: AsideMenuItem[] = [];
