import React, { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Radio, RadioChangeEvent, Row, UploadFile } from 'antd';
import { FormikHelpers, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import GridDetailTemplate from '../GridDetailTemplate';
import { SubproductCreateUpdateDto } from '../../../interface/product';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import InputForm from '../../molecules/InputForm';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import Input from '../../atoms/Input';
import TextArea from '../../atoms/TextArea';
import InputAlert from '../../atoms/InputAlert';
import ContentBoxItem from '../../atoms/ContentBoxItem';
import ImageUpload from '../../atoms/ImageUpload';
import { getPriceAsNumber, numberWithCommas } from '../../../utils/data-format';
import {
	useCreateSubproductMutation,
	useDeleteSubproductMutation,
	useUpdateSubproductMutation,
} from '../../../store/apis/subproduct';
import { useBigImagesUploadMutation } from '../../../store/apis/upload';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { ToastContext } from '../../../contexts/Toast';
import { CustomErrorType } from '../../../store/apis/@types';
import { SUBPRODUCT_MANAGEMENT_PATH } from '../../../routes/constants/urls';
import Alert from '../../atoms/Alert';
import useCheckRole from '../../../hooks/useCheckRole';

interface SubproductTemplateProps {
	initialValues: SubproductCreateUpdateDto;
}

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
	DELETE,
}

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ContentBoxItemCustom = styled(InputForm)`
	margin-left: 0;

	.label-content-bundle-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;

const ContentBoxItemTaxCustom = styled(ContentBoxItem)`
	flex-direction: row;
	align-items: center;
	margin: 0;
	width: 100%;

	.price-tax-container {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.tax-price {
		width: 100%;
	}
`;

const backUrl = SUBPRODUCT_MANAGEMENT_PATH;

const SubproductTemplate = ({ initialValues }: SubproductTemplateProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const { id } = useParams();

	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);

	const [createSubproduct] = useCreateSubproductMutation();
	const [updateSubproduct] = useUpdateSubproductMutation();
	const [deleteSubproduct] = useDeleteSubproductMutation();
	const [imageUpload] = useBigImagesUploadMutation();

	const isUpdateSubproduct = useCheckRole({
		roleCategory: '상품관리',
		roleName: '개별상품관리',
		roleType: 'isUpdate',
	});

	const isDeleteSubproduct = useCheckRole({
		roleCategory: '상품관리',
		roleName: '개별상품관리',
		roleType: 'isDelete',
	});

	const [uploadImageFileList, setUploadImageFileList] = useState<
		UploadFile<any>[] | undefined
	>(
		initialValues.productImage
			? [
					{
						uid: '-1',
						name: 'image.png',
						status: 'done',
						url: initialValues.productImage,
					},
			  ]
			: undefined,
	);

	const [uploadIconFileList, setUploadIconFileList] = useState<
		UploadFile<any>[] | undefined
	>(
		initialValues.productIcon
			? [
					{
						uid: '-1',
						name: 'image.png',
						status: 'done',
						url: initialValues.productIcon,
					},
			  ]
			: undefined,
	);

	const handleAlertUpdateClick = () => {
		setAlertState(AlertState.UPDATE);
	};

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);

		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (!values.productName) {
			errors.productName = '상품명을 입력해 주세요.';
		}

		if (!values.productDescription) {
			errors.productDescription = '상품 설명을 입력해 주세요.';
		}

		if (values.productImage === undefined && !uploadImageFileList?.length) {
			errors.productImage = '상품 이미지를 등록해 주세요.';
		}

		if (values.productIcon === undefined && !uploadIconFileList?.length) {
			errors.productIcon = '상품 아이콘을 등록해 주세요.';
		}

		if (values.productPrice === 0) {
			errors.productPrice = '판매가격을 입력해 주세요.';
		}

		return errors;
	};

	const handleUploadImage = async (
		formikHelpers: FormikHelpers<typeof initialValues>,
		defaultValues: { productImage: string; productIcon: string },
	) => {
		const resultUrls: { productImage: string; productIcon: string } = {
			productImage: defaultValues.productImage,
			productIcon: defaultValues.productIcon,
		};

		if (uploadImageFileList?.length) {
			const targetFile = uploadImageFileList[0];

			if (targetFile && !targetFile.url) {
				const res = await imageUpload({
					file: targetFile.originFileObj,
					folder: '/product/image',
				});

				if ('error' in res) {
					const errorMessage =
						(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message;

					toast('error', errorMessage);

					formikHelpers.setErrors({ productImage: errorMessage });

					return null;
				}

				resultUrls.productImage = res.data.rows[0] as string;
			}
		}

		if (uploadIconFileList?.length) {
			const targetFile = uploadIconFileList[0];

			if (targetFile && !targetFile.url) {
				const res = await imageUpload({
					file: targetFile.originFileObj,
					folder: '/product/icon',
				});

				if ('error' in res) {
					const errorMessage =
						(res.error as CustomErrorType).data.translate ||
						(res.error as CustomErrorType).data.message;

					toast('error', errorMessage);

					formikHelpers.setErrors({ productIcon: errorMessage });

					return null;
				}

				resultUrls.productIcon = res.data.rows[0] as string;
			}
		}

		return resultUrls;
	};

	const handleCreateSubmit = async (values: typeof initialValues) => {
		const result = await createSubproduct({
			...values,
		});
		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '개별 상품 등록이 완료되었습니다.');
		}
	};

	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const result = await updateSubproduct({
			subproductId: Number(id),
			body: values,
		});

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '하위 상품 수정이 완료되었습니다.');
		}
	};

	const {
		validateForm,
		values: formikValues,
		errors: formikErrors,
		handleChange,
		setFieldValue,
		handleSubmit: formikSubmit,
	} = useFormik({
		initialValues: { ...initialValues },
		enableReinitialize: true,
		onSubmit: async (values, formikHelpers) => {
			// handle form submission
			dispatch(fullLoadingOn());

			const uploadResult = await handleUploadImage(formikHelpers, {
				productImage: values.productImage as string,
				productIcon: values.productIcon as string,
			});

			if (uploadResult) {
				if (!id) {
					setAlertState(AlertState.CREATE);
					await handleCreateSubmit({
						...values,
						...uploadResult,
					});
				} else {
					setAlertState(AlertState.UPDATE);
					await handleUpdateSubmit({
						...values,
						...uploadResult,
					});
				}
			}

			dispatch(fullLoadingOff());
			navigate(backUrl);
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertOpenClick = () => {
		setAlertState(AlertState.CREATE);
	};

	const handleCancelClick = () => {
		navigate(-1);
	};

	const handleCreateClick = async () => {
		const errors = await validateForm();

		if (Object.keys(errors).length) {
			return;
		}

		handleAlertOpenClick();
	};

	const handleDeleteClick = () => {
		setAlertState(AlertState.DELETE);
	};

	const handleUpdateClick = async () => {
		const errors = await validateForm();

		if (Object.keys(errors).length) {
			return;
		}

		handleAlertUpdateClick();
	};

	const handleDelete = useCallback(async () => {
		if (!id) return;

		handleAlertCloseClick();
		const res = await deleteSubproduct(id);

		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '하위 상품 삭제가 완료되었습니다.');
			navigate(backUrl, { replace: true });
		}
	}, [deleteSubproduct, id, navigate, toast]);

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.CREATE:
				return (
					<Alert
						title="하위 상품 등록"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="등록하기"
						onConfirmButtonClick={() => formikSubmit()}
						isVisible={alertState === AlertState.CREATE}
					>
						개별상품을 등록하시겠습니까?
					</Alert>
				);

			case AlertState.UPDATE:
				return (
					<Alert
						title="하위 상품 저장"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="저장하기"
						onConfirmButtonClick={() => formikSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						개별상품을 저장하시겠습니까?
					</Alert>
				);

			case AlertState.DELETE:
				return (
					<Alert
						title="하위 상품 삭제"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="삭제하기"
						onConfirmButtonClick={handleDelete}
						isVisible={alertState === AlertState.DELETE}
					>
						개별상품을 삭제하시겠습니까?
					</Alert>
				);

			default:
				return null;
		}
	}, [alertState, formikSubmit, handleDelete]);

	return (
		<GridDetailTemplate
			detailTitle="하위상품 등록"
			onBack={handleCancelClick}
			rightAccessory={
				<ButtonContainer>
					<Button
						$buttonType={ButtonTypeType.OPAQUE}
						onClick={handleCancelClick}
						size="small"
					>
						취소
					</Button>
					{!id ? (
						<Button onClick={handleCreateClick} size="small">
							등록하기
						</Button>
					) : (
						<>
							<Button
								$buttonType={ButtonTypeType.GHOST_DANGER}
								onClick={handleDeleteClick}
								size="small"
							>
								{isDeleteSubproduct ? '삭제하기' : '삭제 권한없음'}
							</Button>
							<Button onClick={handleUpdateClick} size="small">
								{isUpdateSubproduct ? '저장하기' : '수정 권한없음'}
							</Button>
						</>
					)}
				</ButtonContainer>
			}
		>
			<Row gutter={[12, 12]}>
				<Col md={12}>
					<ContentBoxWithHeader
						title="상품 상세"
						borderRadius="10px"
						className="inner-content"
					>
						<InnerContent>
							<InputForm>
								<Label name="productName" essential>
									<span className="label">
										<Typo $typoType="label2">상품명</Typo>
									</span>
								</Label>
								<Input
									name="productName"
									placeholder="상품명을 입력해 주세요"
									type="text"
									value={formikValues.productName ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('productName', '')}
								/>
								{formikErrors.productName && (
									<InputAlert $inputStatus="error">
										{formikErrors.productName}
									</InputAlert>
								)}
							</InputForm>
							<InputForm>
								<Label name="productDescription" essential>
									<span className="label">
										<Typo $typoType="label2">상품 설명</Typo>
									</span>
								</Label>
								<TextArea
									name="productDescription"
									placeholder="상품 설명을 입력해 주세요"
									value={formikValues.productDescription ?? ''}
									onChange={handleChange}
									onReset={() => setFieldValue('productDescription', '')}
								/>
								{formikErrors.productDescription && (
									<InputAlert $inputStatus="error">
										{formikErrors.productDescription}
									</InputAlert>
								)}
							</InputForm>
							<ContentBoxItemCustom>
								<div className="label-content-bundle-container">
									<Label name="productImage" essential>
										<span>
											<Typo $typoType="label2">상품 이미지</Typo>
										</span>
									</Label>
									<ImageUpload
										size="large"
										onChange={({ fileList }) => {
											setUploadImageFileList(fileList);

											if (uploadImageFileList?.length) {
												setFieldValue(
													'productImage',
													fileList[0]?.thumbUrl as string,
												);
											} else {
												setFieldValue('productImage', undefined);
											}
										}}
										fileList={uploadImageFileList}
									/>
									{formikErrors.productImage &&
										!uploadImageFileList?.length && (
											<InputAlert $inputStatus="error">
												{formikErrors.productImage}
											</InputAlert>
										)}
								</div>
							</ContentBoxItemCustom>
							<ContentBoxItemCustom>
								<div className="label-content-bundle-container">
									<Label name="productIcon" essential>
										<span>
											<Typo $typoType="label2">상품 아이콘</Typo>
										</span>
									</Label>
									<ImageUpload
										size="large"
										onChange={({ fileList }) => {
											setUploadIconFileList(fileList);
											if (fileList.length > 0) {
												setFieldValue(
													'productIcon',
													fileList[0]?.thumbUrl as string,
												);
											} else {
												setFieldValue('productIcon', undefined);
											}
										}}
										fileList={uploadIconFileList}
									/>
									{formikErrors.productIcon && !uploadIconFileList?.length && (
										<InputAlert $inputStatus="error">
											{formikErrors.productIcon}
										</InputAlert>
									)}
								</div>
							</ContentBoxItemCustom>
							<ContentBoxItemTaxCustom>
								<InputForm>
									<Label name="salePrice" essential>
										<span className="label">
											<Typo $typoType="label2">판매가 (KRW)</Typo>
										</span>
									</Label>
									<div className="price-tax-container">
										<Input
											name="productPrice"
											placeholder="숫자 입력"
											type="text"
											$inputSize="small"
											$inputRadius="small"
											value={numberWithCommas(
												(formikValues.productPrice ?? 0) +
													(formikValues.productTax ?? 0),
											)}
											onChange={(e) => {
												setFieldValue(
													'productPrice',
													getPriceAsNumber(e.target.value) -
														getPriceAsNumber(e.target.value, true),
												);
												setFieldValue(
													'productTax',
													getPriceAsNumber(e.target.value, true),
												);
											}}
											maxLength={11}
											onReset={() => setFieldValue('productPrice', '')}
											unitText="원"
										/>
										<div className="tax-price">
											<Typo $typoType="label2" color="gray_6">
												공급가액{' '}
												{numberWithCommas(formikValues.productPrice ?? 0)}원 /
												부가세(10%){' '}
												{numberWithCommas(formikValues.productTax ?? 0)}원
											</Typo>
										</div>
									</div>
									{formikErrors.productPrice && (
										<InputAlert $inputStatus="error">
											{formikErrors.productPrice}
										</InputAlert>
									)}
								</InputForm>
							</ContentBoxItemTaxCustom>
							<InputForm>
								<Label name="productViewable" essential>
									<span className="label">
										<Typo $typoType="label2">판매 상태</Typo>
									</span>
								</Label>
								<Radio.Group
									onChange={(e: RadioChangeEvent) => {
										setFieldValue('productViewable', e.target.value);
									}}
									value={formikValues.productViewable}
									defaultValue="true"
								>
									<Radio value>
										<Typo $typoType="label1">판매중</Typo>
									</Radio>
									<Radio value={false}>
										<Typo $typoType="label1">숨김</Typo>
									</Radio>
								</Radio.Group>
							</InputForm>
						</InnerContent>
					</ContentBoxWithHeader>
				</Col>
			</Row>
			{renderAlert()}
		</GridDetailTemplate>
	);
};

export default SubproductTemplate;
