import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useGetCompaniesQuery } from '../../../store/apis/company';
import useCheckRole from '../../../hooks/useCheckRole';
import Button from '../../atoms/Button';
import GridContentTemplate from '../GridContentTemplate';
import { createDetailAction } from '../../../utils/row-data-util';
import userData from '../../../pages/UserManagement/CompanyManagement/Companies/gridData/gridData';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import {
	COMPANY_CREATE_PATH,
	COMPANY_DETAIL_PATH,
} from '../../../routes/constants/urls';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

function CompaniesListTemplate() {
	const navigate = useNavigate();
	const { formInitialValues, colsList, forms } = userData;
	const isCreateCompany = useCheckRole({
		roleCategory: '회원관리',
		roleName: '법인관리',
		roleType: 'isCreate',
	});

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetCompaniesQuery,
		detailPath: COMPANY_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const handleCreateClick = () => {
		navigate(COMPANY_CREATE_PATH);
	};

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="법인 관리"
			gridTitle="법인 목록"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'id'),
			}}
			rightAccessory={
				isCreateCompany && (
					<ButtonContainer>
						<Button onClick={handleCreateClick} size="small">
							법인 등록
						</Button>
					</ButtonContainer>
				)
			}
		/>
	);
}

export default CompaniesListTemplate;
