import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	pdf,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Font,
	Image,
	BlobProvider,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import {
	pdfjs,
	Document as ReactPdfDocument,
	Page as ReactPdfPage,
} from 'react-pdf';
import {
	MakeVehicleSubscriptionQuoteDto,
	VehicleSubscriptionQuoteDto,
} from '../../../../interface/vehicleSubscriptionsQuotes';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import OpenMilelogoImage from '../../../../assets/images/logo.png';
import { ToastContext } from '../../../../contexts/Toast';
import { numberWithCommas } from '../../../../utils/data-format';
import { useGetExternalVehicleSubscriptionVehicleQuotesQuery } from '../../../../store/apis/vehicleSubscriptionQuotes';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.mjs',
	import.meta.url,
).toString();

Font.register({
	family: 'Pretendard',
	src: 'https://cdn.jsdelivr.net/npm/pretendard@1.3.9/dist/public/variable/PretendardVariable.ttf',
});

type VehicleSubscriptionQuote = Partial<
	MakeVehicleSubscriptionQuoteDto & VehicleSubscriptionQuoteDto
>;

const PdfDocument: React.FC<{ quotes: VehicleSubscriptionQuote[] }> = ({
	quotes,
}) => {
	const styles = StyleSheet.create({
		page: {
			padding: 24,
			fontFamily: 'Pretendard',
			lineHeight: 1.6,
		},
		header: {
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			marginBottom: 10,
		},
		image: {
			marginBottom: 16,
			width: 100,
			alignSelf: 'flex-start',
		},
		subTitle: {
			fontSize: 12,
			fontWeight: 'bold',
		},
		section: {
			marginBottom: 16,
			padding: 10,
		},
		label: {
			fontWeight: 'bold',
			borderBottom: '1px solid black',
		},

		grid: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			width: '100%',
		},
		gridItem: {
			width: '50%',
			flexDirection: 'row',
		},
		tableCellLabel: {
			fontSize: 12,
			fontWeight: 'bold',
			backgroundColor: '#f0f0f0',
			width: '20%',
			padding: 4,
		},
		tableCellValue: {
			fontSize: 12,
			width: '80%',
			padding: 4,
		},

		tableRow: {
			flexDirection: 'row',
		},

		tableCell: {
			margin: 4,
			padding: 4,
			borderRight: '1px solid #ccc',
			fontSize: 12,
		},

		footer: {
			marginTop: 24,
			textAlign: 'center',
			alignItems: 'flex-end',
			fontSize: 12,
			image: {
				width: 100,
				display: 'block',
				margin: 'auto',
			},
		},

		footerText: {
			fontSize: 12,
			color: '#888',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},

		footerImage: {
			width: 100,
		},
	});

	return (
		<Document>
			{quotes.map((quote, index) => (
				<Page size="A4" style={styles.page} key={index}>
					<View style={styles.header}>
						<Image style={styles.image} src={OpenMilelogoImage} />
						<Text style={styles.subTitle}>
							견적서 - {quote.subscriptionVehicleQuoteNo || '정보 없음'}
						</Text>
					</View>

					<View style={styles.section}>
						<Text style={styles.label}>고객 정보</Text>
						<View style={styles.grid}>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>고객 유형</Text>
								<Text style={styles.tableCellValue}>
									{quote.customerType || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>고객명</Text>
								<Text style={styles.tableCellValue}>
									{quote.customerName || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>연락처</Text>
								<Text style={styles.tableCellValue}>
									{quote.customerPhone || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>이메일</Text>
								<Text style={styles.tableCellValue}>
									{quote.customerEmail || '정보 없음'}
								</Text>
							</View>
						</View>
					</View>

					{/* 구독 정보 */}
					<View style={styles.section}>
						<Text style={styles.label}>구독 정보</Text>
						<View style={styles.grid}>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>구독시작일</Text>
								<Text style={styles.tableCellValue}>
									{dayjs(quote.subscriptionStartedAt).format('YYYY-MM-DD(dd)')}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>구독종료일</Text>
								<Text style={styles.tableCellValue}>
									{dayjs(quote.subscriptionEndedAt).format('YYYY-MM-DD(dd)')}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>구독기간</Text>
								<Text style={styles.tableCellValue}>
									{quote.subscriptionPeriod}일
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>만기시</Text>
								<Text style={styles.tableCellValue}>
									{quote.endActionType || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>월 구독료</Text>
								<Text style={styles.tableCellValue}>
									{`${numberWithCommas(quote.subscriptionPrice || 0)}원`}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>차량가</Text>
								<Text style={styles.tableCellValue}>
									{numberWithCommas(quote.vehiclePrice || 0)}원
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>선납금</Text>
								<Text style={styles.tableCellValue}>
									{numberWithCommas(quote.prePaymentPrice || 0)}원
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>인수잔가</Text>
								<Text style={styles.tableCellValue}>
									{numberWithCommas(quote.acquisitionPrice || 0)}원
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>AG수수료</Text>
								<Text style={styles.tableCellValue}>
									{`${numberWithCommas(quote.agCommissionPct || 0)}%`}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>번호판 신청여부</Text>
								<Text style={styles.tableCellValue}>
									{quote.requestVehiclePlate ? '신청함' : '신청안함'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>결제방법</Text>
								<Text style={styles.tableCellValue}>
									{quote.paymentMethod || '정보 없음'}
								</Text>
							</View>
						</View>
					</View>

					{/* 차량 정보 */}
					<View style={styles.section}>
						<Text style={styles.label}>차량 정보</Text>
						<View style={styles.grid}>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>차량 번호</Text>
								<Text style={styles.tableCellValue}>
									{quote.vehicle?.vehicleNumber || '정보 없음'}
								</Text>
							</View>

							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>차대번호</Text>
								<Text style={styles.tableCellValue}>
									{quote.vehicle?.vin || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>차종</Text>
								<Text style={styles.tableCellValue}>
									{quote.vehicle?.vehicleCategory || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>적재함 종류</Text>
								<Text style={styles.tableCellValue}>
									{quote.vehicle?.carLoadingBoxType || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>차명</Text>
								<Text style={styles.tableCellValue}>
									{quote.vehicle?.vehicleName || '정보 없음'}
								</Text>
							</View>
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>적재 용량</Text>
								<Text style={styles.tableCellValue}>
									{quote.vehicle?.loadingCapacity || '정보 없음'}
								</Text>
							</View>
						</View>
					</View>

					<View style={styles.section}>
						<Text style={styles.label}>오픈스패너 정보</Text>
						<View style={styles.grid}>
							{/* Vehicle Number */}
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>상품명</Text>
								<Text style={styles.tableCellValue}>
									{quote.subscriptionProduct?.subscriptionProductName ||
										'정보 없음'}
								</Text>
							</View>
							{/* Memo */}
							<View style={styles.gridItem}>
								<Text style={styles.tableCellLabel}>가격</Text>
								<Text style={styles.tableCellValue}>
									{`${numberWithCommas(
										quote.subscriptionProduct?.monthlyPrice ?? 0,
									)}원` || '정보 없음'}
								</Text>
							</View>
						</View>
					</View>

					{/* 하단 섹션 */}
					{index === quotes.length - 1 && (
						<View style={styles.footerText}>
							<Text>이 문서는 고객님의 요청에 따라 생성되었습니다.</Text>
						</View>
					)}

					{/* 날짜 */}
					<View style={styles.footer}>
						<Text>
							견적서 발송시간 :{' '}
							{dayjs(quote.quoteSentAt?.toString()).format('YYYY-MM-DD HH:mm')}
						</Text>
					</View>
				</Page>
			))}
		</Document>
	);
};

const PublicPdfViwer = () => {
	const toast = useContext(ToastContext);
	const [pdfUrl, setPdfUrl] = useState<string>('');
	const [numberPages, setNumberPages] = useState<number>(0);
	const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const quoteNo = searchParams.get('quoteNo');
	const token = searchParams.get('token');

	const { isLoading, data } =
		useGetExternalVehicleSubscriptionVehicleQuotesQuery(
			{
				quoteNo: quoteNo as string,
				token: token as string,
			},
			{ skip: !quoteNo || !token },
		);

	const vehicleSubscriptionQuotes = React.useMemo(
		() => data?.rows || [],
		[data],
	);

	if (!quoteNo || !token) {
		toast('error', '견적서 정보가 없습니다.');
		navigate('/');
	}

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const generatePdf = useCallback(async () => {
		if (vehicleSubscriptionQuotes.length === 0) {
			return;
		}

		try {
			const pdfBlob = await pdf(
				<PdfDocument
					quotes={vehicleSubscriptionQuotes as VehicleSubscriptionQuote[]}
				/>,
			).toBlob();
			const blobPdfUrl = URL.createObjectURL(pdfBlob);
			setPdfUrl(blobPdfUrl);
		} catch (error) {
			toast('error', '견적서를 생성하는 중 오류가 발생했습니다.');
		}
	}, [toast, vehicleSubscriptionQuotes]);

	useEffect(() => {
		generatePdf();
	}, [generatePdf]);

	return (
		<BlobProvider
			document={
				<PdfDocument
					quotes={vehicleSubscriptionQuotes as VehicleSubscriptionQuote[]}
				/>
			}
		>
			{({ blob }) => {
				return isLoading ? (
					<div style={{ textAlign: 'center', padding: '16px' }}>
						PDF 생성중입니다...
					</div>
				) : (
					<div>
						{isMobile ? (
							<div style={{ textAlign: 'center', paddingTop: '12px' }}>
								<a href={pdfUrl} download="오픈트럭 견적서.pdf">
									PDF 다운로드
								</a>
								<ReactPdfDocument
									file={blob}
									onLoadSuccess={(pdfProxy) =>
										setNumberPages(pdfProxy.numPages)
									}
									renderMode="canvas"
								>
									{Array.from(new Array(numberPages), (_, index) => (
										<ReactPdfPage
											key={`page_${index + 1}`}
											pageNumber={index + 1}
											width={window.innerWidth}
										/>
									))}
								</ReactPdfDocument>
							</div>
						) : (
							<div>
								<iframe
									title="pdf-viewer"
									src={pdfUrl}
									style={{ width: '100%', height: '100vh', border: 'none' }}
								/>
							</div>
						)}
					</div>
				);
			}}
		</BlobProvider>
	);
};

export default PublicPdfViwer;
