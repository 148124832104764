import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { LabelValue } from '../../../../utils/const';
import { numberWithCommas } from '../../../../utils/data-format';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import { FilterElementType } from '../../../../utils/filter-util';

interface userDataProps {
	ownerCompanyId: number;
	departmentsOption: Array<{ label: string; value: number }>;
	companiesOption: LabelValue[];
	isSuper: boolean;
}

// @ts-ignore
const userData: ({
	ownerCompanyId,
	departmentsOption,
	companiesOption,
	isSuper,
}: userDataProps) => GridDataModel = ({
	ownerCompanyId,
	departmentsOption,
	companiesOption,
	isSuper,
}) => {
	return {
		formInitialValues: isSuper ? {} : { companyId: String(ownerCompanyId) },
		colsList: [
			{
				tableInfo: {
					field: 'createdAt',
					headerName: '등록일자',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
					},
				},
			},
			{
				tableInfo: {
					field: 'vin',
					headerName: '차대번호',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehicleNumber',
					headerName: '차량번호',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehiclePlate.vehiclePlateType',
					headerName: '영업/일반',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value || '임의번호';
					},
				},
			},
			{
				tableInfo: {
					field: 'vehicleCategory',
					headerName: '차군',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'ownerCompanyName',
					headerName: '소유자',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					valueGetter: ({ data }: ICellRendererParams) => {
						return data.ownerCompany?.name ?? '-';
					},
					cellRenderer: ({ data }: ICellRendererParams) => {
						return data.ownerCompany?.name ?? '-';
					},
				},
			},
			{
				tableInfo: {
					field: 'department',
					headerName: '소속',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					valueGetter: ({ data }: ICellRendererParams) => {
						return data.department?.departmentName ?? '-';
					},
					cellRenderer: ({ data }: ICellRendererParams) => {
						return data.department?.departmentName ?? '-';
					},
				},
			},
			{
				tableInfo: {
					field: 'manufacturingCompany',
					headerName: '제조사',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'vehicleName',
					headerName: '차종명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'factoryPrice',
					headerName: '신차가',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return `${numberWithCommas(value)}원`;
					},
				},
			},

			{
				tableInfo: {
					field: 'transmission',
					headerName: '미션',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'fuelType',
					headerName: '유종',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'color',
					headerName: '색상',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'loadingCapacity',
					headerName: '적재량',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return `${value}톤`;
					},
				},
			},
			{
				tableInfo: {
					field: 'carLoadingBoxType',
					headerName: '탑 형식',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'registrationDate',
					headerName: '차량등록일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'vehicleYear',
					headerName: '연식',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'legalInspectionDate',
					headerName: '최종검사일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'legalInspectionEndDate',
					headerName: '검사종료일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'lastInspectionDate',
					headerName: '마지막 점검일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'insurancePremiums',
					headerName: '보험금',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return `${numberWithCommas(value)}원`;
					},
				},
			},
			{
				tableInfo: {
					field: 'insuranceStartDate',
					headerName: '보험시작일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'insuranceEndDate',
					headerName: '보험종료일',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'insurer',
					headerName: '보험사',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'insuranceLossPct',
					headerName: '손해율',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return `${value || ''}%`;
					},
				},
			},
			{
				tableInfo: {
					field: 'isDtgInstalled',
					headerName: 'DTG 장착여부',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value ? '장착' : '미장착';
					},
				},
			},
			{
				tableInfo: {
					field: 'driver.driverName',
					headerName: '배정기사',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'driver.customer.customerName',
					headerName: '사용회원',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'status',
					headerName: '사용여부',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;

						return value === 'ACTIVE' ? '사용' : '미사용';
					},
				},
			},
			{
				tableInfo: {
					field: 'detail',
					headerName: '관리',
				},
				options: {
					pinned: 'right',
					width: ColSize.CW_SMALL,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
		],
		forms: isSuper
			? [
					{
						key: 'ownerCompanyId',
						label: '소유자',
						type: FilterElementType.SELECT,
						options: [{ label: '전체', value: '' }, ...companiesOption],
					},
					{
						key: 'departmentId',
						label: '부서',
						type: FilterElementType.SELECT,
						options: [{ label: '전체', value: '' }, ...departmentsOption],
					},
					{
						key: 'vehicleNumber',
						type: FilterElementType.INPUT,
						label: '차량번호',
					},
					{
						key: 'vin',
						type: FilterElementType.INPUT,
						label: '차대번호',
					},
					{
						key: 'status',
						type: FilterElementType.SELECT,
						label: '사용유무',
						options: [
							{ label: '전체', value: '' },
							{ label: '사용', value: 'ACTIVE' },
							{ label: '미사용', value: 'INACTIVE' },
						],
					},
			  ]
			: [
					{
						key: 'vehicleNumber',
						type: FilterElementType.INPUT,
						label: '차량번호',
					},
					{
						key: 'vin',
						type: FilterElementType.INPUT,
						label: '차대번호',
					},
					{
						key: 'status',
						type: FilterElementType.SELECT,
						label: '사용유무',
						options: [
							{ label: '전체', value: '' },
							{ label: '사용', value: 'ACTIVE' },
							{ label: '미사용', value: 'INACTIVE' },
						],
					},
			  ],
	};
};

export default userData;
