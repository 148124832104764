import { AxiosError } from 'axios';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContext } from '../../../contexts/Toast';
import useTimer from '../../../hooks/useTimer';
import { LOGIN_PATH } from '../../../routes/constants/urls';
import { CustomErrorType } from '../../../store/apis/@types';
import getAdminDuplicate from '../../../store/apis/adminDuplicate';
import { useAuthTokenMutation } from '../../../store/apis/authToken';
import { useAuthTokenVerifyMutation } from '../../../store/apis/authTokensVerify';
import { useResetPasswordMutation } from '../../../store/apis/passwordReset';
import { ButtonTypeType } from '../../../styles/theme';
import { phoneFormat, formatPassword } from '../../../utils/data-format';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import InputAlert, { InputAlertMessage } from '../../atoms/InputAlert';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import LoginInputForm from '../../molecules/LoginInputForm';
import FormLayout from '../../organisms/LoginFormLayout';
import LoginSearchResultLayout from '../../organisms/LoginSearchResultLayout';

interface UserPasswordSearchTemplateProps {
	title: string;
}

const UserPasswordSearchTemplateContainer = styled.main`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;

	.title {
		margin-bottom: 40px;
	}

	.input-button-container {
		display: flex;
		gap: 6px;
	}

	.button-container {
		display: flex;
		align-items: flex-end;
		height: 100%;
		width: 100%;
	}

	.label {
		display: inline-block;
		margin-bottom: 6px;
	}

	.login-form-layout {
		margin-bottom: 0;
	}

	.input-alert-pending-time {
		color: ${({ theme }) => theme.common.colors.danger_5_main};
	}

	.input-button {
		white-space: nowrap;
	}

	.password-reset-notice {
		margin-left: 20px;
		margin-top: 6px;
		color: ${({ theme }) => theme.common.colors.gray_8};
		list-style: disc;
	}

	.search-result-layout-button {
		display: flex;
		flex-direction: column;
	}

	.search-result-userinfo {
		font-weight: 500;
	}

	.search-result-userid {
		color: ${({ theme }) => theme.common.colors.primary_6_main};
		font-weight: 700;
	}

	.search-result-form-layout {
		justify-content: space-between;
	}
`;

function UserPasswordSearchTemplate({
	title,
}: PropsWithChildren<UserPasswordSearchTemplateProps>) {
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const [userPhoneNumber, setUserPhoneNumber] = useState('');
	const [certificationNumber, setCertificationNumber] = useState('');
	const [isPhoneNumberPost, setIsPhoneNumberPost] = useState(false);
	const [isCertification, setIscertification] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isPasswordCheckSubmit, setIsPasswordCheckSubmit] = useState(false);

	const [userId, setUserId] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [userPasswordCheck, setUserPasswordCheck] = useState('');

	const [userAuthId, setUserAuthId] = useState('');
	const [accessToken, setAccessToken] = useState('');
	const [authToken] = useAuthTokenMutation();
	const [authTokenVerify] = useAuthTokenVerifyMutation();
	const [resetPassword] = useResetPasswordMutation();

	const {
		state: { countDown, isCountEnd },
		func: { resetCount, setIsCountEnd },
	} = useTimer({ duration: 180 });

	const getUserPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
		const autoHyphen = phoneFormat(event.target.value);
		setUserPhoneNumber(autoHyphen);
	};
	const phoneNumberHideHyphen = userPhoneNumber.replace(/-/g, '');

	const getCertificationNumber = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const numberReg = event.target.value.replace(/[^0-9]/g, '');
		setCertificationNumber(numberReg);
	};

	const getUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserId(event.target.value);
	};

	const handlePhoneNumberPostClick = async () => {
		const res = await authToken({
			type: 'message',
			key: phoneNumberHideHyphen,
			kind: '가입 인증',
		});

		if (!('error' in res)) {
			setUserAuthId(res.data.row.id);
			setIsPhoneNumberPost(true);
			setIscertification(false);
			setCertificationNumber('');
			resetCount();
		}
		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		}
	};

	const handleCertificationClick = async () => {
		const res = await authTokenVerify({
			type: 'message',
			kind: '가입 인증',
			authTokenId: userAuthId,
			token: certificationNumber,
		});
		if (!('error' in res) && res.data.row.accessToken) {
			setAccessToken(res.data.row.accessToken);
			setIscertification(true);
			setIsCountEnd(true);
		}
		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		}
	};

	const handleSubmitButton = async () => {
		try {
			await getAdminDuplicate({
				administratorApplicationId: userId,
				administratorPhone: phoneNumberHideHyphen,
			});
			setIsSubmit(true);
		} catch (e) {
			const err = e as AxiosError<{ message: string; translate: string }>;

			if (err.response)
				toast(
					'error',
					err.response?.data.translate || err.response?.data.message,
				);
		}
	};

	const handlePassWordCheckButton = async () => {
		const res = await resetPassword({
			authTokenId: userAuthId,
			accessToken,
			removable: true,
			administratorApplicationId: userId,
			newPassword: userPasswordCheck,
		});

		if (!('error' in res)) {
			setIsPasswordCheckSubmit(true);
		}
		if ('error' in res) {
			toast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		}
	};

	const goToLoginPage = () => {
		navigate(LOGIN_PATH);
	};

	const userPasswordSearchDafaultLayout = () => {
		return (
			<>
				<h1 className="title">
					<Typo $typoType="h1">{title}</Typo>
				</h1>
				<FormLayout
					formTitle={
						<h2>
							<Typo $typoType="btn1">
								비밀번호를 찾기 위해
								<br />
								아이디와 휴대폰 인증을 진행해 주세요
							</Typo>
						</h2>
					}
				>
					<LoginInputForm>
						<Label name="userId" essential>
							<span className="label">
								<Typo $typoType="label2">아이디</Typo>
							</span>
						</Label>
						<Input
							name="userId"
							placeholder="아이디를 입력해주세요"
							type="text"
							$inputSize="large"
							$inputRadius="large"
							value={userId}
							onChange={getUserId}
							$isDisabled={isCertification}
							onReset={() => setUserId('')}
						/>
					</LoginInputForm>
					<LoginInputForm>
						<Label name="userPassword" essential>
							<span className="label">
								<Typo $typoType="label2">휴대폰 번호</Typo>
							</span>
						</Label>
						<div className="input-button-container">
							<Input
								name="userPassword"
								placeholder="휴대폰 번호 입력"
								$isDisabled={isCertification}
								type="text"
								$inputSize="large"
								$inputRadius="large"
								value={userPhoneNumber}
								onChange={getUserPhoneNumber}
								maxLength={13}
								onReset={() => setUserPhoneNumber('')}
							/>
							<Button
								className="input-button"
								$buttonType={
									isPhoneNumberPost
										? ButtonTypeType.GHOST
										: ButtonTypeType.GHOST_BLACK
								}
								disabled={
									!(
										/^01([0|1|6|7|8|9]){1}[0-9]{3,4}[0-9]{4}/.test(
											userPhoneNumber.replaceAll('-', ''),
										) && userPhoneNumber.length >= 10
									)
								}
								onClick={handlePhoneNumberPostClick}
							>
								{isPhoneNumberPost ? '재전송' : '인증번호 전송'}
							</Button>
						</div>
					</LoginInputForm>

					<LoginInputForm className="login-form-layout">
						<Label name="userPasswordCheck" essential>
							<span className="label">
								<Typo $typoType="label2">인증번호</Typo>
							</span>
						</Label>
						<div className="input-button-container">
							<Input
								name="userPasswordCheck"
								placeholder="인증번호 4자리 입력"
								$isDisabled={!isPhoneNumberPost || isCertification}
								type="text"
								$inputSize="large"
								$inputRadius="large"
								$inputStatus="normal"
								value={certificationNumber}
								onChange={getCertificationNumber}
								maxLength={4}
								onReset={() => setCertificationNumber('')}
							/>
							<Button
								className="input-button"
								$buttonType={
									certificationNumber.length === 4
										? ButtonTypeType.GHOST
										: ButtonTypeType.GHOST_BLACK
								}
								disabled={
									(!(certificationNumber.length === 4) && !isCertification) ||
									isCountEnd
								}
								onClick={handleCertificationClick}
							>
								{isCertification ? '인증완료' : '인증하기'}
							</Button>
						</div>
					</LoginInputForm>
					{isPhoneNumberPost && !isCertification && (
						<InputAlert $inputStatus="pending" $justifyContent="spaceBetween">
							<p>
								<Typo $typoType="label3" color="info_5_main">
									{InputAlertMessage.pending}
								</Typo>
							</p>
							<p className="input-alert-pending-time">
								<Typo $typoType="label3" color="danger_5_main">
									{countDown.toString()}
								</Typo>
							</p>
						</InputAlert>
					)}

					<div className="button-container">
						<Button
							size="large"
							$isFull
							$textCenter
							disabled={!isCertification}
							onClick={handleSubmitButton}
						>
							완료
						</Button>
					</div>
				</FormLayout>
			</>
		);
	};

	const userPasswordCheckLayout = () => {
		const passwordReconfirm =
			formatPassword(userPasswordCheck) && userPassword === userPasswordCheck;

		const getUserPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
			setUserPassword(event?.target.value);
		};
		const getUserPasswordCheck = (
			event: React.ChangeEvent<HTMLInputElement>,
		) => {
			setUserPasswordCheck(event?.target.value);
		};
		return (
			<>
				<h1 className="title">
					<Typo $typoType="h1">{title}</Typo>
				</h1>
				<FormLayout
					formTitle={
						<h2>
							<Typo $typoType="btn1">비밀번호를 재설정 해주세요</Typo>
						</h2>
					}
				>
					<LoginInputForm>
						<Label name="userPassword" essential>
							<span className="label">
								<Typo $typoType="label2">변경 비밀번호</Typo>
							</span>
						</Label>
						<Input
							name="userPassword"
							placeholder="비밀번호를 입력해 주세요"
							type="password"
							$inputSize="large"
							$inputRadius="large"
							value={userPassword}
							onChange={getUserPassword}
							$inputStatus={
								(userPassword.length === 0 && 'normal') ||
								(formatPassword(userPassword) ? 'success' : 'error')
							}
							onReset={() => setUserPassword('')}
						/>
						{userPassword && (
							<InputAlert
								$inputStatus={
									(userPassword.length === 0 && 'normal') ||
									formatPassword(userPassword)
										? 'success'
										: 'error'
								}
							>
								<p>
									<Typo
										$typoType="label3"
										color={
											formatPassword(userPassword)
												? 'success_5_main'
												: 'danger_5_main'
										}
									>
										{formatPassword(userPassword)
											? InputAlertMessage.success
											: InputAlertMessage.error}
									</Typo>
								</p>
							</InputAlert>
						)}
					</LoginInputForm>

					<LoginInputForm>
						<Label name="userPasswordCheck" essential>
							<span className="label">
								<Typo $typoType="label2">비밀번호 재확인</Typo>
							</span>
						</Label>
						<Input
							name="userPasswordCheck"
							placeholder="비밀번호를 입력해 주세요"
							type="password"
							$inputSize="large"
							$inputRadius="large"
							value={userPasswordCheck}
							onChange={getUserPasswordCheck}
							$inputStatus={
								(userPasswordCheck.length === 0 && 'normal') ||
								(passwordReconfirm ? 'success' : 'error')
							}
							onReset={() => setUserPasswordCheck('')}
						/>
						{userPasswordCheck && (
							<InputAlert
								$inputStatus={passwordReconfirm ? 'success' : 'error'}
							>
								<p>
									<Typo
										$typoType="label3"
										color={
											passwordReconfirm ? 'success_5_main' : 'danger_5_main'
										}
									>
										{passwordReconfirm
											? InputAlertMessage.success
											: InputAlertMessage.error}
									</Typo>
								</p>
							</InputAlert>
						)}
					</LoginInputForm>

					<ul>
						<p>
							<Typo $typoType="label2">비밀번호를 만들 때는...</Typo>
						</p>
						<li className="password-reset-notice">
							<Typo $typoType="b7" color="gray_8">
								영문 대소문자, 숫자, 특수문자(@$!%*?&)를 모두 조합하여 10~20자로
								만들어주세요.
							</Typo>
						</li>
					</ul>

					<div className="button-container">
						<Button
							$isFull
							$textCenter
							disabled={!passwordReconfirm}
							onClick={handlePassWordCheckButton}
						>
							완료
						</Button>
					</div>
				</FormLayout>
			</>
		);
	};

	const userPasswordSearchResultLayout = () => {
		return (
			<>
				<h1 className="title">
					<Typo $typoType="h1">{title}</Typo>
				</h1>
				<FormLayout
					$isTitle={false}
					$status="search"
					className="search-result-form-layout"
				>
					<LoginSearchResultLayout>
						<p>
							<Typo $typoType="h4" className="search-result-userinfo">
								비밀번호 재설정이 완료되었습니다
								<br /> 재설정된 비밀번호로 로그인 해주세요
							</Typo>
						</p>
					</LoginSearchResultLayout>

					<Button $isFull $textCenter onClick={goToLoginPage}>
						홈으로
					</Button>
				</FormLayout>
			</>
		);
	};

	return (
		<UserPasswordSearchTemplateContainer>
			{isSubmit
				? !isPasswordCheckSubmit && userPasswordCheckLayout()
				: userPasswordSearchDafaultLayout()}
			{isSubmit && isPasswordCheckSubmit && userPasswordSearchResultLayout()}
		</UserPasswordSearchTemplateContainer>
	);
}

export default UserPasswordSearchTemplate;
