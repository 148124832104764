import dayjs from 'dayjs';
import { FilterElementType } from '../../../../utils/filter-util';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';

const userData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'id',
				headerName: 'No',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
			},
		},
		{
			tableInfo: {
				field: 'productName',
				headerName: '하위상품명',
			},
			options: {
				minWidth: ColSize.CW_DESCRIPTION,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'productSalesPrice',
				headerName: '판매가',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: (params: any) => {
					return `${Number(
						params.data.productPrice + params.data.productTax,
					).toLocaleString()}원`;
				},
			},
		},
		{
			tableInfo: {
				field: 'productPrice',
				headerName: '공급가',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: (params: any) => {
					return `${Number(params.data.productPrice).toLocaleString()}원`;
				},
			},
		},
		{
			tableInfo: {
				field: 'productPrice',
				headerName: '부가세',
			},
			options: {
				width: ColSize.CW_LARGE,
				cellRenderer: (params: any) => {
					return `${Number(params.data.productTax).toLocaleString()}원`;
				},
			},
		},
		{
			tableInfo: {
				field: 'productViewable',
				headerName: '상품 노출여부',
			},
			options: {
				width: ColSize.CW_MEDIUM,
				cellRenderer: ({ value }: any) => {
					return value ? '판매중' : '숨김';
				},
			},
		},
		{
			tableInfo: {
				field: 'createdAt',
				headerName: '등록일자',
			},
			options: {
				minWidth: ColSize.CW_DATE_RANGE,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;

					return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
				},
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '상세보기',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],

	forms: [
		{
			key: 'createdAt',
			label: '등록일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
	],
};

export default userData;
