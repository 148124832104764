import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import { useGetSubproductDetailQuery } from '../../../../store/apis/subproduct';
import SubproductTemplate from '../../../../components/templates/SubProductTemplate';
import { SubproductCreateUpdateDto } from '../../../../interface/product';
import { SUBPRODUCT_MANAGEMENT_PATH } from '../../../../routes/constants/urls';

const SubproductDetailPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { isLoading, isFetching, data } = useGetSubproductDetailQuery({
		subproductId: id ? Number(id) : undefined,
	});
	const subproductDetail = data?.row;

	if (!isLoading && !isFetching && subproductDetail?.deletedAt) {
		navigate(SUBPRODUCT_MANAGEMENT_PATH);
	}

	const initialValues: SubproductCreateUpdateDto = {
		productName: subproductDetail?.productName ?? '',
		productPrice: subproductDetail?.productPrice ?? 0,
		productTax: subproductDetail?.productTax ?? 0,
		productTaxType: subproductDetail?.productTaxType ?? '',
		productViewable: subproductDetail?.productViewable ?? true,
		productRemark: subproductDetail?.productRemark ?? undefined,
		productImage: subproductDetail?.productImage,
		productIcon: subproductDetail?.productIcon,
		productDescription: subproductDetail?.productDescription,
	};

	return isLoading ? (
		<GridDetailTemplate detailTitle="하위상품 등록">
			<ContentBoxWithHeader title="상품 상세" borderRadius="10px">
				<Skeleton
					active
					paragraph={{ rows: 6 }}
					style={{ borderRadius: '10px' }}
				/>
			</ContentBoxWithHeader>
		</GridDetailTemplate>
	) : (
		<SubproductTemplate initialValues={initialValues} />
	);
};

export default SubproductDetailPage;
