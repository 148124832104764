import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Alert from '../../../../components/atoms/Alert';
import Button from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input';
import InputAlert from '../../../../components/atoms/InputAlert';
import Label from '../../../../components/atoms/Label';
import Select from '../../../../components/atoms/Select';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../../../components/molecules/GridLayout';
import InputForm from '../../../../components/molecules/InputForm';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import { ToastContext } from '../../../../contexts/Toast';
import { UpdateAdministratorDto } from '../../../../interface/administrator';
import { CustomErrorType } from '../../../../store/apis/@types';
import {
	useGetAdministratorDetailQuery,
	useUpdateAdministratorMutation,
} from '../../../../store/apis/administrator';
import { useGetDepartmentsQuery } from '../../../../store/apis/department';
import { useGetRolesQuery } from '../../../../store/apis/role';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import { LabelValue } from '../../../../utils/const';
import { phoneFormat } from '../../../../utils/data-format';
import { NewTableValueType } from '../../../../utils/table-util';
import { adminInfoTableKey } from './tableData';
import { getDepartmentServiceName } from '../../../../utils/string-util';
import DepartmentModal from '../../../../components/templates/DepartmentModal';
import ContentBox from '../../../../components/atoms/ContentBox';
import carServiceDashboardGroupColumn from '../../../NationalInspectionAgency/CarServiceDashboard/modalColumn';
import { useGetCarServiceDashBoardGroupsQuery } from '../../../../store/apis/carService';
import SelectionModal from '../../../../components/organisms/SelectionModal';
import useCheckRole from '../../../../hooks/useCheckRole';
import LockStatusButton from './LockStatusButton';
import theme from '../../../../styles/theme';

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
	max-width: 830px;
	margin: 0 auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

enum AlertState {
	NONE,
	MODIFY,
}

function AdminDetailPage() {
	const { id } = useParams();
	const [selectDashboardGroupId, setSelectDashboardGroupId] = useState(false);
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);

	const [updateAdministrator] = useUpdateAdministratorMutation();
	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);
	const navigate = useNavigate();

	const isDashboardAdmin = useCheckRole({
		roleCategory: '검사대행',
		roleName: '대시보드관리',
		roleType: 'isRead',
	});

	const isSuperAdmin = useCheckRole({
		roleCategory: '회원관리',
		roleName: '관리자',
		roleType: 'isUpdate',
		includeSuper: true,
	});

	const goTo = (route: number) => {
		navigate(route);
	};

	const { data: adminInfo, isLoading } = useGetAdministratorDetailQuery({ id });
	const { data: roles } = useGetRolesQuery({ pageSize: 500 });
	const { data: departmentsList } = useGetDepartmentsQuery({
		service: adminInfo?.row.department?.service ?? 'BOS_WEB',
	});

	const departmentOptionList: LabelValue[] = departmentsList
		? departmentsList.rows.map((item) => ({
				label: item.departmentName,
				value: item.id,
		  }))
		: [];

	const roleOptionList: LabelValue[] = roles
		? roles.rows.map((item) => ({
				label: item.roleName,
				value: item.id,
		  }))
		: [];

	const handleSubmit = async (values: UpdateAdministratorDto) => {
		const res = await updateAdministrator({ id: Number(id), body: values });

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			setToast('info', '관리자 저장이 완료되었습니다.');
		}
	};

	const handleValidate = (values: UpdateAdministratorDto) => {
		setIsTrySubmit(true);

		const errors: Partial<Record<keyof UpdateAdministratorDto, string>> = {};

		if (values.administratorApplicationId === '') {
			errors.administratorApplicationId = '아이디를 입력해주세요.';
		}

		if (values.administratorName === '') {
			errors.administratorName = '이름을 입력해주세요.';
		}

		if (values.administratorPhone === '') {
			errors.administratorPhone = '휴대폰 번호를 입력해주세요.';
		}

		if (values.administratorPhone.length < 11) {
			errors.administratorPhone = '휴대폰 번호를 확인해주세요.';
		}

		if (!values.roleId) {
			errors.roleId = '권한을 선택해주세요.';
		}

		if (adminInfo?.row.department && !values.departmentId) {
			errors.roleId = '제휴 소속을 선택해주세요.';
		}

		return errors;
	};

	const initialValues: UpdateAdministratorDto = adminInfo
		? {
				administratorApplicationId: adminInfo.row.administratorApplicationId,
				administratorName: adminInfo.row.administratorName,
				administratorPhone: adminInfo.row.administratorPhone,
				departmentId: adminInfo.row.department?.id ?? 0,
				departmentName: adminInfo.row.department?.departmentName ?? '',
				roleId: adminInfo.row.role.id,
				dashboardGroupId: adminInfo.row.dashboardGroupId,
		  }
		: {
				administratorApplicationId: '',
				administratorName: '',
				administratorPhone: '',
				departmentId: 0,
				departmentName: '',
				roleId: 0,
				dashboardGroupId: null,
		  };

	const {
		setFieldValue,
		values: formikValues,
		errors,
		handleChange,
		// setValues,
		validateForm,
		handleSubmit: formikHandleSubmit,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());
			setAlertState(AlertState.NONE);

			await handleSubmit(values);

			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleModifyClick = async () => {
		const res = await validateForm();

		if (Object.keys(res).length) return;

		setAlertState(AlertState.MODIFY);
	};

	const renderTable = <T extends Record<keyof T, any>>({
		tableKeys,
		data,
	}: {
		tableKeys: Record<string, NewTableValueType>;
		data: T;
	}) => {
		const tableKeyList = Object.entries(tableKeys);

		return tableKeyList.map(([key, value], idx) => {
			const tableContent = data[key as keyof T];
			const { label } = value;

			switch (key) {
				case 'createdAt':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							{dayjs(tableContent).format('YYYY-MM-DD HH:mm:ss')}
						</GridItem>
					);

				case 'administratorPhone':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							{phoneFormat(tableContent)}
						</GridItem>
					);

				case 'department':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							{tableContent
								? getDepartmentServiceName(tableContent.service)
								: '오픈마일 관리자'}
						</GridItem>
					);
				case 'departmentManagement':
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							<DepartmentModal userType="ADMIN" />
						</GridItem>
					);
				case 'isLock': {
					const color = !tableContent ? 'gray_11' : 'danger_5_main';
					const viewValue = !tableContent ? '정상' : '잠겨있음';

					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							<span style={{ color: theme.common.colors[color] }}>
								{viewValue}
							</span>
							<div style={{ marginLeft: 'auto' }}>
								{adminInfo?.row.isLock && isSuperAdmin && (
									<LockStatusButton accountId={adminInfo?.row.accountId} />
								)}
							</div>
						</GridItem>
					);
				}
				default:
					return (
						<GridItem
							key={idx.toString()}
							title={label}
							size={GridTitleSize.GT_MEDIUM}
						>
							{tableContent || '-'}
						</GridItem>
					);
			}
		});
	};

	const renderAlert = () => {
		switch (alertState) {
			case AlertState.MODIFY:
				return (
					<Alert
						title="관리자 저장"
						closeButtonClick={() => setAlertState(AlertState.NONE)}
						onConfirmButtonText="저장하기"
						onConfirmButtonClick={() => formikHandleSubmit()}
						isVisible={alertState === AlertState.MODIFY}
					>
						관리자를 저장하시겠습니까?
					</Alert>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<GridDetailTemplate
				detailTitle="관리자 상세"
				onBack={() => goTo(-1)}
				rightAccessory={
					<ButtonContainer>
						<Button onClick={handleModifyClick} size="small">
							저장하기
						</Button>
					</ButtonContainer>
				}
			>
				{isLoading ? (
					<ContentBox borderRadius="10px">
						<Skeleton active paragraph={{ rows: 2 }} />
					</ContentBox>
				) : (
					<ContentBoxWithHeader
						title="관리자 기본 정보"
						borderRadius="10px"
						className="inner-content"
					>
						<GridLayout>
							{adminInfo &&
								renderTable({
									tableKeys: adminInfoTableKey,
									data: adminInfo.row,
								})}
						</GridLayout>
					</ContentBoxWithHeader>
				)}
				<ContentBoxWithHeader
					title="관리자 정보"
					borderRadius="10px"
					className="inner-content"
				>
					<InnerContent>
						<InputForm>
							<Label name="departmentId" essential>
								<span className="label">
									<Typo $typoType="label2">소속</Typo>
								</span>
							</Label>
							<Select
								placeholder="소속 선택"
								value={formikValues.departmentName}
								onChange={(value: string) =>
									setFieldValue('departmentId', value)
								}
							>
								{departmentOptionList.map((item, idx) => (
									<Select.Option value={item.value} key={idx.toString()}>
										{item.label}
									</Select.Option>
								))}
							</Select>
							{errors.roleId && (
								<InputAlert $inputStatus="error">{errors.roleId}</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="roleId" essential>
								<span className="label">
									<Typo $typoType="label2">권한</Typo>
								</span>
							</Label>
							<Select
								placeholder="권한 선택"
								value={formikValues.roleId}
								onChange={(value: string) => setFieldValue('roleId', value)}
							>
								{roleOptionList.map((item, idx) => (
									<Select.Option value={item.value} key={idx.toString()}>
										{item.label}
									</Select.Option>
								))}
							</Select>
							{errors.roleId && (
								<InputAlert $inputStatus="error">{errors.roleId}</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="administratorApplicationId" essential>
								<span className="label">
									<Typo $typoType="label2">아이디</Typo>
								</span>
							</Label>
							<Input
								name="administratorApplicationId"
								placeholder="영문, 숫자 6글자 이상 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formikValues.administratorApplicationId}
								onChange={handleChange}
								onReset={() => setFieldValue('administratorApplicationId', '')}
							/>
							{errors.administratorApplicationId && (
								<InputAlert $inputStatus="error">
									{errors.administratorApplicationId}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="administratorName" essential>
								<span className="label">
									<Typo $typoType="label2">이름</Typo>
								</span>
							</Label>
							<Input
								name="administratorName"
								placeholder="이름을 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formikValues.administratorName}
								onChange={handleChange}
								onReset={() => setFieldValue('administratorName', '')}
							/>
							{errors.administratorName && (
								<InputAlert $inputStatus="error">
									{errors.administratorName}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="administratorPhone" essential>
								<span className="label">
									<Typo $typoType="label2">휴대폰 번호</Typo>
								</span>
							</Label>
							<Input
								name="administratorPhone"
								placeholder="휴대폰 번호를 입력해 주세요"
								maxLength={13}
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={phoneFormat(formikValues.administratorPhone)}
								onChange={(e) => {
									setFieldValue(
										'administratorPhone',
										e.target.value.replaceAll('-', ''),
									);
								}}
								onReset={() => setFieldValue('administratorPhone', '')}
							/>
							{errors.administratorPhone && (
								<InputAlert $inputStatus="error">
									{errors.administratorPhone}
								</InputAlert>
							)}
						</InputForm>
						<InputForm>
							<Label name="dashboardGroupId">
								<span className="label">
									<Typo $typoType="label2">검사대행 대시보드 그룹 ID</Typo>
								</span>
							</Label>
							{isDashboardAdmin && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										gap: '8px',
									}}
								>
									<Button
										size="small"
										onClick={() => setSelectDashboardGroupId(true)}
									>
										조회 및 선택하기
									</Button>
								</div>
							)}
							{selectDashboardGroupId && (
								<SelectionModal
									isVisible={selectDashboardGroupId}
									onChange={(e) =>
										setFieldValue('dashboardGroupId', e[0].dashboardGroupId)
									}
									title="대상 그룹 선택하기"
									onClose={() => setSelectDashboardGroupId(false)}
									column={carServiceDashboardGroupColumn.colsList}
									useQuery={useGetCarServiceDashBoardGroupsQuery}
									defaultSelectedIdList={[formikValues.dashboardGroupId]}
									width={600}
								/>
							)}
							<Input
								name="dashboardGroupId"
								placeholder="대시보드 그룹 ID를 입력해 주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formikValues.dashboardGroupId || ''}
								onChange={(e) =>
									setFieldValue('dashboardGroupId', e.target.value || null)
								}
								onReset={() => setFieldValue('dashboardGroupId', null)}
							/>
							{errors.dashboardGroupId && (
								<InputAlert $inputStatus="error">
									{errors.dashboardGroupId}
								</InputAlert>
							)}
						</InputForm>
					</InnerContent>
				</ContentBoxWithHeader>
			</GridDetailTemplate>
			{renderAlert()}
		</>
	);
}

export default AdminDetailPage;
