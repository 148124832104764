import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import vehicleSubscriptionData from '../../VehicleSubscription/gridData/managementGridData';
import vehicleSubscriptionDataNotSuper from '../../VehicleSubscription/gridData/managementGridDataNotSuper';
import useGridDataWithRTK from '../../../hooks/useGridData/useGridDataWithRTK';
import {
	VEHICLE_SUBSCRIPTION_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH,
} from '../../../routes/constants/urls';
import {
	useGetMyVehicleSubscriptionListQuery,
	useGetVSListAdminQuery,
} from '../../../store/apis/vehicleSubscription';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import {
	createDetailAction,
	createBadgeTextCellRender,
} from '../../../utils/row-data-util';
import useCheckRole from '../../../hooks/useCheckRole';
import {
	CANCEL_APPROVED_VALUE,
	CANCEL_APPROVED_LABEL,
	CANCEL_ENDED_LABEL,
	CANCEL_ENDED_VALUE,
	CANCEL_APPLY_LABEL,
	CANCEL_APPLY_VALUE,
} from '../../../utils/const';
import Button from '../../../components/atoms/Button';
import { blobFileDownload } from '../../../utils/file-util';
import { axiosInstance } from '../../../network/axios';
import { ToastContext } from '../../../contexts/Toast';
import { ButtonTypeType } from '../../../styles/theme';
import useCompaniesOption from '../../../hooks/useCompaniesOption';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const VehicleSubscriptionsManagement = () => {
	// isSuper 권한이 있으면 전체 관리자라고 판단
	const isSuper = useCheckRole({
		roleCategory: '차량구독관리',
		roleName: '현황관리',
		roleType: 'isRead',
		includeSuper: true,
	});
	const isRegister = useCheckRole({
		roleCategory: '차량구독관리',
		roleName: '신청관리',
		roleType: 'isUpdate',
		includeSuper: true,
	});

	const toast = useContext(ToastContext);
	const [isLoading, setIsLoading] = useState(false);

	const downloadFile = async () => {
		setIsLoading(true);
		await axiosInstance
			.get(`/members/admin/subscriptions-vehicle/excel`, {
				responseType: 'blob',
			})
			.then((response) => response.data)
			.then((blob) => {
				const fileName = `오픈트럭 계약현황.xlsx`;
				blobFileDownload(blob, fileName);
			})
			.catch((err) => {
				if (err.response && err.response.status !== 404) {
					toast('error', '요청을 처리할 수 없습니다.');
				}
				if (err.response && err.response.status === 404) {
					toast('error', '데이터가 없습니다.');
				}
			})
			.finally(() => setIsLoading(false));
	};

	const navigate = useNavigate();
	const companyOptions = useCompaniesOption();
	const { formInitialValues, colsList, forms } = isSuper
		? vehicleSubscriptionData({
				isSuper,
				companyOptions,
		  })
		: vehicleSubscriptionDataNotSuper;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleDetailClick,
		handleSearchSubmit,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: !isSuper
			? useGetMyVehicleSubscriptionListQuery
			: useGetVSListAdminQuery,
		detailPath: VEHICLE_SUBSCRIPTION_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="구독 현황관리"
			gridTitle="구독 현황관리"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			onSearchSubmit={handleSearchSubmit}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			isFetching={isFetching}
			rightAccessory={
				<ButtonContainer>
					{isSuper && (
						<Button
							$buttonType={ButtonTypeType.GHOST}
							size="small"
							onClick={downloadFile}
						>
							{!isLoading ? '엑셀 다운로드' : '다운로드 중..'}
						</Button>
					)}
					{isRegister && (
						<Button
							size="small"
							onClick={() =>
								navigate(VEHICLE_SUBSCRIPTION_REGISTER_CONTRACT_PATH)
							}
						>
							오픈트럭 계약 등록
						</Button>
					)}
				</ButtonContainer>
			}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'subscriptionNo'),
				...createBadgeTextCellRender([
					{
						label: CANCEL_APPLY_LABEL,
						value: CANCEL_APPLY_VALUE,
						color: 'red',
					},
					{
						label: CANCEL_APPROVED_LABEL,
						value: CANCEL_APPROVED_VALUE,
						color: 'red',
					},
					{
						label: CANCEL_ENDED_LABEL,
						value: CANCEL_ENDED_VALUE,
						color: 'gray',
					},
				]),
			}}
		/>
	);
};

export default VehicleSubscriptionsManagement;
