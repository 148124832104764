import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Alert from '../../../components/atoms/Alert';
import Button from '../../../components/atoms/Button';
import GridContentTemplate from '../../../components/templates/GridContentTemplate';
import { ToastContext } from '../../../contexts/Toast';
import useGridData from '../../../hooks/useGridData';
import {
	INVENTORY_STATE_STANDARD_CREATE_PATH,
	INVENTORY_STATE_STANDARD_DETAIL_PATH,
} from '../../../routes/constants/urls';
import { CustomErrorType } from '../../../store/apis/@types';
import { useInventoryListDeleteMutation } from '../../../store/apis/inventory';
import { ButtonTypeType } from '../../../styles/theme';
import {
	INANDOUT_RELEASED_LABEL,
	INANDOUT_RELEASED_VALUE,
	INANDOUT_STORED_LABEL,
	INANDOUT_STORED_VALUE,
} from '../../../utils/const';
import { createOptionsBulletTextCellRender } from '../../../utils/row-data-util';
import userData from './gridData';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

function InventoryPage() {
	const { formInitialValues, colsList, forms } = userData;
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const [inventoryListDelete] = useInventoryListDeleteMutation();
	const [isAlertVisible, setIsAlertVisible] = useState(false);

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		selectedRowIds,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		handleRowSelected,
		setSelectedRowIds,
		setPage,
		refetch,
	} = useGridData({
		queryPath: 'members/stock',
		detailPath: INVENTORY_STATE_STANDARD_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	const handleCreateClick = () => {
		navigate(INVENTORY_STATE_STANDARD_CREATE_PATH);
	};

	const handleAlertOpenClick = () => {
		setIsAlertVisible(true);
	};

	const handleAlertCloseClick = () => {
		setIsAlertVisible(false);
	};

	const handleDeleteManagerWhitelists = async () => {
		const result = await inventoryListDelete(
			selectedRowIds.map((item) => ({ stockId: item })),
		);

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '선택하신 재고가 삭제가 완료되었습니다.');
			setSelectedRowIds([]);
			setPage(1);
			refetch();
		}

		handleAlertCloseClick();
	};

	return (
		<>
			<GridContentTemplate
				gridRef={setGridApi}
				mainTitle="재고현황 (입/출고 관리)"
				gridTitle="재고 목록"
				totalCount={totalCount}
				currentPage={page}
				currentPageSize={pageSize}
				onPageChange={handlePageChange}
				onPageSizeChange={handlePageSizeChange}
				onRowClicked={handleDetailClick}
				selectedOptions={{ selectedRowIds, handleRowSelected }}
				rowData={rowData}
				cols={cols}
				formInitialValues={formInitialValues}
				forms={forms}
				onSearchSubmit={handleSearchSubmit}
				frameworkComponents={{
					...createOptionsBulletTextCellRender([
						{
							label: INANDOUT_STORED_LABEL,
							value: INANDOUT_STORED_VALUE,
							color: 'primary_6_main',
						},
						{
							label: INANDOUT_RELEASED_LABEL,
							value: INANDOUT_RELEASED_VALUE,
							color: 'danger_5_main',
						},
					]),
				}}
				rightAccessory={
					<ButtonContainer>
						<Button
							onClick={handleAlertOpenClick}
							disabled={!selectedRowIds.length}
							$buttonType={ButtonTypeType.GHOST_DANGER}
							size="small"
						>
							재고삭제
						</Button>
						<Button onClick={handleCreateClick} size="small">
							재고등록
						</Button>
					</ButtonContainer>
				}
			/>

			{isAlertVisible ? (
				<Alert
					title="재고 선택 삭제"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="삭제하기"
					onConfirmButtonClick={handleDeleteManagerWhitelists}
					isVisible={isAlertVisible}
				>
					선택하신 재고를 삭제 하시겠습니까?
				</Alert>
			) : null}
		</>
	);
}

export default InventoryPage;
