import React, { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { openMilelogoImage } from '../../../assets/images';
import Typo from '../../atoms/Typo';

export type LoginFormLayoutStatusType = 'logo' | 'text' | 'search';
interface LoginFormLayoutProps {
	formTitle?: ReactNode;
	$isTitle?: boolean;
	$status?: LoginFormLayoutStatusType;
	className?: string;
}

const getLoginFormLayoutStatus = (status: LoginFormLayoutStatusType) => {
	let padding;
	switch (status) {
		case 'logo':
			padding = '44px 40px 20px';
			break;
		case 'text':
			padding = '44px 40px 20px';
			break;
		case 'search':
			padding = '72px 80px 40px';
			break;
		default:
			return '';
	}
	return css`
		padding: ${padding};
	`;
};

const LoginFormLayoutContainer = styled.form<LoginFormLayoutProps>`
	display: flex;
	flex-direction: column;
	width: 400px;
	height: 660px;
	${({ $status }) => $status && getLoginFormLayoutStatus($status)};
	border: 1px solid ${({ theme }) => theme.common.colors.gray_4};
	border-radius: 24px;
	margin: 0 auto;
`;

const LogoWrap = styled.div`
	text-align: center;
	margin-bottom: 50px;

	img {
		width: 100%;
		max-width: 174px;
		margin-bottom: 56px;
		object-fit: contain;
	}
`;

const TitleWrap = styled.div<{ $isTitle: boolean }>`
	text-align: center;
	margin-bottom: 45px;
	display: ${({ $isTitle }) => ($isTitle ? '' : 'none')};
`;

function LoginFormLayout({
	formTitle,
	children,
	$isTitle = true,
	$status = 'text',
	className,
}: PropsWithChildren<LoginFormLayoutProps>) {
	return (
		<LoginFormLayoutContainer $status={$status} className={className}>
			{$status === 'logo' ? (
				<>
					<LogoWrap>
						<img src={openMilelogoImage} alt="logo" />
						{formTitle}
					</LogoWrap>
					{children}
				</>
			) : (
				<>
					{$isTitle && (
						<TitleWrap $isTitle={$isTitle}>
							<Typo $typoType="btn1">{formTitle}</Typo>
						</TitleWrap>
					)}
					{children}
				</>
			)}
		</LoginFormLayoutContainer>
	);
}

export default LoginFormLayout;
