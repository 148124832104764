import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import ContentBox from '../../../../components/atoms/ContentBox';
import { ToastContext } from '../../../../contexts/Toast';
import InputForm from '../../../../components/molecules/InputForm';
import Input from '../../../../components/atoms/Input';
import { MakeCustomer } from '../../../../interface/customer';
import Label from '../../../../components/atoms/Label';
import { phoneFormat } from '../../../../utils/data-format';
import Button from '../../../../components/atoms/Button';
import SelectionModal from '../../../../components/organisms/SelectionModal';
import { useGetVehiclesQuery } from '../../../../store/apis/vehicle';
import {
	departmentModalColumn,
	vehicleModalColumn,
} from '../../../../components/templates/modalColumn';
import { useGetDepartmentsQuery } from '../../../../store/apis/department';
import { useMakeCustomerMutation } from '../../../../store/apis/customer';
import { CustomErrorType } from '../../../../store/apis/@types';
import { VehicleDetailDto } from '../../../../interface/vehicle';
import { DepartmentDto } from '../../../../interface/department';
import { ButtonTypeType } from '../../../../styles/theme';
import Typo from '../../../../components/atoms/Typo';
import { CUSTOMER_MANAGEMENT_PATH } from '../../../../routes/constants/urls';

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
	max-width: 830px;
	margin: 0 auto;
`;

const RowContent = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	gap: 8px;
`;

const CustomerCreate = () => {
	const toast = useContext(ToastContext);
	const navigate = useNavigate();

	const [isSelectVehicle, setIsSelectVehicle] = useState(false);
	const [isSelectDepartment, setIsSelectDepartment] = useState(false);
	const [selectedVehicle, setSelectedVehicle] =
		useState<VehicleDetailDto | null>(null);
	const [selectedDepartment, setSelectedDepartment] =
		useState<DepartmentDto | null>(null);

	const [createCustomer] = useMakeCustomerMutation();

	const initialValues: MakeCustomer = {
		customerApplicationId: '',
		customerName: '',
		customerPhone: '',
		companyId: undefined,
		vehicleId: 0,
		departmentId: undefined,
		optionalTermsIds: [],
		marketingAgreement: true,
	};
	const handleSubmit = async (values: typeof initialValues) => {
		const result = await createCustomer(values);
		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '회원 등록이 완료되었습니다.');
			navigate(CUSTOMER_MANAGEMENT_PATH);
		}
	};

	const formik = useFormik({ initialValues, onSubmit: handleSubmit });

	return (
		<GridDetailTemplate detailTitle="회원계정 생성하기">
			<ContentBox borderRadius="10px">
				<InnerContent>
					<InputForm>
						<Label name="customerApplicationId" essential>
							아이디
						</Label>
						<Input
							type="text"
							name="customerApplicationId"
							onChange={formik.handleChange}
							value={formik.values.customerApplicationId}
							onReset={() => formik.setFieldValue('customerApplicationId', '')}
						/>
					</InputForm>
					<InputForm>
						<Label name="customerName" essential>
							이름
						</Label>
						<Input
							type="text"
							name="customerName"
							onChange={formik.handleChange}
							value={formik.values.customerName}
							onReset={() => formik.setFieldValue('customerName', '')}
						/>
					</InputForm>
					<InputForm>
						<Label name="customerPhone" essential>
							휴대폰번호
						</Label>
						<Input
							type="text"
							name="customerPhone"
							onChange={(e) =>
								formik.setFieldValue(
									'customerPhone',
									e.target.value.replaceAll('-', ''),
								)
							}
							value={phoneFormat(formik.values.customerPhone)}
							onReset={() => formik.setFieldValue('customerPhone', '')}
						/>
					</InputForm>
					<InputForm>
						<Label name="vehicleId" essential>
							차량정보
						</Label>
						<RowContent>
							<Button
								size="small"
								$buttonType={ButtonTypeType.GHOST}
								onClick={() => setIsSelectVehicle(true)}
							>
								차량선택
							</Button>
							{selectedVehicle && (
								<Typo>{`${selectedVehicle.vehicleNumber || '번호없음'} | ${
									selectedVehicle.vehicleName
								} | ${selectedVehicle.vehicleCategory} | ${
									selectedVehicle.carLoadingBoxType
								} | ${selectedVehicle.loadingCapacity}톤`}</Typo>
							)}
							<SelectionModal
								title="차량선택"
								isVisible={isSelectVehicle}
								onChange={(e) => {
									formik.setFieldValue('vehicleId', e[0].id);
									setSelectedVehicle(e[0]);
								}}
								useQuery={useGetVehiclesQuery}
								column={vehicleModalColumn.colsList}
								searchInput={vehicleModalColumn.forms}
								onClose={() => setIsSelectVehicle(false)}
							/>
						</RowContent>
					</InputForm>
					<InputForm>
						<Label name="departmentId">소속정보</Label>
						<RowContent>
							<Button
								size="small"
								$buttonType={ButtonTypeType.GHOST}
								onClick={() => setIsSelectDepartment(true)}
							>
								소속선택
							</Button>
							{selectedDepartment && (
								<Typo>{`${selectedDepartment.departmentName} | 법인명: ${
									selectedDepartment.company?.name ?? '-'
								}`}</Typo>
							)}
							<SelectionModal
								title="소속선택"
								isVisible={isSelectDepartment}
								onChange={(e) => {
									formik.setFieldValue('departmentId', e[0].id);
									formik.setFieldValue(
										'companyId',
										e[0].company?.id || undefined,
									);
									setSelectedDepartment(e[0]);
								}}
								useQuery={useGetDepartmentsQuery}
								defaultQueryParams={{ service: 'CUSTOMER_APP' }}
								column={departmentModalColumn.colsList}
								searchInput={departmentModalColumn.forms}
								onClose={() => setIsSelectDepartment(false)}
							/>
						</RowContent>
					</InputForm>
					<Button onClick={() => formik.handleSubmit()}>생성하기</Button>
				</InnerContent>
			</ContentBox>
		</GridDetailTemplate>
	);
};

export default CustomerCreate;
