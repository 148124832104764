import { ICellRendererParams } from 'ag-grid-community';
import { ColSize, SelectionGridColumn } from '../../utils/grid-util';
import { FilterElementType } from '../../utils/filter-util';
import {
	companyRegisterNumberFormat,
	numberWithCommas,
	phoneFormat,
} from '../../utils/data-format';
import { getDepartmentServiceName } from '../../utils/string-util';
import { renderDate } from '../../utils/row-data-util';
import { renderCustomerStatusCell } from '../../pages/UserManagement/CustomerManagement/Customers/gridData';

// eslint-disable-next-line import/prefer-default-export
export const companyModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '법인명',
			field: 'name',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
		},
		{
			headerName: '사업자번호',
			field: 'businessLicenseNumber',
			flex: 1,
			cellRenderer: (params: any) => {
				const { value } = params;
				return companyRegisterNumberFormat(value);
			},
		},
		{
			headerName: '담당자 이름',
			field: 'contactName',
			flex: 1,
		},
		{
			headerName: '담당자 연락처',
			field: 'contactPhoneNumber',
			flex: 1,
			cellRenderer: (params: any) => {
				const { value } = params;
				return phoneFormat(value);
			},
		},
		{
			headerName: '가입일자',
			field: 'createdAt',
			flex: 1,
			cellRenderer: renderDate('YYYY-MM-DD HH:mm'),
		},
	],
	forms: [
		{
			key: 'name',
			label: '법인명',
			type: FilterElementType.INPUT,
			span: 1,
		},
	],
};

export const customerModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '가입일자',
			field: 'createdAt',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			// headerCheckboxSelection: true,
			// checkboxSelection: true,
			// cellStyle: {
			// 	textOverflow: 'ellipsis',
			// 	overflow: 'hidden',
			// },
			cellRenderer: renderDate('YYYY-MM-DD HH:mm'),
		},
		{
			headerName: '회원아이디',
			field: 'customerApplicationId',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '회원명',
			field: 'customerName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '연락처',
			field: 'customerPhone',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: (params: any) => {
				const { value } = params;
				return phoneFormat(value);
			},
		},
		{
			headerName: '회원상태',
			field: 'customerStatus',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return renderCustomerStatusCell({ value });
			},
		},
	],
	forms: [
		{
			key: 'customerName',
			label: '회원명',
			type: FilterElementType.INPUT,
			span: 1,
		},
	],
};

export const plateModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '번호판',
			field: 'vehicleNumber',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '소유자',
			field: 'companyName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.company?.name ?? '-';
			},
			valueGetter: ({ data }) => {
				return data.company?.name ?? '';
			},
		},
		{
			headerName: '차대번호',
			field: 'vehicleVin',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.vehicle ? data.vehicle.vin ?? '-' : '배정된 차량없음';
			},
			valueGetter: ({ data }) => {
				return data.vehicle?.vin ?? '';
			},
		},
		{
			headerName: '차종명',
			field: 'vehicle.vehicleName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '차군',
			field: 'vehicleCategory',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.vehicle?.vehicleCategory ?? '-';
			},
			valueGetter: ({ data }) => {
				return data.vehicle?.vehicleCategory ?? '';
			},
		},
		{
			headerName: '탑 형식',
			field: 'carLoadingBoxType',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.vehicle?.carLoadingBoxType ?? '-';
			},
			valueGetter: ({ data }) => {
				return data.vehicle?.carLoadingBoxType ?? '';
			},
		},
		{
			headerName: '적재량',
			field: 'loadingCapacity',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.vehicle?.loadingCapacity ?? '-';
			},
			valueGetter: ({ data }) => {
				return data.vehicle?.loadingCapacity ?? '';
			},
		},
	],
	forms: [
		{
			key: 'vehicleNumber',
			label: '번호판',
			type: FilterElementType.INPUT,
			span: 1,
		},
	],
};

export const vehicleModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '차량번호',
			field: 'vehicleNumber',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			// headerCheckboxSelection: true,
			// checkboxSelection: true,
			// cellStyle: {
			// 	textOverflow: 'ellipsis',
			// 	overflow: 'hidden',
			// },
		},
		{
			headerName: '소유자',
			field: 'ownerCompanyName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.ownerCompany?.name ?? '-';
			},
			valueGetter: ({ data }) => {
				return data.ownerCompany?.name ?? '-';
			},
		},
		{
			headerName: '소속',
			field: 'departmentName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.department?.departmentName ?? '-';
			},
			valueGetter: ({ data }) => {
				return data.department?.departmentName ?? '';
			},
		},
		{
			field: 'driver',
			headerName: '배정기사',
			minWidth: ColSize.CW_MEDIUM,
			flex: 1,
			valueGetter: ({ data }: any) => {
				return data.driver?.driverName ?? '-';
			},
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.driver?.driverName ?? '-';
			},
		},
		{
			field: 'customer',
			headerName: '사용회원',
			minWidth: ColSize.CW_MEDIUM,
			flex: 1,
			valueGetter: ({ data }: any) => {
				return data.driver?.customer?.customerName ?? '-';
			},
			cellRenderer: ({ data }: ICellRendererParams) => {
				return data.driver?.customer?.customerName ?? '-';
			},
		},
		{
			headerName: '차량명',
			field: 'vehicleName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '차종',
			field: 'vehicleCategory',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '탑 형식',
			field: 'carLoadingBoxType',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '적재량',
			field: 'loadingCapacity',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '차대번호',
			field: 'vin',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
	],
	forms: [
		{
			key: 'vehicleNumber',
			label: '차량번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'vin',
			label: '차대번호',
			type: FilterElementType.INPUT,
		},
	],
};

export const departmentModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '소속명',
			field: 'departmentName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			// headerCheckboxSelection: true,
			// checkboxSelection: true,
			// cellStyle: {
			// 	textOverflow: 'ellipsis',
			// 	overflow: 'hidden',
			// },
		},
		{
			headerName: '법인명',
			field: 'company',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value && value.name;
			},
		},
		{
			headerName: '소속 타입',
			field: 'service',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return getDepartmentServiceName(data.service);
			},
			valueGetter: ({ data }) => {
				return getDepartmentServiceName(data.service);
			},
		},
		{
			headerName: '등록일',
			field: 'createdAt',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: renderDate('YYYY-MM-DD HH:mm'),
		},
	],
	forms: [
		{
			key: 'departmentName',
			label: '소속명',
			type: FilterElementType.INPUT,
			span: 1,
		},
	],
};

export const subscriptionProductModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '상품명',
			field: 'subscriptionProductName',
			flex: 1,
		},
		{
			headerName: '상품 소개',
			field: 'subscriptionProductDescription',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
		},
		{
			headerName: '월 가격',
			field: 'monthlyPriceTax',
			flex: 1,
			cellRenderer: ({ data }: ICellRendererParams) => {
				return numberWithCommas(data.monthlyPrice + data.monthlyTax);
			},
		},
		{
			headerName: '판매중',
			field: 'subscriptionProductViewable',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value ? '판매중' : '숨김';
			},
		},
	],
	forms: [
		{
			key: 'subscriptionProductName',
			label: '상품명',
			type: FilterElementType.INPUT,
			span: 1,
		},
	],
};

export const subscriptionProductOnSaleModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '상품명',
			field: 'subscriptionProductName',
			flex: 1,
		},
		{
			headerName: '상품 소개',
			field: 'subscriptionProductDescription',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
		},
		{
			headerName: '월 가격',
			field: 'monthlyPriceTax',
			flex: 1,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return numberWithCommas(value);
			},
		},
	],
	forms: [
		{
			key: 'subscriptionProductName',
			label: '상품명',
			type: FilterElementType.INPUT,
			span: 1,
		},
	],
};

export const driverModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '이름',
			field: 'driverName',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '전화번호',
			field: 'driverPhone',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value ? phoneFormat(value) : '-';
			},
		},
		{
			headerName: '법인명',
			field: 'company.name',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
		{
			headerName: '사업자번호',
			field: 'company.businessLicenseNumber',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return companyRegisterNumberFormat(value);
			},
		},
		{
			headerName: '배정차량',
			field: 'vehicle',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
			cellRenderer: ({ value }: ICellRendererParams) => {
				return value ? value.vehicleNumber : '없음';
			},
		},
		{
			headerName: '차대번호',
			field: 'vehicle.vin',
			flex: 1,
			minWidth: ColSize.CW_SMALL,
		},
	],
	forms: [],
};

export const adminRolesModalColumn: SelectionGridColumn = {
	colsList: [
		{
			headerName: '권한명',
			field: 'roleName',
			flex: 1,
			minWidth: ColSize.CW_MEDIUM,
		},
		{
			headerName: '생성일자',
			field: 'createdAt',
			flex: 1,
			cellRenderer: renderDate('YYYY-MM-DD HH:mm'),
		},
		{
			headerName: '업데이트 일자',
			field: 'updatedAt',
			flex: 1,
			cellRenderer: renderDate('YYYY-MM-DD HH:mm'),
		},
	],
	forms: [],
};
