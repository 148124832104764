import {
	SubproductCreateUpdateDto,
	SubproductDto,
} from '../../../interface/product';
import { ListResponse, ObjectResponse } from '../@types';
import { authApiSlice } from '../query';

const subproductApi = authApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// 하위상품 생성
		createSubproduct: builder.mutation<
			ObjectResponse<SubproductDto>,
			SubproductCreateUpdateDto
		>({
			query: (body) => ({
				method: 'POST',
				url: '/members/subproducts',
				body,
			}),
			invalidatesTags: ['Subproducts'],
		}),

		// 하위상품 목록 조회
		getSubproducts: builder.query<
			ListResponse<SubproductDto>,
			Record<any, any>
		>({
			query: (args) => ({
				method: 'GET',
				url: `/members/subproducts`,
				params: args,
			}),
			providesTags: ['Subproducts'],
		}),

		// 하위상품 상세정보 조회
		getSubproductDetail: builder.query<
			ObjectResponse<SubproductDto>,
			{
				subproductId: number | undefined;
			}
		>({
			query: ({ subproductId }) => ({
				method: 'GET',
				url: `/members/subproducts/${subproductId}`,
			}),
			providesTags: (result, error, { subproductId }) => [
				{ type: 'Subproduct', id: subproductId },
			],
		}),

		// 하위상품 상세정보 수정
		updateSubproduct: builder.mutation<
			ObjectResponse<SubproductDto>,
			{
				subproductId: number;
				body: SubproductCreateUpdateDto;
			}
		>({
			query: ({ subproductId, body }) => ({
				method: 'PUT',
				url: `/members/subproducts/${subproductId}`,
				body,
			}),
			invalidatesTags: (result, error, { subproductId }) => [
				'Subproducts',
				{ type: 'Subproduct', id: subproductId },
			],
		}),

		// 하위상품 삭제
		deleteSubproduct: builder.mutation<ObjectResponse<SubproductDto>, string>({
			query: (subproductId) => ({
				method: 'DELETE',
				url: `/members/subproducts/${subproductId}`,
			}),
			invalidatesTags: ['Subproducts'],
		}),
	}),
});

export const {
	useCreateSubproductMutation,
	useGetSubproductsQuery,
	useGetSubproductDetailQuery,
	useUpdateSubproductMutation,
	useDeleteSubproductMutation,
} = subproductApi;
export default subproductApi;
