import { ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../utils/grid-util';
import { CELL_TYPE_DETAIL_ACTION } from '../../utils/row-data-util';
import { renderCustomerStatusCell } from '../UserManagement/CustomerManagement/Customers/gridData';
import { renderSubscriptionStatusCell } from '../OpenSpannerManagement/PurchaseHistoryManagement/PurchaseHistory/gridData';

const vehicleSubscriptionData: GridDataModel = {
	formInitialValues: {},
	colsList: [
		{
			tableInfo: {
				field: 'startedAt',
				headerName: '구독시작일자',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
				cellRenderer: ({ value }: ICellRendererParams) => {
					return value ? dayjs(value).format('YYYY.MM.DD(dd) HH:mm:ss') : '-';
				},
			},
		},
		{
			tableInfo: {
				field: 'subscriptionProductName',
				headerName: '구독상품명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'vehicleNumber',
				headerName: '차량번호',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerName',
				headerName: '회원명',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerPhone',
				headerName: '연락처',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerStatus',
				headerName: '회원상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: renderCustomerStatusCell,
			},
		},
		{
			tableInfo: {
				field: 'subscriptionStatus',
				headerName: '구독상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: renderSubscriptionStatusCell,
			},
		},
		{
			tableInfo: {
				field: 'detail',
				headerName: '상세보기',
			},
			options: {
				pinned: 'right',
				width: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
	],
	forms: [],
};

export default vehicleSubscriptionData;
