import { useNavigate } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import vehicleSubscriptionData from './gridData';
import useGridDataWithRTK from '../../hooks/useGridData/useGridDataWithRTK';
import {
	CAR_CARE_SUBSCRIPTION_DETAIL_PATH,
	CAR_CARE_SUBSCRIPTION_REQUEST_PATH,
} from '../../routes/constants/urls';
import GridContentTemplate from '../../components/templates/GridContentTemplate';
import Button from '../../components/atoms/Button';
import useCheckRole from '../../hooks/useCheckRole';
import { createDetailAction } from '../../utils/row-data-util';
import { useGetMyCarCareSubscriptionListQuery } from '../../store/apis/carCareSubscription';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const MyCarCareSubscriptionManagement = () => {
	const navigate = useNavigate();
	const isCreateVehicleSubscriptionRequest = useCheckRole({
		roleCategory: '카케어구독관리',
		roleName: '서비스관리',
		roleType: 'isCreate',
	});

	const { formInitialValues, colsList, forms } = vehicleSubscriptionData;

	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleDetailClick,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: useGetMyCarCareSubscriptionListQuery,
		detailPath: CAR_CARE_SUBSCRIPTION_DETAIL_PATH,
		formInitialValues,
		colsList,
	});

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="카케어 현황"
			gridTitle="카케어 현황"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			isFetching={isFetching}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'subscriptionNo'),
			}}
			rightAccessory={
				isCreateVehicleSubscriptionRequest && (
					<ButtonContainer>
						<Button
							onClick={() => navigate(CAR_CARE_SUBSCRIPTION_REQUEST_PATH)}
							size="small"
						>
							서비스 신청
						</Button>
					</ButtonContainer>
				)
			}
		/>
	);
};

export default MyCarCareSubscriptionManagement;
