export const phoneFormat = (value: string) => {
	let cleaned = value?.replace(/[^0-9]/g, '');

	if (cleaned?.length > 11) {
		cleaned = cleaned.substring(0, 11);
	}

	if (cleaned?.length >= 10) {
		return cleaned.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
	}

	return cleaned;
};

export const companyRegisterNumberFormat = (value: string = '') => {
	return value
		.replace(/[^0-9]/g, '')
		.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`);
};

export const formatPassword = (value: string) => {
	const passwordRegex =
		// /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,20}$/;
		// 최소 8자, 하나 이상의 대문자, 하나의 소문자, 하나의 특수 문자
		/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/;
	if (passwordRegex.test(value)) {
		return value;
	}
	return '';
};

export const numberWithCommas = (value: number) => {
	if (value < 0) {
		return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	const stringValue = String(value).replace(/[^0-9]/g, '');
	return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getPriceAsNumber = (price: string, isTax = false) => {
	const priceAsNumber = Number(price.replace(/,/g, ''));
	const tax = Math.round(priceAsNumber / 11);

	if (Number.isNaN(priceAsNumber)) {
		return 0;
	}
	return !isTax ? priceAsNumber : tax;
};

export const stringWithCommas = (value: string) => {
	return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const driverLicenseFormat = (value: string | null): any => {
	if (value == null || value.trim() === '') {
		return null;
	}

	const cleanedValue = value.replace(/[^0-9]/g, '');

	if (cleanedValue.length >= 12) {
		return `${cleanedValue.slice(0, 2)}-${cleanedValue.slice(
			2,
			4,
		)}-${cleanedValue.slice(4, 10)}-${cleanedValue.slice(10, 12)}`;
	}

	return cleanedValue;
};
