import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Modal from '../../../../components/atoms/Modal';
import Button from '../../../../components/atoms/Button';
import { ButtonTypeType } from '../../../../styles/theme';
import Typo from '../../../../components/atoms/Typo';
import { useUnlockCustomerMutation } from '../../../../store/apis/customer';
import { ToastContext } from '../../../../contexts/Toast';
import { CustomErrorType } from '../../../../store/apis/@types';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';

interface LockModalProps {
	isVisible: boolean;
	onClose: () => void;
	onRefetch: () => void;
	defaultValue: { isLock: boolean };
	accountId: number;
}

export const ModalTitle = styled.h1`
	${({ theme }) => theme.typo.h2};
`;

const ModalFooterWrapper = styled.div`
	display: flex;
	gap: 8px;
	justify-content: flex-end;
`;

function LockModal({
	isVisible,
	onClose,
	onRefetch,
	defaultValue,
	accountId,
}: LockModalProps) {
	const [unlockUser] = useUnlockCustomerMutation();

	const dispatch = useDispatch();
	const setToast = useContext(ToastContext);

	const handleModalClose = () => {
		onClose();
	};

	const handleSubmit = async () => {
		dispatch(fullLoadingOn());

		const result = await unlockUser({
			body: { accountId, isLock: false },
		});

		if (!('error' in result) && result.data.row.updateResult) {
			setToast('info', '잠금해제가 완료되었습니다.');

			if (onRefetch) onRefetch();
			if (onClose) onClose();
		} else if ('error' in result) {
			setToast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		}

		dispatch(fullLoadingOff());
	};

	return (
		<Modal
			title={<ModalTitle>잠금해제</ModalTitle>}
			open={isVisible}
			onCancel={handleModalClose}
			width={640}
			destroyOnClose
			footer={
				<ModalFooterWrapper>
					<Button
						$buttonType={ButtonTypeType.OPAQUE}
						onClick={handleModalClose}
					>
						닫기
					</Button>
					<Button disabled={!defaultValue.isLock} onClick={handleSubmit}>
						완료
					</Button>
				</ModalFooterWrapper>
			}
		>
			<Typo>잠금상태를 해제하시겠습니까?</Typo>
		</Modal>
	);
}

export default LockModal;
