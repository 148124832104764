import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { FormikHelpers, useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { MakeCompanyDta, UpdateCompanyDto } from '../../../interface/company';
import { ToastContext } from '../../../contexts/Toast';
import { useBigFilesUploadSecureMutation } from '../../../store/apis/upload';
import {
	useMakeCompanyMutation,
	useUpdateCompanyMutation,
	useUpdateMyCompanyMutation,
} from '../../../store/apis/company';
import useCheckRole from '../../../hooks/useCheckRole';
import { CustomErrorType } from '../../../store/apis/@types';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { COMPANY_MANAGEMENT_PATH } from '../../../routes/constants/urls';
import GridDetailTemplate from '../GridDetailTemplate';
import Button from '../../atoms/Button';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import InputForm from '../../molecules/InputForm';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import Input from '../../atoms/Input';
import InputAlert from '../../atoms/InputAlert';
import {
	companyRegisterNumberFormat,
	phoneFormat,
} from '../../../utils/data-format';
import Alert from '../../atoms/Alert';
import { formatDateString } from '../../../utils/date-util';
import Badge from '../../atoms/Badge';
import GridLayout, { GridItem } from '../../molecules/GridLayout';
import Divider from '../../../styles/divider';
import SelectionModal from '../../organisms/SelectionModal';
import { useGetRolesQuery } from '../../../store/apis/role';
import { adminRolesModalColumn } from '../modalColumn';
import { ButtonTypeType } from '../../../styles/theme';

interface CompanyTemplateProps {
	initialValues: MakeCompanyDta | UpdateCompanyDto;
	title?: string;
	createdAt?: string;
	updatedAt?: string;
}

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
}

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const CompanyTemplate = ({
	initialValues,
	title = '법인 등록하기',
	createdAt,
	updatedAt,
}: CompanyTemplateProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const theme = useTheme();

	const { companyId } = useParams();

	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isRoleSelect, setIsRoleSelect] = useState(false);
	const [selectedRoleName, setSelectedRoleName] = useState(undefined);
	const [fileUploadSecure] = useBigFilesUploadSecureMutation();

	const [makeCompany] = useMakeCompanyMutation();
	const [updateCompany] = useUpdateCompanyMutation();
	const [updateMyCompany] = useUpdateMyCompanyMutation();

	const isCreateCompany = useCheckRole({
		roleCategory: '회원관리',
		roleName: '법인관리',
		roleType: 'isCreate',
	});
	const isUpdateCompany = useCheckRole({
		roleCategory: '회원관리',
		roleName: '법인관리',
		roleType: 'isUpdate',
	});

	const isSuper = useCheckRole({
		roleCategory: '회원관리',
		roleName: '법인관리',
		roleType: 'isSuper',
	});

	const onDrop = useCallback((acceptedFiles: File[]) => {
		// Do something with the files
		// eslint-disable-next-line
		console.log(acceptedFiles);
	}, []);
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({ onDrop, noKeyboard: true });

	const handleUploadFile = async (
		formikHelpers: FormikHelpers<typeof initialValues>,
		defaultValues: { businessLicense: string },
	) => {
		const resultUrls: { businessLicense: string } = {
			businessLicense: defaultValues.businessLicense,
		};

		if (acceptedFiles.length) {
			const res = await fileUploadSecure({
				file: [...acceptedFiles],
				folder: 'company/business-license',
			});

			if ('error' in res) {
				const errorMessage =
					(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message;

				toast('error', errorMessage);

				formikHelpers.setErrors({ businessLicense: errorMessage });

				return null;
			}

			resultUrls.businessLicense = res.data.rows[0] as string;
		}

		return resultUrls;
	};

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);

		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (values.name === '') {
			errors.name = '법인명을 입력해주세요.';
		}
		return errors;
	};

	const handleCreateSubmit = async (values: typeof initialValues) => {
		const cleanedValues = {
			...values,
			businessLicenseNumber: values.businessLicenseNumber?.replace(
				/[^0-9]/g,
				'',
			),
		};

		const result = await makeCompany(cleanedValues as MakeCompanyDta);

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '법인 등록이 완료되었습니다.');
			navigate(COMPANY_MANAGEMENT_PATH);
		}
	};

	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const { businessLicense, ...rest } = values;

		const result = !isSuper
			? await updateMyCompany({
					body: {
						...rest,
						businessLicense:
							businessLicense === initialValues.businessLicense
								? undefined
								: businessLicense,
					} as UpdateCompanyDto,
			  })
			: await updateCompany({
					body: {
						...rest,
						businessLicense:
							businessLicense === initialValues.businessLicense
								? undefined
								: businessLicense,
					} as UpdateCompanyDto,
					companyId,
			  });

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '법인 수정이 완료되었습니다.');
			navigate(COMPANY_MANAGEMENT_PATH);
		}
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values, formikHelpers) => {
			dispatch(fullLoadingOn());

			const uploadResult = await handleUploadFile(formikHelpers, {
				businessLicense: values.businessLicense ?? '',
			});
			if (uploadResult) {
				if (!companyId) {
					await handleCreateSubmit({
						...(values as MakeCompanyDta),
						businessLicense: uploadResult.businessLicense,
					});
				} else {
					await handleUpdateSubmit({
						...(values as UpdateCompanyDto),
						businessLicense: uploadResult.businessLicense,
					});
				}
			}

			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleCancelClick = () => {
		navigate(-1);
	};

	const handleCreateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.CREATE);
		}
	};

	const handleUpdateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.UPDATE);
		}
	};

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.CREATE:
				return (
					<Alert
						title="법인 등록"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="등록하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.CREATE}
					>
						법인을 등록하시겠습니까?
					</Alert>
				);
			case AlertState.UPDATE:
				return (
					<Alert
						title="법인 수정"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="수정하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						법인 정보를 수정하시겠습니까?
					</Alert>
				);
			default:
				return null;
		}
	}, [alertState, formik]);

	return (
		<>
			<GridDetailTemplate
				detailTitle="법인 관리"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						{!companyId ? (
							<Button
								onClick={handleCreateClick}
								size="small"
								disabled={!isCreateCompany}
							>
								{isCreateCompany ? '등록하기' : '등록 권한없음'}
							</Button>
						) : (
							<Button
								onClick={handleUpdateClick}
								size="small"
								disabled={!isUpdateCompany}
							>
								{isUpdateCompany ? '수정하기' : '수정 권한없음'}
							</Button>
						)}
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title={title as string}
					borderRadius="10px"
					className="inner-content"
				>
					{(createdAt || updatedAt) && (
						<>
							<GridLayout>
								{createdAt && (
									<GridItem title="등록일">
										{formatDateString(createdAt, true)}
									</GridItem>
								)}
								{updatedAt && (
									<GridItem title="수정일">
										{formatDateString(updatedAt, true)}
									</GridItem>
								)}
							</GridLayout>
							<Divider $verticalGap={16} />
						</>
					)}
					<GridLayout>
						<GridItem title="법인명">
							<Input
								name="name"
								placeholder="법인명을 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.name ?? ''}
								onChange={formik.handleChange}
								onReset={() => formik.setFieldValue('name', '')}
							/>
							{formik.errors.name && (
								<InputAlert $inputStatus="error">
									{formik.errors.name}
								</InputAlert>
							)}
						</GridItem>
						<GridItem title="법인등록번호">
							<Input
								name="registrationNumber"
								placeholder="법인등록번호를 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.registrationNumber ?? ''}
								onChange={formik.handleChange}
								onReset={() =>
									formik.setFieldValue('registrationNumber', undefined)
								}
							/>
							{formik.errors.registrationNumber && (
								<InputAlert $inputStatus="error">
									{formik.errors.registrationNumber}
								</InputAlert>
							)}
						</GridItem>
						<GridItem title="사업자등록번호">
							<Input
								name="businessLicenseNumber"
								placeholder="사업자등록번호를 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={companyRegisterNumberFormat(
									formik.values.businessLicenseNumber ?? '',
								)}
								onChange={(e) =>
									formik.setFieldValue(
										'businessLicenseNumber',
										e.target.value.replace(/,/g, ''),
									)
								}
								maxLength={12}
								onReset={() =>
									formik.setFieldValue('businessLicenseNumber', '')
								}
							/>
							{formik.errors.businessLicenseNumber && (
								<InputAlert $inputStatus="error">
									{formik.errors.businessLicenseNumber}
								</InputAlert>
							)}
						</GridItem>
						<GridItem title="대표 연락처">
							<Input
								name="representativePhoneNumber"
								placeholder="대표번호를 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={phoneFormat(
									formik.values.representativePhoneNumber ?? '',
								)}
								onChange={(e) => {
									formik.setFieldValue(
										'representativePhoneNumber',
										e.target.value.replaceAll('-', ''),
									);
								}}
								onReset={() =>
									formik.setFieldValue('representativePhoneNumber', '')
								}
							/>
							{formik.errors.representativePhoneNumber && (
								<InputAlert $inputStatus="error">
									{formik.errors.representativePhoneNumber}
								</InputAlert>
							)}
						</GridItem>
					</GridLayout>
					<Divider $verticalGap={16} />
					{!companyId && (
						<>
							<p className="label" style={{ marginBottom: 10 }}>
								<Typo $typoType="label2">
									초기 비밀번호는 대표 연락처의 뒤 네자리입니다. 소속명을
									입력하지 않는 경우 법인명과 동일하게 생성됩니다.
								</Typo>
							</p>
							<GridLayout>
								<GridItem title="관리자 아이디">
									<Input
										name="adminApplicationId"
										placeholder="관리자 아이디를 입력해주세요"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										value={(formik.values as MakeCompanyDta).adminApplicationId}
										onChange={formik.handleChange}
										onReset={() =>
											formik.setFieldValue('adminApplicationId', '')
										}
									/>
									{(formik.errors as MakeCompanyDta).adminApplicationId && (
										<InputAlert $inputStatus="error">
											{(formik.errors as MakeCompanyDta).adminApplicationId}
										</InputAlert>
									)}
								</GridItem>
								<GridItem title="관리자 권한">
									<Button
										onClick={() => setIsRoleSelect(true)}
										size="small"
										$buttonType={ButtonTypeType.GHOST}
									>
										권한 선택
									</Button>
									<span style={{ marginLeft: 4 }}>
										{(formik.values as MakeCompanyDta).roleId
											? selectedRoleName
											: ''}
									</span>
									<SelectionModal
										onClose={() => setIsRoleSelect(false)}
										onChange={(e) => {
											formik.setFieldValue('roleId', e[0].id);
											setSelectedRoleName(e[0].roleName);
										}}
										column={adminRolesModalColumn.colsList}
										useQuery={useGetRolesQuery}
										isVisible={isRoleSelect}
										defaultSelectedIdList={[
											(formik.values as MakeCompanyDta).roleId,
										]}
										title="관리자 권한 선택"
									/>
									{(formik.errors as MakeCompanyDta).roleId && (
										<InputAlert $inputStatus="error">
											{(formik.errors as MakeCompanyDta).roleId}
										</InputAlert>
									)}
								</GridItem>
								<GridItem title="기본 소속명">
									<Input
										name="departmentName"
										placeholder="법인의 기본 소속명을 입력해주세요"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										value={
											(formik.values as MakeCompanyDta).departmentName ?? ''
										}
										onChange={(e) =>
											formik.setFieldValue(
												'departmentName',
												e.target.value || undefined,
											)
										}
										onReset={() =>
											formik.setFieldValue('departmentName', undefined)
										}
									/>
								</GridItem>
							</GridLayout>
						</>
					)}
					<GridLayout>
						<GridItem title="담당자">
							<Input
								name="contactName"
								placeholder="담당자 이름을 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.contactName ?? ''}
								onChange={formik.handleChange}
								onReset={() => formik.setFieldValue('contactName', '')}
							/>
							{formik.errors.contactName && (
								<InputAlert $inputStatus="error">
									{formik.errors.contactName}
								</InputAlert>
							)}
						</GridItem>
						<GridItem title="담당자 연락처">
							<Input
								name="contactPhoneNumber"
								placeholder="담당자 연락처를 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={phoneFormat(formik.values.contactPhoneNumber ?? '')}
								onChange={(e) => {
									formik.setFieldValue(
										'contactPhoneNumber',
										e.target.value.replaceAll('-', ''),
									);
								}}
								onReset={() => formik.setFieldValue('contactPhoneNumber', '')}
							/>
							{formik.errors.contactPhoneNumber && (
								<InputAlert $inputStatus="error">
									{formik.errors.contactPhoneNumber}
								</InputAlert>
							)}
						</GridItem>
					</GridLayout>
					<Divider $verticalGap={16} />
					<InputForm>
						<Label name="businessLicense" essential>
							<Typo $typoType="label2">사업자등록증</Typo>
						</Label>
						{initialValues.businessLicense && (
							<Label name="businessLicense">
								<span>
									<a
										href={formik.values.businessLicense}
										target="_blank"
										rel="noreferrer"
									>
										현재 등록되어있는 사업자등록증
									</a>
								</span>
							</Label>
						)}
						{formik.errors.businessLicense && (
							<InputAlert $inputStatus="error">
								{formik.errors.businessLicense}
							</InputAlert>
						)}
						{acceptedFiles.map((file) => (
							<span key={file.name}>
								<Badge color="blue">업로드 예정파일</Badge> {file.name}
							</span>
						))}
						<div
							style={{
								border: `1px solid ${theme.common.colors.gray_3}`,
								padding: '14px',
								borderRadius: '10px',
							}}
							{...getRootProps()}
						>
							<input {...getInputProps()} />
							{isDragActive ? (
								<p>끌어온 파일을 내려놓으세요.</p>
							) : (
								<Button $buttonType={ButtonTypeType.GHOST_BLACK}>
									업로드할 사업자등록증을 첨부해주세요
								</Button>
							)}
						</div>
					</InputForm>
				</ContentBoxWithHeader>
			</GridDetailTemplate>
			{renderAlert()}
		</>
	);
};

export default CompanyTemplate;
