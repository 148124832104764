import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import userData from './gridData';
import { VEHICLE_OBD_PATH } from '../../../../routes/constants/urls';
import GridContentTemplate from '../../../../components/templates/GridContentTemplate';
import useClientGridWithRTK from '../../../../hooks/useClientGridWithRTK';
import { createDetailAction } from '../../../../utils/row-data-util';
import Input from '../../../../components/atoms/Input';
import { useGetVehiclesOBDInfoQuery } from '../../../../store/apis/vehicleConditions';
import { RootState } from '../../../../store';
import useCheckRole from '../../../../hooks/useCheckRole';
import useDepartmentsOption from '../../../../hooks/useDepartmentsOption';

const VehiclesOBDManagement = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const isSuper = useCheckRole({
		roleCategory: '차량관리',
		roleName: '실시간차량상태',
		roleType: 'isSuper',
	});

	const departmentsOption = useDepartmentsOption({ pageSize: 500 });

	const { formInitialValues, colsList, forms } = userData({
		departmentId: user?.department.id ?? 0,
		departmentsOption,
		isSuper,
	});
	const {
		rowData,
		totalCount,
		cols,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		handleSearchSubmit,
		handleDetailClick,
		isFetching,
		handleQuickSearchText,
		setQuickSearchText,
		quickSearchText,
	} = useClientGridWithRTK({
		useQuery: useGetVehiclesOBDInfoQuery,
		detailPath: VEHICLE_OBD_PATH,
		formInitialValues,
		colsList,
	});

	const resetQuickFilterText = useCallback(() => {
		handleQuickSearchText();
	}, [handleQuickSearchText]);

	useEffect(() => {
		if (quickSearchText === '' || !quickSearchText) {
			resetQuickFilterText();
		}
	}, [quickSearchText, resetQuickFilterText]);

	return (
		<GridContentTemplate
			gridRef={setGridApi}
			mainTitle="실시간 차량상태"
			gridTitle="실시간 차량상태"
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			onRowClicked={handleDetailClick}
			rowData={rowData}
			cols={cols}
			formInitialValues={formInitialValues}
			forms={forms}
			onSearchSubmit={handleSearchSubmit}
			frameworkComponents={{
				...createDetailAction(handleDetailClick, 'vehicleNumber'),
			}}
			isFetching={isFetching}
			rightAccessory={
				<div>
					<Input
						onChange={(e) => {
							setQuickSearchText(e.target.value);
						}}
						name="quickSearch"
						type="text"
						placeholder="결과내검색"
						value={quickSearchText}
						onReset={() => setQuickSearchText('')}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleQuickSearchText();
							}
						}}
					/>
				</div>
			}
		/>
	);
};

export default VehiclesOBDManagement;
