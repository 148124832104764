import React from 'react';
import styled from 'styled-components';
import Select from '../../../components/atoms/Select';
import { useGetVehiclesQuery } from '../../../store/apis/vehicle';
import useDepartmentsOption from '../../../hooks/useDepartmentsOption';
import useCheckRole from '../../../hooks/useCheckRole';

interface VehiclesSelectionProps {
	vehicleNumber: string;
	departmentId: number | undefined;
	page: number;
	pageSize: number;
	setDepartmentId: (departmentId: number | undefined) => void;
	setVehicleNumber: (vehicleNum: string) => void;
}

const SelectWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const VehiclesSelection = ({
	vehicleNumber,
	departmentId,
	page,
	pageSize,
	setDepartmentId,
	setVehicleNumber,
}: VehiclesSelectionProps) => {
	const isSuper = useCheckRole({
		roleCategory: '차량관리',
		roleName: '차량운행기록',
		roleType: 'isSuper',
	});
	const { data } = useGetVehiclesQuery(
		{ departmentId, page, pageSize },
		{ skip: !departmentId },
	);

	const departments = useDepartmentsOption({ pageSize: 500 });
	const vehicles = departmentId && data ? data.rows : [];

	return (
		<SelectWrapper>
			{isSuper && (
				<Select
					value={departmentId}
					onChange={(value: number | undefined) => {
						setVehicleNumber('');
						setDepartmentId(value);
					}}
					placeholder="소속"
					showSearch
					style={{ width: '160px' }}
				>
					{departments.map((item) => (
						<Select.Option value={item.value} key={item.value}>
							{item.label}
						</Select.Option>
					))}
				</Select>
			)}
			<Select
				value={vehicleNumber}
				onChange={(value: string) => setVehicleNumber(value)}
				placeholder="차량번호"
				showSearch
				style={{ width: '160px' }}
			>
				{vehicles.map((item) => (
					<Select.Option value={item.vehicleNumber} key={item.id}>
						{item.vehicleNumber}
					</Select.Option>
				))}
			</Select>
		</SelectWrapper>
	);
};

export default VehiclesSelection;
