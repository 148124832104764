import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
	FieldArray,
	FormikErrors,
	FormikProvider,
	FormikValues,
	useFormik,
} from 'formik';
import { Radio, RadioChangeEvent } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Button from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input';
import Label from '../../../../components/atoms/Label';
import Typo from '../../../../components/atoms/Typo';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import InputForm from '../../../../components/molecules/InputForm';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import Alert from '../../../../components/atoms/Alert';
import { VEHICLES_STATUS_MANAGEMENT_PATH } from '../../../../routes/constants/urls';
import { ButtonTypeType } from '../../../../styles/theme';
import InputAlert from '../../../../components/atoms/InputAlert';
import { CustomErrorType } from '../../../../store/apis/@types';
import { ToastContext } from '../../../../contexts/Toast';
import { fullLoadingOff, fullLoadingOn } from '../../../../store/webUtil';
import {
	VehicleStatus,
	CreateVehicleStatus,
} from '../../../../interface/vehicleStatus';
import {
	useGetVehicleStatusItemsQuery,
	useMakeVehicleStatusMutation,
} from '../../../../store/apis/vehicleStatus';
import GridLayout, {
	GridItem,
} from '../../../../components/molecules/GridLayout';
import { numberWithCommas } from '../../../../utils/data-format';
import useCheckRole from '../../../../hooks/useCheckRole';
import Select from '../../../../components/atoms/Select';
import useManagersOption from '../../../../hooks/useManagersOption';
import { RootState } from '../../../../store';
import Checkbox from '../../../../components/atoms/Checkbox';
import SelectionModal from '../../../../components/organisms/SelectionModal';
import { vehicleModalColumn } from '../../../../components/templates/modalColumn';
import { useGetVehiclesQuery } from '../../../../store/apis/vehicle';
import DateTimePicker from '../../../../components/atoms/DateTimePicker';

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	width: 100%;
	max-width: 830px;
	margin: 0 auto;
	margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

// const InnerInputForm = styled(InputForm)`
// 	padding: 20px;
// 	background: ${({ theme }) => theme.common.colors.gray_1};
// 	border-radius: 12px;
// `;

interface ItemListItemProps {
	item: Omit<VehicleStatus, 'createdAt' | 'updatedAt'>;
	index: number;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => Promise<FormikErrors<FormikValues>> | Promise<void>;
}

const ItemListItem = ({ item, index, setFieldValue }: ItemListItemProps) => {
	const [isRadio, setIsRadio] = useState(true);

	useEffect(() => {
		if (
			item.itemName.includes('타이어') ||
			item.itemName.includes('배터리') ||
			item.itemName.includes('브레이크패드')
		) {
			setIsRadio(false);
		}
	}, [item.itemName]);

	return (
		<GridItem title={item.itemName} size={140} $isCompact key={index}>
			{isRadio ? (
				<Radio.Group
					size="small"
					onChange={(e: RadioChangeEvent) => {
						setFieldValue(`itemList[${index}].status`, Number(e.target.value));
					}}
					value={item.status}
				>
					<Radio value={1}>
						<Typo $typoType="label3">위험</Typo>
					</Radio>
					<Radio value={3}>
						<Typo $typoType="label3">보통</Typo>
					</Radio>
					<Radio value={5}>
						<Typo $typoType="label3">정상</Typo>
					</Radio>
				</Radio.Group>
			) : (
				<Input
					name={`itemList[${index}].status`}
					placeholder="숫자를 입력해주세요"
					type="text"
					$inputSize="small"
					$inputRadius="small"
					value={numberWithCommas(item.status)}
					onChange={(e) =>
						setFieldValue(
							`itemList[${index}].status`,
							Number(String(e.target.value).replace(/[^0-9]/g, '')),
						)
					}
					onReset={() => setFieldValue(`itemList[${index}].status`, 0)}
				/>
			)}
		</GridItem>
	);
};

function VehicleStatusCreate() {
	const { user } = useSelector((state: RootState) => state.auth);
	const departmentName = user?.department.departmentName;

	const [isVehicleSelection, setIsVehicleSelection] = useState(false);
	const [isManagerName, setIsManagerName] = useState(true);

	const isCreateVehicleStatus = useCheckRole({
		roleCategory: '차량관리',
		roleName: '차량상태관리',
		roleType: 'isCreate',
	});
	const isSuperVehicleStatus = useCheckRole({
		roleCategory: '차량관리',
		roleName: '차량상태관리',
		roleType: 'isSuper',
	});
	const managerOptionList = useManagersOption(
		isSuperVehicleStatus ? undefined : departmentName,
	);

	let itemList: Omit<VehicleStatus, 'createdAt' | 'updatedAt'>[] = [];

	const initialValues: CreateVehicleStatus = {
		vehicleNumber: '',
		managerName: '',
		memo: '',
		mileage: 0,
		managerId: undefined,
		subscriptionTaskId: undefined,
		itemList: [],
		inspectedAt: dayjs().format('YYYY-MM-DD'),
	};

	const backUrl = VEHICLES_STATUS_MANAGEMENT_PATH;

	const navigate = useNavigate();
	const toast = useContext(ToastContext);
	const dispatch = useDispatch();

	const [isAlertVisible, setAlertVisible] = useState(false);
	const [makeVehicleStatus] = useMakeVehicleStatusMutation();
	const { data: vehicleStatusItemList, isSuccess } =
		useGetVehicleStatusItemsQuery({});

	const handleAlertCloseClick = () => {
		setAlertVisible(false);
	};

	const handleAlertOpenClick = () => {
		setAlertVisible(true);
	};

	const handleValidate = (values: typeof initialValues) => {
		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (values.vehicleNumber === '') {
			errors.vehicleNumber = '차량번호를 입력해주세요';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());

			const result = await makeVehicleStatus(values);

			if ('error' in result) {
				toast(
					'error',
					(result.error as CustomErrorType).data.translate ||
						(result.error as CustomErrorType).data.message,
				);
			} else {
				toast('info', '차량상태가 등록되었습니다.');
				navigate(backUrl);
			}

			setAlertVisible(false);
			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: false,
	});

	const handleCancelClick = () => {
		navigate(-1);
	};

	const handleCreateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			handleAlertOpenClick();
		}
	};

	if (isSuccess && vehicleStatusItemList) {
		itemList = vehicleStatusItemList.rows.map((item) => ({
			itemName: item.itemName,
			status:
				item.itemName.includes('타이어') ||
				item.itemName.includes('배터리') ||
				item.itemName.includes('브레이크패드')
					? 50
					: 5,
			memo: '',
		}));
	}

	return (
		<>
			<GridDetailTemplate
				detailTitle="차량상태 추가"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						<Button
							onClick={handleCreateClick}
							size="small"
							disabled={!isCreateVehicleStatus}
						>
							{isCreateVehicleStatus ? '등록하기' : '등록 권한없음'}
						</Button>
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="차량상태 입력하기"
					borderRadius="10px"
					className="inner-content"
				>
					<FormikProvider value={formik}>
						<InnerContent>
							<InputForm>
								<Label name="vehicleNumber" essential>
									<span className="label">
										<Typo $typoType="label2">차량번호</Typo>
									</span>
								</Label>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<Button
										size="small"
										$buttonType={ButtonTypeType.GHOST}
										onClick={() => setIsVehicleSelection(true)}
									>
										차량번호 선택
									</Button>
								</div>
								<SelectionModal
									onClose={() => setIsVehicleSelection(false)}
									onChange={(e) =>
										formik.setFieldValue('vehicleNumber', e[0].vehicleNumber)
									}
									column={vehicleModalColumn.colsList}
									useQuery={useGetVehiclesQuery}
									isVisible={isVehicleSelection}
									title="차량 선택"
								/>
								<Input
									name="vehicleNumber"
									placeholder="차량번호를 선택하거나 직접 입력해주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formik.values.vehicleNumber}
									onChange={formik.handleChange}
									onReset={() => formik.setFieldValue('vehicleNumber', '')}
								/>
								{formik.errors.vehicleNumber && (
									<InputAlert $inputStatus="error">
										{formik.errors.vehicleNumber}
									</InputAlert>
								)}
							</InputForm>
							<InputForm>
								<Label name="managerName" essential>
									<span className="label">
										<Typo $typoType="label2">담당 매니저</Typo>
									</span>
								</Label>
								{!isManagerName ? (
									<Select
										size="large"
										placeholder="담당 매니저 선택"
										value={formik.values.managerId}
										onChange={(value: number, option) => {
											formik.setFieldValue('managerId', value);
											formik.setFieldValue('managerName', option?.children);
										}}
										showSearch
										allowClear
									>
										{managerOptionList.map((manager, idx) => (
											<Select.Option value={manager.value} key={idx.toString()}>
												{manager.label}
											</Select.Option>
										))}
									</Select>
								) : (
									<Input
										name="managerName"
										placeholder="담당 매니저 이름을 입력해 주세요"
										type="text"
										$inputSize="small"
										$inputRadius="small"
										value={formik.values.managerName}
										onChange={formik.handleChange}
										onReset={() => formik.setFieldValue('managerName', '')}
									/>
								)}
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Checkbox
										$checkboxStyle="square"
										onClick={() => {
											if (isManagerName) {
												formik.setFieldValue('managerName', '');
											}
											formik.setFieldValue('managerId', undefined);
											setIsManagerName(!isManagerName);
										}}
										name="managerName"
										isChecked={isManagerName}
									/>
									<Label name="managerName">매니저 직접 입력</Label>
								</div>
								{(formik.errors.managerName || formik.errors.managerId) && (
									<InputAlert $inputStatus="error">
										{formik.errors.managerName} {formik.errors.managerId}
									</InputAlert>
								)}
							</InputForm>
							<InputForm>
								<Label name="mileage" essential>
									<span className="label">
										<Typo $typoType="label2">마일리지(총 주행거리)</Typo>
									</span>
								</Label>
								<Input
									name="mileage"
									placeholder="차량의 총 주행거리를 입력해주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={numberWithCommas(formik.values.mileage)}
									onChange={(e) =>
										formik.setFieldValue(
											'mileage',
											Number(String(e.target.value).replace(/[^0-9]/g, '')),
										)
									}
									onReset={() => formik.setFieldValue('mileage', 0)}
								/>
								{formik.errors.mileage && (
									<InputAlert $inputStatus="error">
										{formik.errors.mileage}
									</InputAlert>
								)}
							</InputForm>
							<InputForm>
								<Label name="memo">
									<span className="label">
										<Typo $typoType="label2">메모</Typo>
									</span>
								</Label>
								<Input
									name="memo"
									placeholder="간단한 메모를 입력해주세요"
									type="text"
									$inputSize="small"
									$inputRadius="small"
									value={formik.values.memo}
									onChange={formik.handleChange}
									onReset={() => formik.setFieldValue('memo', '')}
								/>
								{formik.errors.memo && (
									<InputAlert $inputStatus="error">
										{formik.errors.memo}
									</InputAlert>
								)}
							</InputForm>
							<InputForm>
								<Label name="createdAt">
									<span className="label">
										<Typo $typoType="label2">점검일자</Typo>
									</span>
								</Label>
								<DateTimePicker
									name="createdAt"
									value={dayjs(formik.values.inspectedAt)}
									onChange={(date, dateString) => {
										formik.setFieldValue(
											'inspectedAt',
											dayjs(dateString as string).format('YYYY-MM-DD'),
										);
									}}
									placeholder="점검일자"
									allowClear={false}
									style={{ borderRadius: '4px' }}
								/>
								{formik.errors.inspectedAt && (
									<InputAlert $inputStatus="error">
										{formik.errors.inspectedAt}
									</InputAlert>
								)}
							</InputForm>
							<Button
								size="default"
								$buttonType={ButtonTypeType.UNDER_LINE}
								onClick={() => formik.setFieldValue('itemList', itemList)}
							>
								차량상태 목록 가져오기
							</Button>
						</InnerContent>
						<FieldArray
							name="itemList"
							render={() => (
								<GridLayout $columnCount={3} $isCompact>
									{formik.values.itemList.map((item, index) => {
										return (
											<ItemListItem
												key={index}
												item={item}
												index={index}
												setFieldValue={formik.setFieldValue}
											/>
										);
									})}
								</GridLayout>
							)}
						/>
					</FormikProvider>
				</ContentBoxWithHeader>
			</GridDetailTemplate>

			{isAlertVisible && (
				<Alert
					title="차량상태 등록"
					closeButtonClick={handleAlertCloseClick}
					onConfirmButtonText="등록하기"
					onConfirmButtonClick={() => formik.handleSubmit()}
					isVisible={isAlertVisible}
				>
					치량상태를 등록하시겠습니까?
				</Alert>
			)}
		</>
	);
}

export default VehicleStatusCreate;
