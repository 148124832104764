import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { RootState, AppDispatch } from '../..';
import { setToken } from '../../auth';
import { CustomErrorType } from '../@types';

export const privateBaseQuery = async (
	args: string | FetchArgs,
	WebApi: any,
	extraOptions: {},
) => {
	const rawBaseQuery = fetchBaseQuery({
		baseUrl:
			(args as FetchArgs).params?.version === 'v1'
				? process.env.REACT_APP_API_URL_V1
				: process.env.REACT_APP_API_URL,
		prepareHeaders: (headers, { getState }) => {
			const { token } = (getState() as RootState).auth;

			if (token) {
				headers.set('authorization', token);
			}
			headers.set('service-name', 'BOS_WEB');

			return headers;
		},
		credentials: 'same-origin',
	});
	return rawBaseQuery(args, WebApi, extraOptions);
};

export const publicBaseQuery = async (
	args: string | FetchArgs,
	WebApi: any,
	extraOptions: {},
) => {
	const rawBaseQuery = fetchBaseQuery({
		baseUrl:
			(args as FetchArgs).params?.version === 'v1'
				? process.env.REACT_APP_API_URL_V1
				: process.env.REACT_APP_API_URL,
		prepareHeaders: (headers) => {
			headers.set('service-name', 'BOS_WEB');
			return headers;
		},
		credentials: 'same-origin',
	});
	return rawBaseQuery(args, WebApi, extraOptions);
};

export const baseAuthQuery: BaseQueryFn<
	string | FetchArgs,
	unknown,
	CustomErrorType
> = async (args, api, extraOptions) => {
	const result = await privateBaseQuery(args, api, extraOptions);

	if ('error' in result && result.error?.status === 401) {
		window.location.href = '/error/401';
	}

	if ('error' in result) {
		return { error: result.error as CustomErrorType };
	}

	return { data: result.data };
};

export const authApiSlice = createApi({
	baseQuery: baseAuthQuery,
	endpoints: () => ({}),
	tagTypes: [
		'Admin',
		'Vehicles',
		'VehicleStatus',
		'VehiclePlate',
		'VehicleConditions',
		'VehicleSubscriptionConsulting',
		'VehicleSubscriptionQuotes',
		'Products',
		'Product',
		'Company',
		'Driver',
		'VSRequest',
		'VSQuotes',
		'VSContracts',
		'Departments',
		'CarCareSubscription',
		'CarServicePaymentRequest',
		'Payments',
		'InspectionDashboard',
		'SubscriptionProducts',
		'Subproducts',
		'Subproduct',
	],
});

export const baseLoginQuery: BaseQueryFn<
	string | FetchArgs,
	unknown,
	CustomErrorType,
	{}
> = async (args, api, extraOptions) => {
	const result = await privateBaseQuery(args, api, extraOptions);

	if ('error' in result) {
		if (
			(result.error as CustomErrorType) &&
			!(result.error as CustomErrorType).data.translate &&
			(result.error as CustomErrorType).data.message.length &&
			// @ts-ignore
			(result.error as CustomErrorType).data.message.find(
				(item: string) => item.indexOf('regular expression') > -1,
			)
		) {
			(result.error as CustomErrorType).data.translate =
				'양식이 잘못되었습니다.';
		}
		return { error: result.error as CustomErrorType };
	}

	if (
		result.meta?.response?.headers &&
		result.meta.response.headers.get('authorization')
	) {
		const token = result.meta.response.headers.get('authorization');
		api.dispatch(setToken(token || undefined));
	}

	return { data: result.data };
};

export const anyMobilityApiSlice = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: 'https://asia-northeast3-anydtg-env.cloudfunctions.net',
	}),
	endpoints: () => ({}),
});

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
