import React from 'react';
import SubproductTemplate from '../../../../components/templates/SubProductTemplate';
import { SubproductCreateUpdateDto } from '../../../../interface/product';

const initialValues: SubproductCreateUpdateDto = {
	productName: '',
	productPrice: 0,
	productTax: 0,
	productTaxType: 'TAX',
	productViewable: true,
	productRemark: undefined,
	productImage: undefined,
	productIcon: undefined,
	productDescription: '',
};

function SubproductCreatePage() {
	return <SubproductTemplate initialValues={initialValues} />;
}

export default SubproductCreatePage;
