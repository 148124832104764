import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface CheckRoleProps {
	roleCategory: string;
	roleName: string;
	roleType: 'isRead' | 'isCreate' | 'isUpdate' | 'isDelete' | 'isSuper';
	includeSuper?: boolean;
}

// const useCheckRole = ({
// 	roleCategory,
// 	roleName,
// 	roleType,
// 	includeSuper,
// }: CheckRoleProps) => {
// 	const { user } = useSelector((state: RootState) => state.auth);

// 	let hasRole = false;

// 	if (user) {
// 		const normalizedRoleCategory = roleCategory.replaceAll(' ', '');
// 		const normalizedRoleName = roleName.replaceAll(' ', '');

// 		hasRole = user.role.roleItems.some((item) => {
// 			const matchesRoleType = item[roleType];
// 			const matchesRoleCategory =
// 				item.roleTemplate.roleCategory.replaceAll(' ', '') ===
// 				normalizedRoleCategory;

// 			const matchesRoleName =
// 				item.roleTemplate.roleName.replaceAll(' ', '') === normalizedRoleName;

// 			if (includeSuper) {
// 				return (
// 					matchesRoleType &&
// 					matchesRoleCategory &&
// 					matchesRoleName &&
// 					item.isSuper
// 				);
// 			}
// 			return matchesRoleType && matchesRoleCategory && matchesRoleName;
// 		});
// 	}
// 	return hasRole;
// };

const useCheckRole = ({
	roleCategory,
	roleName,
	roleType,
	includeSuper,
}: CheckRoleProps) => {
	const { user } = useSelector((state: RootState) => state.auth);
	let hasRole = false;

	if (user) {
		const normalizedRoleCategory = roleCategory.replaceAll(' ', '');
		const normalizedRoleName = roleName.replaceAll(' ', '');

		// isSuper를 고려하지 않고 역할이 일치하는지 먼저 확인
		hasRole = user.role.roleItems.some((item) => {
			const matchesRoleType = item[roleType] === true;
			const matchesRoleCategory =
				item.roleTemplate.roleCategory.replaceAll(' ', '') ===
				normalizedRoleCategory;
			const matchesRoleName =
				item.roleTemplate.roleName.replaceAll(' ', '') === normalizedRoleName;

			return matchesRoleType && matchesRoleCategory && matchesRoleName;
		});

		// 만약 includeSuper가 isSuper이면서, 역할이 일치하는지 확인
		if (includeSuper) {
			const hasSuperRole = user.role.roleItems.some((item) => {
				const matchesRoleType = item[roleType] === true;
				const matchesRoleCategory =
					item.roleTemplate.roleCategory.replaceAll(' ', '') ===
					normalizedRoleCategory;
				const matchesRoleName =
					item.roleTemplate.roleName.replaceAll(' ', '') === normalizedRoleName;

				return (
					matchesRoleType &&
					matchesRoleCategory &&
					matchesRoleName &&
					item.isSuper === true
				);
			});

			return hasRole && hasSuperRole; // 둘 다 true여야 true
		}
	}
	return hasRole;
};

export default useCheckRole;
