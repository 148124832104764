import React from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useGetVehicleChangeHistoriesQuery,
	useGetVehicleDetailQuery,
	useGetVehiclePurchasePriceQuery,
} from '../../../../store/apis/vehicle';
import VehicleTemplate from '../../../../components/templates/VehicleTemplate';
import { MakeVehicleDto } from '../../../../interface/vehicle';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import VehicleHistoryChangeKeys from './gridData';
import {
	VEHICLES_DETAIL_PATH,
	VEHICLES_MANAGEMENT_PATH,
} from '../../../../routes/constants/urls';
import useCheckRole from '../../../../hooks/useCheckRole';

const VehiclesDetail = () => {
	const isGetVehiclePurchasePrice = useCheckRole({
		roleCategory: '자산관리',
		roleName: '차량관리(취득가)',
		roleType: 'isRead',
	});

	const { id } = useParams();
	const { data } = useGetVehicleDetailQuery({ id: Number(id) });
	const { data: vehiclePurchasePriceData } = useGetVehiclePurchasePriceQuery(
		{
			vehicleId: Number(id),
		},
		{
			skip: !isGetVehiclePurchasePrice,
		},
	);
	const vehiclePurchasePrice = vehiclePurchasePriceData?.row.purchasePrice ?? 0;
	const vehicleInfo = data?.row;
	const navigate = useNavigate();

	const { colsList, formInitialValues } = VehicleHistoryChangeKeys;
	const {
		rowData,
		totalCount,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: (args) =>
			useGetVehicleChangeHistoriesQuery({
				vehicleId: Number(id),
				page: args.page,
				pageSize: args.pageSize,
			}),
		detailPath: VEHICLES_DETAIL_PATH,
		formInitialValues,
		colsList,
		navigateCallback: () => navigate(VEHICLES_MANAGEMENT_PATH),
	});

	const initialValues: MakeVehicleDto = {
		vehicleNumber: vehicleInfo?.vehicleNumber ?? undefined,
		vin: vehicleInfo?.vin ?? '',
		isDtgInstalled: vehicleInfo?.isDtgInstalled ?? false,
		isObdInstalled: vehicleInfo?.isObdInstalled ?? false,
		lastInspectionDate:
			vehicleInfo?.lastInspectionDate ?? dayjs().format('YYYYMMDD'),
		manufacturingCompany: vehicleInfo?.manufacturingCompany ?? '현대',
		carLoadingBoxType: vehicleInfo?.carLoadingBoxType ?? '하이탑',
		loadingCapacity: vehicleInfo?.loadingCapacity ?? 1,
		vehicleCategory: vehicleInfo?.vehicleCategory,
		vehicleName: vehicleInfo?.vehicleName,
		vehicleRegistration: vehicleInfo?.vehicleRegistration ?? null,
		vehicleYear: vehicleInfo?.vehicleYear,
		factoryPrice: vehicleInfo?.factoryPrice,
		purchasePrice: vehicleInfo?.purchasePrice,
		purchaseDate: vehicleInfo?.purchaseDate,
		salePrice: vehicleInfo?.salePrice ?? 0,
		saleDate: vehicleInfo?.saleDate,
		importDomestic: vehicleInfo?.importDomestic ?? '국산',
		fuelType: vehicleInfo?.fuelType ?? '디젤',
		status: vehicleInfo?.status ?? 'ACTIVE',
		insurer: vehicleInfo?.insurer ?? '화물공제조합',
		insuranceAge: vehicleInfo?.insuranceAge ?? '만 26세 이상',
		insuranceInPersonam: vehicleInfo?.insuranceInPersonam,
		insuranceInRem: vehicleInfo?.insuranceInRem,
		insuranceSelf: vehicleInfo?.insuranceSelf,
		insuranceSelfVehicle: vehicleInfo?.insuranceSelfVehicle,
		insuranceDriverType: vehicleInfo?.insuranceDriverType ?? '법인',
		insuranceIndemnification: vehicleInfo?.insuranceIndemnification,
		transmission: vehicleInfo?.transmission ?? '오토',
		color: vehicleInfo?.color ?? null,
		driverId: vehicleInfo?.driver?.id,
		registrationDate:
			vehicleInfo?.registrationDate ?? dayjs().format('YYYY-MM-DD'),
		legalInspectionDate:
			vehicleInfo?.legalInspectionDate ?? dayjs().format('YYYY-MM-DD'),
		legalInspectionEndDate:
			vehicleInfo?.legalInspectionEndDate ?? dayjs().format('YYYY-MM-DD'),
		insurancePremiums: vehicleInfo?.insurancePremiums ?? null,
		insuranceLossPct: vehicleInfo?.insuranceLossPct ?? null,
		insuranceStartDate:
			vehicleInfo?.insuranceStartDate ?? dayjs().format('YYYY-MM-DD'),
		insuranceEndDate:
			vehicleInfo?.insuranceEndDate ?? dayjs().format('YYYY-MM-DD'),
	};

	return (
		<VehicleTemplate
			gridRef={setGridApi}
			totalCount={totalCount}
			currentPage={page}
			currentPageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			rowData={rowData}
			initialValues={initialValues}
			extraInfo={vehicleInfo}
			isFetching={isFetching}
			vehiclePurchasePrice={vehiclePurchasePrice}
		/>
	);
};

export default VehiclesDetail;
