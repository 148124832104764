import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Skeleton } from 'antd';
import VehicleSubscriptionDetailGridData from './gridData';
import ContentBoxWithHeader from '../../../../components/molecules/ContentBoxWithHeader';
import {
	VEHICLES_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_DETAIL_PATH,
	VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH,
} from '../../../../routes/constants/urls';
import GridDetailTemplate from '../../../../components/templates/GridDetailTemplate';
import {
	useGetMyVehicleSubscriptionDetailQuery,
	useGetVSContractHistoriesQuery,
	useGetVSDetailAdminQuery,
} from '../../../../store/apis/vehicleSubscription';
import useCheckRole from '../../../../hooks/useCheckRole';
import useGridDataWithRTK from '../../../../hooks/useGridData/useGridDataWithRTK';
import GridLayout, {
	GridItem,
} from '../../../../components/molecules/GridLayout';
import HistoryDetail from '../../../../components/molecules/HistoryDetail';
import { detailIcon } from '../../../../assets/icon';
import Icon from '../../../../components/atoms/Icon';
import GetContractLink from '../../../../components/templates/VehicleSubscription/GetContractLink';
import {
	companyRegisterNumberFormat,
	numberWithCommas,
	phoneFormat,
} from '../../../../utils/data-format';
import EndActionButton from '../../../../components/templates/VehicleSubscription/EndActionButton';
import CancelButton from '../../../../components/templates/VehicleSubscription/CancelButton';
import EditContractInfoButton from '../../../../components/templates/VehicleSubscription/EditContractInfoButton';
import ContentBox from '../../../../components/atoms/ContentBox';
import sortedColsList from '../../../../utils/historyDetail-util';

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const VehicleSubscriptionDetailPage = () => {
	const navigate = useNavigate();
	const { subscriptionNo } = useParams();
	const isSuper = useCheckRole({
		roleCategory: '차량구독관리',
		roleName: '현황관리',
		roleType: 'isRead',
		includeSuper: true,
	});
	const { colsList, formInitialValues } = VehicleSubscriptionDetailGridData;

	const { isLoading: isAdminLoading, data: adminData } =
		useGetVSDetailAdminQuery(
			{ subscriptionNo },
			{ skip: !subscriptionNo || !isSuper },
		);
	const { isLoading, data } = useGetMyVehicleSubscriptionDetailQuery(
		{ subscriptionNo },
		{ skip: !subscriptionNo || isSuper },
	);
	const detailInfo = !isSuper ? data?.row : adminData?.row;

	const {
		rowData,
		totalCount,
		page,
		pageSize,
		setGridApi,
		handlePageChange,
		handlePageSizeChange,
		isFetching,
	} = useGridDataWithRTK({
		useQuery: (args) =>
			useGetVSContractHistoriesQuery({
				svNo: subscriptionNo || '',
				page: args.page,
				pageSize: args.pageSize,
			}),
		detailPath: VEHICLE_SUBSCRIPTION_DETAIL_PATH,
		formInitialValues,
		colsList,
		navigateCallback: () => navigate(VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH),
	});

	const vehicleSubscriptionValues = rowData.map((vehicleSubscription) => {
		return {
			createdAt: vehicleSubscription.createdAt,
			memo: vehicleSubscription.memo,
			administratorName: vehicleSubscription.admin.administratorName,
			subscriptionStart: vehicleSubscription.payload?.subscriptionStartedAt,
			subscriptionEnd: vehicleSubscription.payload?.subscriptionEndedAt,
			subscriptionPrice: vehicleSubscription.payload?.subscriptionPrice,
			vehiclePrice: vehicleSubscription.payload?.vehiclePrice,
			prePaymentPrice: vehicleSubscription.payload?.prePaymentPrice,
			acquisitionPrice: vehicleSubscription.payload?.acquisitionPrice,
			agCommissionPct: vehicleSubscription.payload?.agCommissionPct,
			status: vehicleSubscription.payload?.status,
		};
	});
	const fixedColumnsKeys = ['createdAt', 'memo', 'administratorName'];
	const sortedColsListResult = sortedColsList(
		rowData,
		colsList,
		fixedColumnsKeys,
	);

	if (isAdminLoading || isLoading) {
		return (
			<GridDetailTemplate detailTitle="차량구독 상세">
				<ContentBox borderRadius="10px">
					<Skeleton
						active
						paragraph={{ rows: 2 }}
						style={{ borderRadius: '10px' }}
					/>
				</ContentBox>
				<ContentBox borderRadius="10px">
					<Skeleton
						active
						paragraph={{ rows: 4 }}
						style={{ borderRadius: '10px' }}
					/>
				</ContentBox>
				<ContentBox borderRadius="10px">
					<Skeleton
						active
						paragraph={{ rows: 2 }}
						style={{ borderRadius: '10px' }}
					/>
				</ContentBox>
				<ContentBox borderRadius="10px">
					<Skeleton
						active
						paragraph={{ rows: 1 }}
						style={{ borderRadius: '10px' }}
					/>
				</ContentBox>
			</GridDetailTemplate>
		);
	}

	return (
		<GridDetailTemplate
			detailTitle="차량구독 상세"
			onBack={() => navigate(VEHICLE_SUBSCRIPTION_MANAGEMENT_PATH)}
			rightAccessory={
				isSuper && (
					<EditContractInfoButton
						svNo={subscriptionNo as string}
						detailInfo={detailInfo}
					/>
				)
			}
		>
			<ContentBoxWithHeader
				title="계약자 정보"
				borderRadius="10px"
				className="inner-content"
			>
				<GridLayout>
					<GridItem title="유형">
						{detailInfo?.subscription.clientType === 'COMPANY'
							? '법인'
							: '개인'}
					</GridItem>
					<GridItem title="계약자명">
						{detailInfo?.subscription.clientName}
					</GridItem>
				</GridLayout>
			</ContentBoxWithHeader>
			{detailInfo?.company &&
				detailInfo?.subscription.clientType === 'COMPANY' && (
					<ContentBoxWithHeader
						title="법인정보"
						borderRadius="10px"
						className="inner-content"
					>
						<GridLayout>
							<GridItem title="가입일자">
								{dayjs(detailInfo?.company.createdAt ?? undefined).format(
									'YYYY-MM-DD(dd) hh:mm',
								)}
							</GridItem>
							<GridItem title="법인명">{detailInfo?.company.name}</GridItem>
							<GridItem title="사업자번호">
								{companyRegisterNumberFormat(
									detailInfo?.company.businessLicenseNumber ?? '',
								)}
							</GridItem>
							<GridItem title="대표번호">
								{phoneFormat(
									detailInfo?.company.representativePhoneNumber ?? '',
								)}
							</GridItem>
							<GridItem title="담당자">
								{detailInfo?.company.contactName}
							</GridItem>
							<GridItem title="담당자 번호">
								{phoneFormat(detailInfo?.company.contactPhoneNumber ?? '')}
							</GridItem>
						</GridLayout>
					</ContentBoxWithHeader>
				)}
			{detailInfo?.driver && (
				<ContentBoxWithHeader
					title="기사정보"
					borderRadius="10px"
					className="inner-content"
				>
					<GridLayout>
						<GridItem title="가입일자">
							{dayjs(detailInfo?.driver.createdAt ?? undefined).format(
								'YYYY-MM-DD(dd) hh:mm',
							)}
						</GridItem>
						<GridItem title="기사명">{detailInfo?.driver.driverName}</GridItem>
						<GridItem title="휴대폰번호">
							{phoneFormat(detailInfo?.driver.driverPhone ?? '')}
						</GridItem>
					</GridLayout>
				</ContentBoxWithHeader>
			)}
			<ContentBoxWithHeader
				title="구독정보"
				borderRadius="10px"
				className="inner-content"
				rightAccessory={
					!isSuper &&
					detailInfo && (
						<ButtonContainer>
							<CancelButton svNo={detailInfo.subscriptionNo} />
							<EndActionButton
								endType="반납신청"
								contactName={
									detailInfo.company.admin?.administratorName ?? '오픈마일'
								}
								contactPhoneNumber={
									detailInfo.subscription.admin?.administratorPhone ??
									'번호없음'
								}
								vehicleNumber={
									detailInfo.subscription.vehicle.vehicleNumber as string
								}
							/>
							<EndActionButton
								endType="인수신청"
								contactName={
									detailInfo.subscription.admin?.administratorName ?? '오픈마일'
								}
								contactPhoneNumber={
									detailInfo.subscription.admin?.administratorPhone ??
									'번호없음'
								}
								vehicleNumber={
									detailInfo.subscription.vehicle.vehicleNumber as string
								}
							/>
							<EndActionButton
								endType="재구독신청"
								contactName={
									detailInfo.subscription.admin?.administratorName ?? '오픈마일'
								}
								contactPhoneNumber={
									detailInfo.subscription.admin?.administratorPhone ??
									'번호없음'
								}
								vehicleNumber={
									detailInfo.subscription.vehicle.vehicleNumber as string
								}
							/>
						</ButtonContainer>
					)
				}
			>
				<GridLayout>
					<GridItem title="구독시작일자">
						{dayjs(detailInfo?.subscription.subscriptionStartedAt).format(
							'YYYY-MM-DD(dd)',
						)}
					</GridItem>
					<GridItem title="구독종료일자">
						{dayjs(detailInfo?.subscription.subscriptionEndedAt).format(
							'YYYY-MM-DD(dd)',
						)}
					</GridItem>
					<GridItem title="구독기간">
						{`${detailInfo?.subscriptionPeriod}일`}
					</GridItem>
					<GridItem title="만기시">{detailInfo?.endActionType}</GridItem>
					<GridItem title="결제방법">{detailInfo?.paymentMethod}</GridItem>
					<GridItem title="상태">{detailInfo?.status}</GridItem>
					<GridItem title="월 구독료">
						{`${numberWithCommas(detailInfo?.subscriptionPrice ?? 0)}원`}
					</GridItem>
					{isSuper && (
						<GridItem title="차량가">
							{`${numberWithCommas(detailInfo?.vehiclePrice ?? 0)}원`}
						</GridItem>
					)}
					<GridItem title="선입금">
						{`${numberWithCommas(detailInfo?.prePaymentPrice ?? 0)}원`}
					</GridItem>
					<GridItem title="인수잔가">
						{`${numberWithCommas(detailInfo?.acquisitionPrice ?? 0)}원`}
					</GridItem>
					{isSuper && (
						<GridItem title="AG수수료">{`${detailInfo?.agCommissionPct}%`}</GridItem>
					)}
					<GridItem title="전자계약">
						{detailInfo?.subscriptionNo && (
							<GetContractLink svNo={detailInfo.subscriptionNo} />
						)}
					</GridItem>
				</GridLayout>
			</ContentBoxWithHeader>
			<ContentBoxWithHeader
				title="차량정보"
				borderRadius="10px"
				className="inner-content"
				rightAccessory={
					<Icon
						src={detailIcon}
						onClick={() =>
							navigate(
								`${VEHICLES_DETAIL_PATH}/${detailInfo?.subscription.vehicle.id}`,
							)
						}
						width={18}
						height={18}
					/>
				}
			>
				<GridLayout>
					<GridItem title="차량번호">
						{detailInfo?.subscription.vehicle?.vehicleNumber}
					</GridItem>
					<GridItem title="차량소유자">
						{detailInfo?.subscription.vehicle?.ownerCompany?.name}
					</GridItem>
					<GridItem title="차종">
						{detailInfo?.subscription.vehicle?.vehicleCategory}
					</GridItem>
					<GridItem title="차명">
						{detailInfo?.subscription.vehicle?.vehicleName}
					</GridItem>
					<GridItem title="적재함 종류">
						{detailInfo?.subscription.vehicle?.carLoadingBoxType}
					</GridItem>
					<GridItem title="적재량">
						{`${detailInfo?.subscription.vehicle?.loadingCapacity}톤`}
					</GridItem>
					<GridItem title="소속명">
						{detailInfo?.subscription.vehicle?.department?.departmentName}
					</GridItem>
				</GridLayout>
			</ContentBoxWithHeader>
			{detailInfo?.subscription.subscriptionCarCare && (
				<ContentBoxWithHeader
					title="카케어 정보"
					borderRadius="10px"
					className="inner-content"
					id="step-4"
				>
					<GridLayout>
						<GridItem title="상품명">
							{
								detailInfo?.subscription.subscriptionCarCare.subscriptionProduct
									.subscriptionProductName
							}
						</GridItem>
						<GridItem title="상품소개" span={3}>
							{
								detailInfo?.subscription.subscriptionCarCare.subscriptionProduct
									.subscriptionProductDescription
							}
						</GridItem>
					</GridLayout>
				</ContentBoxWithHeader>
			)}

			<HistoryDetail
				gridRef={setGridApi}
				gridTitle="계약현황 변경 내역"
				colsList={sortedColsListResult}
				currentPage={page}
				currentPageSize={pageSize}
				handlePageChange={handlePageChange}
				handlePageSizeChange={handlePageSizeChange}
				rowData={vehicleSubscriptionValues}
				totalCount={totalCount}
				isFetching={isFetching}
			/>
		</GridDetailTemplate>
	);
};
export default VehicleSubscriptionDetailPage;
